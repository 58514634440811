export const environment = Object.assign({
    frontendUrl: "http://localhost:3001",
    backendUrl: "http://localhost:8080",
    casLoginUrl: "https://cas-prelive.rkas.ee/cas/login",
    casLogoutUrl: "https://cas-prelive.rkas.ee/cas/logout",
    production: true,
    coreAppUrls: {
        propertyregistry: "https://kinnistud-prelive.rkas.ee",
        tuumikbilling: "https://tuumik-prelive.rkas.ee",
        workorder: "https://localhost:2400",
        userregistry: "http://localhost:3001",
        projektid: "https://projektid-prelive.rkas.ee",
        lepingud: "https://lepingud-prelive.rkas.ee"
    },
    name: "Riigi Kinnisvara AS",
    address: "Lelle 24, Tallinn 11318",
    logo: "RiigiKinnisvara_new.svg",
    helpdesk: {
        email: "helpdesk@rkas.ee",
        phone: "606 3482"
    },
    enablePersonaSync: true
}, (window as any).AppConfig);
