import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin.routing.module';

import { AdminComponent } from './admin.component'

import { AdminService } from './admin.service';

@NgModule({
    imports: [
        CommonModule,
        AdminRoutingModule
    ],
    declarations: [
        AdminComponent
    ],
    providers: [
        AdminService
    ]
})
export class AdminModule {
}
