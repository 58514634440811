<footer class="footer">
    <div class="pull-right text-muted">
        Ver. {{version}}
    </div>
    <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-5 col-sm-10" *ngIf="helpdesk">
            <span class="text-muted"><b>{{name}}</b> {{address ? address + '. ': ''}}Tehniline tugi : <a href="#" *ngIf="helpdesk?.email">{{helpdesk?.email}}</a><ng-container *ngIf="helpdesk?.email && helpdesk?.phone">, </ng-container><ng-container *ngIf="helpdesk?.phone">{{helpdesk?.phone}}</ng-container></span>
        </div>
        <div class="col-xl-4 col-lg-5 col-sm-10" *ngIf="!helpdesk">
            <span class="text-muted"><b>{{name}}</b> {{address}}</span>
        </div>
    </div>
</footer>
