import { Directive, AfterViewInit, OnInit, OnChanges, OnDestroy, ElementRef, HostListener, Input } from '@angular/core';

declare let jQuery:any;

@Directive({
    selector: '[bs-table-control]'
})
export class BsTableControl implements AfterViewInit, OnInit, OnDestroy, OnChanges {

    private _$table: any;

    private _isInstantiated: boolean = false;

    @Input('bs-table-options')
    private options:any = {};

    constructor(private _element: ElementRef) { }

    ngAfterViewInit() {}

    ngOnInit() {
        if(this._isInstantiated) return;

        this._$table = jQuery(this._element.nativeElement);

        this._$table.bootstrapTable(this.options);

        this._isInstantiated = true;
    }

    ngOnChanges(changes:any) {
        if (changes['options']) {
            if(this._$table && this._isInstantiated) {
                this._$table.bootstrapTable('destroy');
                this._$table.bootstrapTable(this.options);
                this._isInstantiated = true;
            }
        }
    }

    ngOnDestroy() {
        jQuery(this._element.nativeElement).bootstrapTable('destroy');
    }

    @HostListener("window:resize", [])
    onResize() {
        this._$table.bootstrapTable('resetView');
    }

    @HostListener("window:scroll", [])
    onScroll() {

    }

    refresh() {
        if(this._$table) {
            this._$table.bootstrapTable('refresh');
        }
    }

    load(data:any[]) {
        if(this._$table) {
            this._$table.bootstrapTable('load', data);
        }
    }
}