import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { BsTableControl } from './directives/bs-table-control.directive';

import { throwIfAlreadyLoaded } from '../core/module-import-guard';
import {DaterangepickerDirective} from "./directives/daterangepicker.directive";
import {UserCompanyFormComponent} from "../user-registry/form/user-company-form.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {EmailComponent} from "./components/email/email.component";
import {ClassifierLabelPipe} from "./pipes/classifier-label.pipe";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgxPermissionsModule} from "ngx-permissions";
import {TooltipModule} from "ngx-bootstrap/tooltip";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgxPermissionsModule,
        TooltipModule
    ],
    exports: [BsTableControl, DaterangepickerDirective, UserCompanyFormComponent, EmailComponent, ClassifierLabelPipe],
    declarations: [BsTableControl, DaterangepickerDirective, UserCompanyFormComponent, EmailComponent, ClassifierLabelPipe],
    providers: [
        ClassifierLabelPipe
    ]
})
export class SharedModule {
    constructor( @Optional() @SkipSelf() parentModule: SharedModule) {
        throwIfAlreadyLoaded(parentModule, 'SharedModule');
    }
}
