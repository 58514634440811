import {Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild} from "@angular/core";
import {Subject} from "rxjs";
import {UserDto} from "@app/core/resource-dto/user";
import {BsTableControl} from "@app/shared/directives/bs-table-control.directive";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'basis-of-assign',
    templateUrl: './basis-of-assign.component.html'
})
export class BasisOfAssignComponent implements OnDestroy, OnChanges {

    @Input()
    public basisOfAssigns: UserDto.BasisOfAssign[] = [];

    @ViewChild(BsTableControl)
    private bsTableControl: BsTableControl;

    @ViewChild('bsTableElement')
    private bsTableElement: ElementRef;

    private readonly ngDestroy: Subject<void> = new Subject<void>();

    public bsTableOptions: any = {
        toolbar: undefined,
        showFooter: false,
        pagination: false,
        columns: [
            {
                field: 'companyName',
                title: 'Ettevõte',
            },
            {
                field: 'roleName',
                title: 'Roll',
            },
            {
                field: 'validFrom',
                title: 'Algus',
            },
            {
                field: 'validTo',
                title: 'Lõpp',
            },
            {
                field: 'basisOfAssign',
                title: 'Alus',
                formatter: (value: any, row: any, rowIndex: any) => {
                    return '<a href="' + value + '" target="_blank" data-id="' + rowIndex + '">' + value + '</a>'
                }
            },
        ],
        data: []
    };

    public constructor(
        private datePipe: DatePipe,
    ) {
    }

    ngOnChanges() {
        if (this.basisOfAssigns.length > 0) {
            this.bsTableControl.load(this.basisOfAssigns.map((data: UserDto.BasisOfAssign) => {
                return {
                    companyName: data.companyName,
                    roleName: data.roleName,
                    validFrom: this.datePipe.transform(data.validFrom, 'dd.MM.yyyy'),
                    validTo: this.datePipe.transform(data.validTo, 'dd.MM.yyyy'),
                    basisOfAssign: data.basisOfAssign
                }
            }));
        }
    }

    public ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }
}
