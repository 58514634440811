import { Component} from '@angular/core';
import { AdminService } from './admin.service';
import {ConfigService} from '@app/config/config.service';

@Component({
    templateUrl: './admin.component.html'
})
export class AdminComponent {

    constructor(
        private adminService: AdminService,
        public configService: ConfigService
    ) {
    }

    public substituteSync(): void {
        if (!this.configService.enablePersonaSync()) return;

        this.adminService.substituteSync();
    }

    public adImportSync(): void {
        this.adminService.adImportSync();
    }
}
