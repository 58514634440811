import {Component, OnInit, AfterViewInit, ViewChild, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import { UserDto } from "../core/resource-dto/user";

import {RoleAdapter} from "../user-registry/form/role-adapter";
import {CompanyAdapter} from "../user-registry/form/company-adapter";
import {ManageService as CompanyManageService} from "../company/manage/manage.service";
import {ManageService as UserManageService} from "../user-registry/manage/manage.service";
import {UserCompanyFormComponent} from "../user-registry/form/user-company-form.component";
import {CountryAdapter} from "../core/services/country-adapter";
import {UserAdapter} from "../user-registry/form/user-adapter";
import {CompanyDto} from "../core/resource-dto/company";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {JobTitleAdapter} from "@app/user-registry/form/job-title-adapter";

@Component({
    templateUrl: './representation-right.component.html',
    providers: [ CountryAdapter, UserAdapter, RoleAdapter, CompanyAdapter, JobTitleAdapter, CompanyManageService, UserManageService ],
})
export class RepresentationRightComponent implements OnInit, OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(UserCompanyFormComponent)
    formComponent:UserCompanyFormComponent;

    public country:number = null;
    public isNew = true;
    public redirect: any[] = null;
    public user:UserDto.User = new UserDto.User();
    public company: CompanyDto.Company = new CompanyDto.Company();
    public userCompany:UserDto.Company = new UserDto.Company();

    public user$:Observable<UserDto.User>;
    public company$:Observable<CompanyDto.Company>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private companyAdapter: CompanyAdapter,
        private roleAdapter: RoleAdapter,
        private userAdapter: UserAdapter,
        private companyManageService: CompanyManageService,
        private userManageService: UserManageService
    ) {
        const path = this.router.url.substring(1, this.router.url.length);
        let currentRoute = path.substring(0, path.indexOf('/'));

        this.route.params.pipe(takeUntil(this.ngDestroy)).subscribe(params => {
            this.redirect = ['/companies/view', params['companyId']];
            if (currentRoute === 'user') {
                this.redirect = ['/user', params['userId']];
            }

            if (params['companyId'] && params['userId']) {
                this.isNew = false;


                this.userManageService.getUserCompany(params['userId'], params['companyId']).then(
                    (company:UserDto.Company) => {
                        this.userCompany = company;
                        if (!this.userCompany)
                            this.router.navigate(this.redirect, {relativeTo:route})
                    },
                    (error:any) => {
                        this.router.navigate(this.redirect, {relativeTo:route});
                    }
                );
            }
            if (params['companyId']) {
                this.companyManageService.loadCompany(false, params['companyId']);
                this.company$ = companyManageService.company$;

                if (!params['userId']) {
                    this.userAdapter.loadUsers();
                }
            }
            if (params['userId']) {
                this.userManageService.loadUser(params['userId']);
                this.user$ = userManageService.user$;
            }
        });

    }

    ngOnInit() {
        if (this.company$) {
            this.company$.pipe(takeUntil(this.ngDestroy)).subscribe((company: CompanyDto.Company) => {
                this.company = company;
            });
        }

        if (this.user$) {
            this.user$.pipe(takeUntil(this.ngDestroy)).subscribe((user: UserDto.User) => {
                this.user = user;
                this.companyAdapter.loadCompanies();
            });
        }

        this.roleAdapter.loadRoles();
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    btnSaveClicked(e:any) {
        this.formComponent.save();
    }

    cancel() {
        this.router.navigate(this.redirect, {relativeTo: this.route});
    }

    formSaved(e:any) {
        this.router.navigate(this.redirect, {relativeTo: this.route});
    }
}

