<header class="section-header">
    <div class="tbl">
        <div class="tbl-row">
            <div class="tbl-cell">
                <ol class="breadcrumb breadcrumb-simple">
                    <li><a [routerLink]="['/list']">Kasutajad</a></li>
                    <li class="active">Kasutaja lisamine</li>
                </ol>
            </div>
        </div>
    </div>
</header>
<section class="box-typical">
    <header class="box-typical-header box-typical-header-border">
        <div class="tbl-row">
            <div class="tbl-cell tbl-cell-title">
                <h3>Kasutaja lisamine</h3>
            </div>
        </div>
    </header>
    <div class="box-typical-body box-typical-padding">
        <app-user-form (email)="handleEmailChange($event)" (phone)="handlePhoneChange($event)" [user]="user" *ngIf="user"></app-user-form>
    </div>
    <header class="box-typical-header box-typical-header-border">
        <div class="tbl-row">
            <div class="tbl-cell tbl-cell-title">
                <h3>Esindusõigused</h3>
            </div>
            <div class="tbl-cell tbl-cell-action-bordered" style="border-left: none" *ngxPermissionsOnly="'representation-right.manage'">
                <button type="button" class="action-btn no-wrap" data-toggle="modal" data-target="#addRepresentativeRight" (click)="modalButtonClicked = !modalButtonClicked">
                    <em class="font-icon font-icon-plus"></em>&nbsp;Lisa&nbsp;esindusõigus
                </button>
            </div>
        </div>
    </header>
    <div class="alert alert-danger mt-4 ml-3 mr-3" role="alert" *ngIf="showFormErrors">
        <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
            <span aria-hidden="true">&times;</span>
        </button>
        Lisage vähemalt 1 esindusõigus.
    </div>
    <table bs-table-control
           #bsTableElement
           [bs-table-options]="bsTableOptions"
           class="table-striped table-sm"
           data-pagination="true"
           data-id-field="id"
    ></table>
    <app-representative-right-add-modal [display]="modalButtonClicked" [email]="email" [phone]="phone" (saveEvent)="saveRepresentationRights($event)"></app-representative-right-add-modal>
    <div class="box-typical-footer">
        <div class="tbl">
            <div class="tbl-row">
                <div class="tbl-cell tbl-cell-action text-right">
                    <a [routerLink]="['/list']" class="btn btn-inline btn-secondary">Katkesta</a>
                    <button class="btn btn-inline btn-primary swal-btn-info" (click)="btnSaveClicked($event)">Salvesta</button>
                </div>
            </div>
        </div>
    </div>
</section>

