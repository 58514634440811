import {CommonModule, DatePipe} from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPermissionsModule} from "ngx-permissions";

import { throwIfAlreadyLoaded } from '../core/module-import-guard';
import { SharedModule }    from '../shared/shared.module';

import { RoleRoutingModule }   from './role-routing.module';

import { RoleListComponent } from './list/list.component';
import {GDPRGuard} from "../user-registry/gdpr/gdpr.guard";
import {RoleAddComponent} from "./add/add.component";
import {RoleEditComponent} from "./edit/edit.component";
import {FormComponent} from "./form/form.component";
import {NgSelectModule} from "@ng-select/ng-select";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RoleRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPermissionsModule.forChild(),
        NgSelectModule,
    ],
    exports: [],
    declarations: [
        RoleListComponent,
        RoleAddComponent,
        RoleEditComponent,
        FormComponent
    ],
    providers: [
        DatePipe,
        GDPRGuard
    ]
})
export class RoleModule {
    constructor( @Optional() @SkipSelf() parentModule: RoleModule) {
        throwIfAlreadyLoaded(parentModule, 'RoleModule');
    }
}
