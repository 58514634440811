import {NgModule, LOCALE_ID, APP_INITIALIZER} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ResourceModule} from "@ngx-resource/handler-ngx-http";
import {NgxPermissionsModule} from "ngx-permissions";

import { AppComponent }   from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule }    from './core/core.module';
import { SharedModule }    from './shared/shared.module';
import { HeaderComponent }    from './header.component';
import { FooterComponent }    from './footer.component';
import { SideMenuComponent } from './side-menu.component';
import {RepresentativeModalComponent} from "./representative-modal.component";
import { UserNotFoundComponent } from './core/components/user-not-found.component';
import { ConfigService } from 'app/config/config.service';
import { AuthService } from './core/services/auth.service';
import { UserRegistryModule } from "./user-registry/user-registry.module";
import {PermissionsService} from "./shared/services/permissions.service";

import {CommonModule, registerLocaleData} from '@angular/common';
import localeEt from '@angular/common/locales/et';
import localeEtExtra from '@angular/common/locales/extra/et';

import {appInitializerFactory} from "./app-initializer";
import {ResourceHandler} from "@ngx-resource/core";
import {UserRes} from "./core/resource/user.resource";
import {RoleRes} from "./core/resource/role.resource";
import {UserLoginRes} from "./core/resource/user-login.resource";
import {CompanyRes} from "./core/resource/company.resource";
import {CountryRes} from "./core/resource/country.resource";
import {SubstitutionRes} from "./core/resource/substitution.resource";
import {CompanyModule} from "./company/company.module";
import {ClassifierService} from "./core/services/classifier.service";
import {ClassifierRes} from "./core/resource/classifier.resource";
import {ClassifierLabelPipe} from "./shared/pipes/classifier-label.pipe";
import {RoleModule} from "./role/role.module";
import {PublicDataRes} from "./core/resource/public-data.resource";
import {environment} from "environments/environment";
import {UR_CORE_CONFIG} from "./core/injection-tokens";
import {BsModalService, ModalModule} from "ngx-bootstrap/modal";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {JobTitleRes} from "@app/core/resource/job-title.resource";
import {TooltipModule} from "ngx-bootstrap/tooltip";

registerLocaleData(localeEt, localeEtExtra);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SideMenuComponent,
    RepresentativeModalComponent,
    UserNotFoundComponent,
  ],
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    CompanyModule,
    RoleModule,
    UserRegistryModule,
    HttpClientModule,
    ResourceModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forRoot(),
    CommonModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  providers: [
    {
      provide: UR_CORE_CONFIG,
      useValue: environment
    },
    {
      provide: LOCALE_ID,
      useValue: "et"
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AuthService],
      multi: true
    },
    {provide: UserLoginRes, useClass: UserLoginRes, deps: [ResourceHandler, ConfigService]},
    {provide: UserRes, useClass: UserRes, deps: [ResourceHandler, AuthService, ConfigService]},
    {provide: SubstitutionRes, useClass: SubstitutionRes, deps: [ResourceHandler, AuthService, ConfigService]},
    {provide: CompanyRes, useClass: CompanyRes, deps: [ResourceHandler, AuthService, ConfigService]},
    {provide: PublicDataRes, useClass: PublicDataRes, deps: [ResourceHandler, AuthService, ConfigService]},
    {provide: RoleRes, useClass: RoleRes, deps: [ResourceHandler, AuthService, ConfigService]},
    {provide: CountryRes, useClass: CountryRes, deps: [ResourceHandler, AuthService, ConfigService]},
    {provide: ClassifierRes, useClass: ClassifierRes, deps: [ResourceHandler, ConfigService]},
    {provide: JobTitleRes, useClass: JobTitleRes, deps: [ResourceHandler, AuthService, ConfigService]},
    AuthService,
    PermissionsService,
    ClassifierService,
    ClassifierLabelPipe,
    BsModalService
  ],
    exports: [
        ClassifierLabelPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
