import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {UserDto} from '@app/core/resource-dto/user';
import {BsTableControl} from '@app/shared/directives/bs-table-control.directive';
import {ManageService} from '../manage/manage.service';
import {UserRes} from '@app/core/resource/user.resource';
import {AuthService} from '@app/core/services/auth.service';
import {PermissionsService} from '@app/shared/services/permissions.service';
import {combineLatest, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UserLoginDto} from '@app/core/resource-dto/user-login';
import swal from 'sweetalert';
import {CompanyDto} from '@app/core/resource-dto/company';
import {LargeClientUsersService} from '@app/user-registry/large-client-users.service';
import {HttpStatusCode} from '@angular/common/http';
import {ErrorCode} from '@app/user-registry/error-code';
import User = UserLoginDto.User;

@Component({
    templateUrl: './detail.component.html',
    providers: [LargeClientUsersService]
})
export class UserDetailComponent implements AfterViewInit, OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();

    @Input()
    public basisOfAssigns: UserDto.BasisOfAssign[] = [];

    @ViewChild(BsTableControl)
    private readonly bsTableControl: BsTableControl;

    @ViewChild('bsTableElement')
    private bsTableElement: ElementRef;

    private userCompanies: UserDto.Company[] = [];

    public btnEditEnabled = false;
    public fieldSapCostCenterEnabled = false;

    public user: UserDto.User = null;
    public user$ = this.manageService.user$;
    public userToEdit: UserDto.User = null;
    public currentUser: User = null;
    public isLargeClientManagerUser: boolean = false;
    public birthday: string = '';

    company: Promise<CompanyDto.Company>;

    public bsTableOptions: any = {
        toolbar: undefined,
        showFooter: false,
        pagination: false,
        columns: [
            {
                field: 'companyName',
                title: 'Ettevõte',
                sortable: true,
                formatter: (value: any, row: any) => {
                    return '<a href="#" class="company-link" data-id="' + row.companyId + '">' + value + '</a>';
                }
            },
            {
                field: 'roles',
                title: 'Rollid',
                sortable: true
            },
            {
                field: 'phone',
                title: 'Telefon',
                sortable: true
            },
            {
                field: 'email',
                title: 'E-post',
                sortable: true
            },
            {
                field: 'jobTitles',
                title: 'Ametinimetused',
                sortable: true
            },
        ],
        data: []
    };

    public constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly userRes: UserRes,
        private readonly manageService: ManageService,
        private readonly permissionsService: PermissionsService,
        private readonly largeClientUsersService: LargeClientUsersService
    ) {
        combineLatest([
            this.user$,
            this.authService.user$,
            this.permissionsService.permissions$,
        ]).pipe(takeUntil(this.ngDestroy)
        ).subscribe(([user, currentUser, permissions]: [UserDto.User, User, any]) => {
            this.fieldSapCostCenterEnabled = !!permissions['sap-code.manage']
            this.userToEdit = user;
            this.currentUser = currentUser;
            this.setPermissionToEdit();

            this.manageService.getBasisOfAssign(user.id).then(res => {
                this.basisOfAssigns = res;
            })
        });

        this.user$.pipe(takeUntil(this.ngDestroy)).subscribe(val => {
            this.btnEditEnabled = val.id === this.authService.getCurrentUser().id;
        });
        this.allowEditIfHasLargeClientRepresentationPermission();
    }

    public setPermissionToEdit() {
        if (this.userToEdit.id === this.currentUser.id) {
            this.showEditButtons();
            this.btnEditEnabled = true;

            return this.btnEditEnabled;
        }

        this.permissionsService.hasPermission(['admin.access']).then((isAdmin: boolean) => {
            if (isAdmin) {
                this.allowEdit();
            } else {
                this.permissionsService.hasPermission(['user.manage.large-client-manager-value']).then((hasPermissionLargeClient: boolean) => {
                    if (hasPermissionLargeClient) {
                        this.largeClientPermissions();
                    } else {
                        this.permissionsService.hasPermission(['user.manage']).then((hasPermissionUser: boolean) => {
                            if (hasPermissionUser) {
                                this.userPermissions();
                            }
                        });
                    }
                });
            }
        });
    }

    public largeClientPermissions() {
        if (this.userToEdit !== null) {
            this.largeClientUsersService.getUserCompanies(this.userToEdit.id);
            const currentCompanyId = this.authService.getCurrentUserCompany().id;

            this.largeClientUsersService.companies$.pipe(takeUntil(this.ngDestroy)).subscribe((companies: UserDto.Company[]) => {
                const currentCompany = companies.filter(c => c.companyId === currentCompanyId);
                const roles = currentCompany.map(c => c.roles);

                if (roles.some(value => value.some(v => v.name === 'Suurkliendihaldur'))) {
                    this.btnEditEnabled = false;
                    return this.btnEditEnabled;
                } else if (this.user.largeClientManagerUser !== null) {
                    if (this.user.largeClientManagerUser.id !== this.currentUser.id) {
                        this.btnEditEnabled = false;
                        return this.btnEditEnabled;
                    } else {
                        return this.allowEdit();
                    }
                } else {
                    return this.allowEdit();
                }
            })
        }
    }

    public userPermissions() {
        if (this.userToEdit.largeClientManagerUser !== null) {
            if (this.userToEdit.largeClientManagerUser.id === this.currentUser.id) {
                this.btnEditEnabled = false;
            } else if (this.isLargeClientManagerUser === true) {
                this.btnEditEnabled = false;
                return this.btnEditEnabled;
            }
        } else {
            this.permissionsService.hasPermission(['representation-right.manage']).then((hasPermission: boolean) => {
                if (hasPermission) {
                    this.allowEdit();
                }
            })
        }
    }

    public allowEditIfHasLargeClientRepresentationPermission() {
        if (this.user?.largeClientManagerUser !== null) {
            this.permissionsService.hasPermission(['user.manage.large-client-representation'])
                .then((hasPermission: boolean) => {
                    if (!!hasPermission) {
                        this.allowEdit();
                    }
                });
        }
    }

    public allowEdit() {
        this.btnEditEnabled = true;
        this.showEditButtons();
    }

    public showEditButtons() {
        if (this.bsTableOptions.columns.length === 6) {
            return;
        }
        this.bsTableOptions.columns.push(
            {
                sortable: false,
                width: '152px',
                formatter: (value: any, row: any, rowIndex: any) => {
                    return '<button class="btn btn-primary btn-sm edit-link" data-id="' + row.companyId + '">Muuda</button>' +
                        ' <button class="btn btn-primary swal-btn-cancel btn-sm delete-link" data-id="' + row.id + '">Kustuta</button>';
                },
            }
        );
        this.bsTableControl.ngOnChanges({options: this.bsTableOptions});
        this.updateUserCompanies(this.userCompanies);
    }

    public ngAfterViewInit() {

        let $table = jQuery(this.bsTableElement.nativeElement);
        if ($table.length) {
            $table.on('click', '.company-link', (e: any) => {
                e.preventDefault();

                let id: any = jQuery(e.target).data('id');
                if (id) {
                    this.router.navigate(['/companies/view', id], {relativeTo: this.route});
                }

                return false;
            });

            $table.on('click', '.edit-link', (e: any) => {
                e.preventDefault();

                let id: any = jQuery(e.target).data('id');
                if (id) {
                    this.router.navigate(['/user/representation-right/edit', this.user.id, id], {relativeTo: this.route});
                }

                return false;
            });
            $table.on('click', '.delete-link', (e: any) => {
                e.preventDefault();

                let id: any = jQuery(e.target).data('id');

                if (id) {
                    let userCompany: UserDto.Company = this.userCompanies.find((uc: UserDto.Company) => uc.id == id);
                    if (userCompany) {
                        swal(
                            {
                                title: 'Kas oled kindel?',
                                text: 'Ettevõtte "' + userCompany.name + '" esindusõigus kustutatakse.',
                                type: 'info',
                                showCancelButton: true,
                                cancelButtonText: 'Tühista',
                                cancelButtonClass: 'btn-default',
                                confirmButtonText: 'Jah',
                                confirmButtonClass: 'btn-primary',
                                closeOnConfirm: false,
                            },
                            (isConfirm: any) => {
                                if (isConfirm) {
                                    this.manageService.deleteCompany(userCompany.companyId).then(() => {
                                        this.manageService.loadUser();
                                        swal({
                                            title: 'Esindusõigus kustutatud!',
                                            type: 'success',
                                            timer: 2000,
                                            showConfirmButton: false
                                        });
                                    });
                                }
                            }
                        );
                    }
                }

                return false;
            });
        }
        this.getUserCompany();
    }

    public getUserCompany() {
        this.user$.pipe(takeUntil(this.ngDestroy)).subscribe((user: UserDto.User) => {
            this.user = user;

            this.userRes.queryCompany({userId: user.id}, {companyId: this.authService.getCurrentUserCompany().id}, null, null).then(
                (result: UserDto.CompanyQueryOutput) => {
                    this.updateUserCompanies(result.companies);
                },
                (error: any) => {
                    if (error.status == HttpStatusCode.NotFound) {
                        this.updateUserCompanies([]);
                        return;
                    }
                    throw error;
                }
            );
        });
    }

    public ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public userAddRepresentationRight() {
        this.router.navigate(['/user/representation-right/add', this.user.id], {relativeTo: this.route});
    }

    public delete() {
        swal(
            {
                title: 'Kas oled kindel?',
                text: 'Kasutaja ' + this.user.forename + ' ' + this.user.surname + ' kustutatakse',
                type: 'info',
                showCancelButton: true,
                cancelButtonText: 'Tühista',
                cancelButtonClass: 'btn-default',
                confirmButtonText: 'Jah',
                confirmButtonClass: 'btn-primary',
                closeOnConfirm: false,
            },
            (isConfirm: any) => {
                if (isConfirm) {
                    this.manageService.deleteUser(this.user.id).then(() => {
                            this.manageService.loadUser();
                            swal({
                                title: 'Kasutaja kustutatud!',
                                type: 'success',
                                timer: 2000,
                                showConfirmButton: false
                            });
                        },
                        (error: any) => {
                            if ((error.status === HttpStatusCode.PreconditionFailed || error.status === HttpStatusCode.Forbidden) && error.body
                                && error.body.errorCode === ErrorCode.USER_DELETE_NOT_ALLOWED) {
                                swal({
                                    title: 'Kasutaja kustutamine pole lubatud.',
                                    type: 'error',
                                    timer: 3000,
                                    showConfirmButton: false
                                });
                            }
                        });
                }
            }
        );
    }

    public restore() {
        swal(
            {
                title: 'Kas oled kindel?',
                text: 'Kasutaja ' + this.user.forename + ' ' + this.user.surname + ' taastatakse',
                type: 'info',
                showCancelButton: true,
                cancelButtonText: 'Tühista',
                cancelButtonClass: 'btn-default',
                confirmButtonText: 'Jah',
                confirmButtonClass: 'btn-primary',
                closeOnConfirm: false,
            },
            (isConfirm: any) => {
                if (isConfirm) {
                    let user: UserDto.UserInput = new UserDto.UserInput();
                    user.id = this.user.id;
                    user.personalCode = this.user.personalCode;
                    user.forename = this.user.forename;
                    user.surname = this.user.surname;
                    user.email = this.user.email;
                    user.birthday = this.user.birthday;
                    user.gender = this.user.gender;
                    user.info = this.user.info;
                    user.countryId = this.user.country.id;
                    user.sapCostCenter = this.user.sapCostCenter;
                    user.largeClientManagerUserId = this.user.largeClientManagerUser !== null ? this.user.largeClientManagerUser.id : null;
                    user.undelete = true;
                    user.phoneNumbers = this.user.phoneNumbers;

                    this.manageService.updateUser(user).then(() => {
                        this.manageService.loadUser();
                        swal({
                            title: 'Kasutaja taastatud!',
                            type: 'success',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    });
                }
            }
        );
    }

    private updateUserCompanies(companies: UserDto.Company[]) {
        this.userCompanies = companies;
        this.bsTableControl.load(companies.map((uc: UserDto.Company) => {
            return {
                id: uc.id,
                companyId: uc.companyId,
                companyName: uc.name,
                roles: uc.roles.map((role: UserDto.RoleData) => {
                    return role.name
                }).join(', '),
                phone: uc.phone,
                email: uc.email,
                jobTitles: uc.jobTitles.map((title: UserDto.JobTitle) => {
                    return title.name
                }).join(', ')
            };
        }));
    }
}
