import {Component, ViewChild, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserDto } from "../../core/resource-dto/user";

import {UserFormComponent} from "../form/form.component";
import {ManageService} from "../manage/manage.service";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    templateUrl: './edit.component.html',
    styleUrls: [
        '../../../assets/styles/pages/editor.less',
        '../../../assets/styles/pages/project.less'
    ]
})
export class UserEditComponent implements OnDestroy {

    private ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(UserFormComponent)
    formComponent:UserFormComponent;

    public user$:Observable<UserDto.User>;
    public user:UserDto.User = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private manageService: ManageService
    ) {
        this.user$ = manageService.user$;
        this.user$.pipe(takeUntil(this.ngDestroy)).subscribe((user:UserDto.User) => {
            this.user = user;
        });
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    btnSaveClicked(e:any) {
        this.formComponent.btnSaveClicked(e);
    }
}
