import { Injectable } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { ConfigService } from 'app/config/config.service';

import { AuthGuardedResource } from './auth-guarded.resource';

import { RoleDto } from '../resource-dto/role';
import {ResourceHandler} from "@ngx-resource/core/src/ResourceHandler";
import {
    IResourceMethod,
    IResourceMethodStrict,
    ResourceAction,
    ResourceParams,
    ResourceRequestMethod
} from "@ngx-resource/core";

@Injectable()
@ResourceParams({
    pathPrefix: '/roles',
    withCredentials: true
})
export class RoleRes extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl(''));
    }

    @ResourceAction({
        expectJsonArray: false
    })
    query: IResourceMethod<RoleDto.QueryInput, RoleDto.QueryOutput>;

    @ResourceAction({
        path: '/{!roleId}'
    })
    get: IResourceMethodStrict<any, any, {roleId: any}, RoleDto.Role>;

    @ResourceAction({
        method: ResourceRequestMethod.Post
    })
    save: IResourceMethodStrict<RoleDto.RoleInput, null, {}, RoleDto.Role>;

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/{!roleId}'
    })
    update: IResourceMethodStrict<RoleDto.RoleInput, {undelete: boolean}, {roleId: number}, RoleDto.Role>;

    @ResourceAction({
        path: '/{!roleId}',
        method: ResourceRequestMethod.Delete
    })
    delete: IResourceMethodStrict<{roleId: number}, null, any, void>;
}
