import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RoleListComponent } from './list/list.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { GDPRGuard } from '../user-registry/gdpr/gdpr.guard';
import {RoleAddComponent} from "./add/add.component";
import {RoleEditComponent} from "./edit/edit.component";

const routes: Routes = [
    {
        path: 'roles',
        children: [
            { path: '', component: RoleListComponent },
            { path: 'add', component: RoleAddComponent },
            {
                path: 'edit',
                pathMatch: 'full',
                redirectTo: '/roles'
            },
            {
                path: 'edit/:roleId',
                component: RoleEditComponent
            },
        ],
        canActivate: [ NgxPermissionsGuard, GDPRGuard ],
        data: {
            permissions: {
                only: [
                    'role.manage'
                ]
            }
        }
    }
];

@NgModule({
    imports:    [ RouterModule.forChild(routes) ],
    exports:    [ RouterModule ]
})
export class RoleRoutingModule { }
