import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {DatePipe} from "@angular/common";
import {ActivatedRoute, Router} from '@angular/router';

import {UserDto} from "@app/core/resource-dto/user";
import {BsTableControl} from "@app/shared/directives/bs-table-control.directive";
import {combineLatest, Subject} from "rxjs";
import {ManageService} from "../manage/manage.service";
import {UserRes} from "@app/core/resource/user.resource";
import {AuthService} from "@app/core/services/auth.service";
import {PermissionsService} from "@app/shared/services/permissions.service";
import {CompanyDto} from "@app/core/resource-dto/company";
import {ClassifierLabelPipe} from "@app/shared/pipes/classifier-label.pipe"

import {CompanyRes} from "@app/core/resource/company.resource";
import {ClassifierService} from "@app/core/services/classifier.service";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";

declare let jQuery:any;
declare let swal:any;

@Component({
    templateUrl: './view.component.html',
    providers: [ ManageService ]
})
export class ViewComponent implements AfterViewInit, OnDestroy {

    private ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(BsTableControl)
    bsTableControl:BsTableControl;

    @ViewChild('bsTableElement')
    bsTableElement:ElementRef;

    public company:CompanyDto.Company = null;
    public users: UserDto.User[] = null;

    public bsTableOptions:any = {
        toolbar: undefined,
        pagination: true,
        columns: [
            {
                field: 'name',
                title: 'Nimi',
                sortable: true
            },
            {
                field: 'roles',
                title: 'Rollid',
                sortable: true
            },
            {
                field: 'phone',
                title: 'Telefon',
                sortable: true
            },
            {
                field: 'email',
                title: 'E-post',
                sortable: true
            },
        ],
        data: []
    };

    constructor(
        private route: ActivatedRoute,
        private classifierService: ClassifierService,
        private classifierLabelPipe: ClassifierLabelPipe,
        private router: Router,
        private datePipe:DatePipe,
        private authService:AuthService,
        private userRes: UserRes,
        private companyRes: CompanyRes,
        private manageService: ManageService,
        private permissionsService: PermissionsService
    ) {
        this.route.params.pipe(takeUntil(this.ngDestroy)).subscribe(params => {
            this.manageService.loadCompany(true, params['companyId'])
        });

        this.permissionsService.hasPermission('representation-right.manage').then((hasPermission:boolean) => {
            if(hasPermission) {
                this.bsTableOptions.columns.push(
                    {
                        sortable: false,
                        width: '152px',
                        formatter: (value:any, row:any, rowIndex:any) => {
                            return '<button class="btn btn-primary btn-sm edit-link" data-id="'+row.id+'">Muuda</button> <button class="btn btn-primary swal-btn-cancel btn-sm delete-link" data-id="'+row.id+'">Kustuta</button>';
                        },
                    }
                );
            }
        });

      combineLatest([
        this.manageService.company$ ,
        this.manageService.users$
      ]).pipe(
        takeUntil(this.ngDestroy),
        distinctUntilChanged()
      ).subscribe(([company, users] : [any, any]) => {
        if(!company) return;

        this.company = company;
        this.users = users;
        this.updateUsers();
      });
    }

    ngAfterViewInit() {
        let $table = jQuery(this.bsTableElement.nativeElement);

        if($table.length) {
            $table.on('click', '.edit-link', (e:any) => {
                e.preventDefault();

                let id:any = jQuery(e.target).data('id');
                if(id) {
                    this.router.navigate(['/companies/representation-right/edit/', this.company.id, id], { relativeTo: this.route });
                }

                return false;
            });

        }

        $table.on('click', '.delete-link', (e:any) => {
            e.preventDefault();

            let id:any = jQuery(e.target).data('id');

            if(id) {
                swal(
                    {
                        title: "Kas oled kindel?",
                        text: 'Ettevõtte "'+this.company.name+'" esindusõigus kustutatakse.',
                        type: "info",
                        showCancelButton: true,
                        cancelButtonText: "Tühista",
                        cancelButtonClass: "btn-default",
                        confirmButtonText: "Jah",
                        confirmButtonClass: "btn-primary",
                        closeOnConfirm: false,
                    },
                    (isConfirm:any) => {
                        if (isConfirm) {
                            this.manageService.removeUserRepresentationRight(id, this.company.id).then(() => {
                                   this.manageService.loadCompany(true, this.company.id);
                                   swal({
                                       title: "Esindusõigus kustutatud!",
                                       type: "success",
                                       timer: 2000,
                                       showConfirmButton: false
                                   });
                            });
                        }
                    }
                    );
            }

            return false;
        });
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    navigateToAddRepresentationRight() {
        this.router.navigate(['/companies/representation-right/add', this.company.id], {relativeTo: this.route})
    }
    editCompany() {
        this.router.navigate(['/companies/edit', this.company.id], {relativeTo: this.route});
    }

    updateUsers() {
        this.bsTableControl.load(this.users.map((user:UserDto.User) => {
            return {
                id: user.id,
                name: user.forename + ' ' + user.surname,
                roles: user.userCompanies.filter(uc => uc.companyId === this.company.id)[0]?.roles?.map((role:UserDto.RoleData) => {return role.name}).join(', '),
                phone: user.userCompanies.filter(uc => uc.companyId === this.company.id)[0]?.phone,
                email: user.userCompanies.filter(uc => uc.companyId === this.company.id)[0]?.email,
            };
        }));
    }
}
