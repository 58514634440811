import { Injectable } from '@angular/core';

import { ConfigService } from 'app/config/config.service';
import { ClassifierDto } from "../resource-dto/classifier";
import {IResourceMethod, Resource, ResourceAction, ResourceParams} from "@ngx-resource/core";
import {ResourceHandler} from "@ngx-resource/core/src/ResourceHandler";

@Injectable()
@ResourceParams({
    withCredentials: true
})
export class ClassifierRes extends Resource {

    constructor (
        protected requestHandler: ResourceHandler,
        protected config:ConfigService
    ) {
        super(requestHandler);

        this.$setUrl(config.getBackendUrl('/classifiers'));
    }

    @ResourceAction({})
    query: IResourceMethod<{}, ClassifierDto.QueryOutput>;
}
