import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild} from '@angular/core';
import {CompanyAdapter} from "../form/company-adapter";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UserDto} from "@app/core/resource-dto/user";
import {RoleAdapter} from "../form/role-adapter";
import {Observable, Subject} from "rxjs";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {JobTitleAdapter} from "@app/user-registry/form/job-title-adapter";
import {PermissionsService} from "@app/shared/services/permissions.service";
import {ClassifierService} from "@app/core/services/classifier.service";

declare let jQuery: any;

@Component({
    selector: 'app-representative-right-add-modal',
    templateUrl: './representation-right-add-modal.component.html',
    providers: [CompanyAdapter, RoleAdapter, JobTitleAdapter]
})
export class RepresentationRightAddModalComponent implements OnDestroy, OnChanges {

    private ngDestroy: Subject<void> = new Subject<void>();

    @Output()
    protected readonly saveEvent: EventEmitter<UserDto.CompanyInput> = new EventEmitter<UserDto.CompanyInput>();

    @Input()
    protected display: boolean;

    @Input()
    protected email: string;

    @Input()
    protected phone: string;

    @ViewChild('addRepresentativeRight')
    protected modalRef: ElementRef;

    public basisOfAssignDescription: string = '';
    public basisOfAssignRequired: boolean = false;
    public formErrors: any = {};
    public showFormErrors: boolean = false;
    public permissions$: Observable<any>;
    public currentPermissions: any = {};
    public company: any = null;
    public form: FormGroup = new FormGroup({
        company: new FormControl(null, [Validators.required]),
        roles: new FormControl([], [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        phone: new FormControl('', [Validators.required]),
        jobTitles: new FormControl([], [Validators.required]),
        basisOfAssigns: new FormArray([])
    });

    public constructor(
        public companyAdapter: CompanyAdapter,
        public roleAdapter: RoleAdapter,
        public jobTitleAdapter: JobTitleAdapter,
        private permissionsService: PermissionsService,
        private formBuilder: FormBuilder,
        private classifierService: ClassifierService,
    ) {
        this.permissions$ = this.permissionsService.permissions$;
        this.permissions$.pipe(distinctUntilChanged(), takeUntil(this.ngDestroy)).subscribe((permissions: any) => {
            this.currentPermissions = permissions;
        });

        this.companyAdapter.options$.pipe(takeUntil(this.ngDestroy)).subscribe((options: any[]) => {
            if (options.length == 1) {
                this.company = options[0];
                return;
            }
            if (options.length > 1) {
                this.form.get('company').enable();
            } else {
                this.form.get('company').disable();
            }
        });

        this.classifierService.classifierSource$.pipe(takeUntil(this.ngDestroy)).subscribe(_ => {
            const basisOfAssignClassifier =
                this.classifierService.getClassifierValueByCode('ADDITIONAL_PARAMETER', 'ROLE_ASSIGN_BASIS_DESCRIPTION');
            if (basisOfAssignClassifier) {
                this.basisOfAssignDescription = basisOfAssignClassifier.name
            }
        });

        this.jobTitleAdapter.loadJobTitles();
        this.companyAdapter.loadCompanies();
        this.roleAdapter.loadRoles();
    }

    get basis() {
        return this.form.get('basisOfAssigns') as FormArray;
    }

    public updateRoleDto(inputRole: UserDto.RoleData) {
        const formModel = this.form.getRawValue();

        formModel.roles.filter((role: UserDto.RoleData) => {
            if (role.id === inputRole.id) {
                role.basisOfAssign = inputRole.basisOfAssign
            }
        });
    }

    ngOnChanges(changes: any) {
        if (changes['email']) {
            this.form.get('email').setValue(this.email);
        } else if (changes['phone']) {
            this.form.get('phone').setValue(this.phone);
        }
    }

    public onRoleRemove(event: any) {
        if (event.value.basisOfAssignRequired) {
            const remove = this.basis.controls.map((value, index) => {
                return {index: index, id: value.value.id};
            }).filter(v => v.id == event.value.id);

            this.basis.removeAt(remove[0].index);
        }
    }

    public onRoleAdd(event: any) {
        if (event.basisOfAssignRequired) {
            const data = this.formBuilder.group({
                basisOfAssign: new FormControl(
                    event.basisOfAssign, [Validators.required, Validators.pattern('https://jira.rkas.ee/browse/SD-' + '\\d{4,5}')]
                ),
                name: new FormControl(event.text),
                id: new FormControl(event.id)
            });
            this.basis.push(data);
        }
    }

    public hasErrors(field) {
        return field.invalid && (field.dirty || field.touched);
    }

    public hasInputErrors(field: AbstractControl): boolean {
        return field.invalid && (field.dirty || field.touched);
    }

    public btnDismissErrorsClicked() {
        for (const field in this.formErrors) {
            this.formErrors[field] = null;
        }

        this.showFormErrors = false;
    }

    public save() {
        this.form.updateValueAndValidity();
        this.form.markAllAsTouched();

        if (!this.currentPermissions['user.access.company-role-assign-basis'] && !this.form.get('basisOfAssigns').value) {
            this.formErrors['basisOfAssignRequired'] = true

            return;
        }

        if (this.form.valid) {
            let input: UserDto.CompanyData = this.prepareInputDtoForSubmit();
            this.saveEvent.emit(input);
            jQuery(this.modalRef.nativeElement).modal('hide');
        }
    }

    public ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    private prepareInputDtoForSubmit(): UserDto.CompanyData {
        const formModel = this.form.getRawValue();

        let dto: UserDto.CompanyData = new UserDto.CompanyData();
        dto.companyId = formModel.company.id;
        dto.companyName = formModel.company.text;
        dto.email = formModel.email;
        dto.phone = formModel.phone;
        let roles: UserDto.RoleData[] = [];
        formModel.roles.forEach((role: any) => {
            let roleDto = new UserDto.RoleData();
            roleDto.id = role.id;
            roleDto.name = role.text;
            roleDto.basisOfAssign = role.basisOfAssign;
            roles.push(roleDto);
        });
        dto.roles = roles;
        dto.jobTitles = formModel.jobTitles.map(jt => ({
            id: jt.id,
            name: jt.text
        }));

        return dto;
    }
}
