import {CommonModule, DatePipe} from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPermissionsModule} from "ngx-permissions";

import { throwIfAlreadyLoaded } from '../core/module-import-guard';
import { SharedModule }    from '../shared/shared.module';

import { UserRegistryRoutingModule }   from './user-registry-routing.module';

import { GDPRComponent } from './gdpr/gdpr.component';
import { GDPRGuard } from './gdpr/gdpr.guard';

import { UserListComponent } from './list/list.component';
import { ManageComponent } from './manage/manage.component';
import { UserDetailComponent } from './detail/detail.component';
import { CompanyAddComponent } from './detail/company-add.component';
import { CompanyEditComponent } from './detail/company-edit.component';
import { UserFormComponent } from './form/form.component';
import { UserAddComponent } from './add/add.component';
import { UserEditComponent } from './edit/edit.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {SubstitutionManageComponent} from "./detail/substitution/substitution-manage.component";
import {SusbstitutionTableComponent} from "./detail/substitution/susbstitution-table.component";
import {UserAdapter} from "./form/user-adapter";
import {RepresentationRightAddModalComponent} from "./add/representation-right-add-modal.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {BasisOfAssignComponent} from "@app/user-registry/detail/basisOfAssign/basis-of-assign.component";
import {TooltipModule} from "ngx-bootstrap/tooltip";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        UserRegistryRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxPermissionsModule.forChild(),
        NgSelectModule,
        BsDatepickerModule,
        TooltipModule,
    ],
    exports: [],
    declarations: [
        GDPRComponent,
        UserListComponent,
        ManageComponent,
        UserDetailComponent,
        CompanyAddComponent,
        RepresentationRightAddModalComponent,
        CompanyEditComponent,
        SubstitutionManageComponent,
        SusbstitutionTableComponent,
        UserAddComponent,
        UserEditComponent,
        UserFormComponent,
        BasisOfAssignComponent
    ],
    providers: [
        UserAdapter,
        DatePipe,
        GDPRGuard
    ]
})
export class UserRegistryModule {
    constructor( @Optional() @SkipSelf() parentModule: UserRegistryModule) {
        throwIfAlreadyLoaded(parentModule, 'UserRegistryModule');
    }
}
