<header class="site-header">
    <div class="container-fluid">
        <button id="show-hide-sidebar-toggle" class="show-hide-sidebar" (click)="toggleSidebar()">
            <span>toggle menu</span>
        </button>

        <button class="hamburger hamburger--htla" [hidden]="!isUserLoggedIn">
            <span>toggle menu</span>
        </button>

        <div class="site-header-content">
            <div class="site-header-content-in">
                <div class="site-header-shown">
                    <representative-select [hidden]="!isUserLoggedIn" *ngIf="(userCompany$ | async)"></representative-select>

                    <button type="button" class="burger-right">
                        <i class="font-icon-menu-addl"></i>
                    </button>
                </div><!--.site-header-shown-->

                <div class="mobile-menu-right-overlay"></div>
                <div class="site-header-collapsed">
                    <div class="site-header-collapsed-in">
                        <div class="text-center d-none d-lg-block">
                            <a [routerLink]="['/']" class="site-logo" *ngIf="getLogo()">
                                <img class="hidden-md-down" [src]="getLogo()" [alt]="name">
                            </a>
                        </div>
                    </div><!--.site-header-collapsed-in-->
                </div><!--.site-header-collapsed-->
            </div><!--site-header-content-in-->
        </div><!--.site-header-content-->
    </div><!--.container-fluid-->
</header><!--.site-header-->
