import {Component} from '@angular/core';
import {ConfigService} from "app/config/config.service";
import {PermissionsService} from "./shared/services/permissions.service";


@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html'
})
export class SideMenuComponent {
    constructor(
      public config: ConfigService,
      protected permissionsService: PermissionsService
    ) {}
}
