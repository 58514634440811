import {Component, forwardRef, Input, OnDestroy} from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup, NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors, Validator,
    Validators
} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {OptionalValidator} from "@app/core/validators/optional-validator";
import {Subject} from "rxjs";

@Component({
    selector: 'app-form-email',
    templateUrl: './email.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EmailComponent),
            multi: true
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EmailComponent),
            multi: true,
        }
    ]
})
export class EmailComponent implements ControlValueAccessor, Validator,  OnDestroy {
    protected ngDestroy: Subject<void> = new Subject<void>();

    value: String;
    onChange: (value: any) => void;
    onTouched: () => void;
    disabled: boolean;

    @Input()
    public showErrors: boolean;

    @Input()
    public isRequired: boolean = true;

    @Input()
    private separator: string = ','

    discloseValidatorChange = () => {};

    @Input()
    public errorMessage = {
        'required': 'Palun sisesta e-posti aadress.',
        'email': 'Palun sisesta korrektne e-posti aadress.'
    }

    formModel: FormGroup = this.formBuilder.group({
      'emails': this.formBuilder.array([]),
    });

    constructor(
        private formBuilder: FormBuilder,
    ) {

    }

    get emailArray(): FormArray {
        return this.formModel.get('emails') as FormArray;
    }

    isErrorVisible(field: AbstractControl) : boolean {
        return field.touched || this.showErrors;
    }

    writeValue(value: String): void {
      this.value = value ? value : '';
      let emails = this.value.split(this.separator);

      if(this.emailArray.length != 0) {
        this.emailArray.clear({emitEvent: false})
      }
      for(const email of emails) {
        this.addEmailField(email)
      }

      this.formModel.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(_ => {
          let value = this.emailArray.value.join(this.separator);
          this.onChange(value);
      })
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    addEmailField(value: string = '') {
        let validators = [OptionalValidator.email];
        if(this.isRequired) validators.push(Validators.required);
        this.emailArray.push(new FormControl(value, validators))
    }

    removeEmail(i: number) {
        this.emailArray.removeAt(i);
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    registerOnValidatorChange(fn: () => void): void {
        this.discloseValidatorChange = fn;
    }

    validate(c: AbstractControl): ValidationErrors | null {
        return this.formModel.valid ? null : {invalid: true};
    }
}
