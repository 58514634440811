import {Injectable} from '@angular/core';

import { ReplaySubject } from "rxjs";
import {CompanyDto} from "../../core/resource-dto/company";
import {CompanyRes} from "../../core/resource/company.resource";
import {AuthService} from "../../core/services/auth.service";
import {UserRes} from "../../core/resource/user.resource";
import {UserDto} from "../../core/resource-dto/user";

@Injectable()
export class ManageService {

    protected companySource = new ReplaySubject<CompanyDto.Company>(1);
    protected usersSource = new ReplaySubject<UserDto.User[]>(1);

    company$ = this.companySource.asObservable();
    users$ = this.usersSource.asObservable();

    private company: CompanyDto.Company;
    private users: UserDto.User[];

    constructor(
        private authService: AuthService,
        private companyRes: CompanyRes,
        private userRes: UserRes

    ) {
        this.company$.subscribe((company: CompanyDto.Company) => {
            this.company = company;
        })
        this.users$.subscribe((users: UserDto.User[]) => {
            this.users = users;
        })
    }

    loadCompany(loadUsers: boolean, companyId?: number) {
        this.companyRes.get(null, {companyId: this.authService.getCurrentUserCompany().id}, {companyId: companyId}).then((company: CompanyDto.Company) => {
            this.setCompany(company);
            if (loadUsers) {
                if (companyId)
                    this.loadCompanyUsers(companyId);
                else
                    this.loadCompanyUsers(this.authService.getCurrentUserCompany().id);
            }
        });
    }

    loadCompanyUsers(companyId: number) {
        let queryInput: UserDto.QueryInput = new UserDto.QueryInput();
        queryInput.filterByCompanyId = companyId;
        queryInput.limit = 99999;
        this.userRes.query(queryInput).then((userQueryOutput: UserDto.QueryOutput) => {
            this.setCompanyUsers(userQueryOutput.users);
        },
            (error:any) => {
                if(error.status == 412) {
                    this.setCompanyUsers([]);
                    return;
                }
                throw error;
            });
    }

    setCompany(company: CompanyDto.Company) {
        this.companySource.next(company);
    }

    saveCompany(input: CompanyDto.CompanyInput) {
        return this.companyRes.save(input, {companyId: this.authService.getCurrentUserCompany().id});
    }

    updateCompany(input: CompanyDto.CompanyInput, undelete: boolean) {
        return this.companyRes.update(input, {companyId: this.authService.getCurrentUserCompany().id, undelete: undelete}, {companyId: input.id});
    }

    setCompanyUsers(users: UserDto.User[]) {
        this.usersSource.next(users);
    }

    removeUserRepresentationRight(userId: number, companyId: number) {
        return this.userRes.deleteCompany({userId: userId, companyId: companyId}, {companyId: this.authService.getCurrentUserCompany().id})
    }
}
