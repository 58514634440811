<section class="box-typical">
    <header class="box-typical-header">
        <div class="tbl-row">
            <div class="tbl-row">
                <div class="tbl-cell tbl-cell-title">
                    <h3>Õiguste andmise alused</h3>
                </div>
            </div>
        </div>
    </header>
        <table bs-table-control
               #bsTableElement
               [bs-table-options]="bsTableOptions"
               class="table-striped table-sm"
               data-pagination="true"
               data-id-field="id"
        ></table>
</section>
