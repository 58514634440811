import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../core/services/auth.service';

@Injectable()
export class GDPRGuard implements CanActivate, CanActivateChild {

    constructor(private _router: Router, private _authService: AuthService) {
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
        if (this._authService.getCurrentUser().gdprAccepted) {
            return true;
        } else {
            this._router.navigate(['/gdpr'], {
                queryParams: {
                    returnUrl: state.url
                }
            });
            return false;
        }
    }


    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
        return this.canActivate(childRoute, state);
    }
}
