import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RepresentativeSelectComponent } from "./components/representative-select.component";

@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule
    ],
    exports: [
        RepresentativeSelectComponent
    ],
    declarations: [
        RepresentativeSelectComponent
    ],
    providers: [
    ]
})
export class CoreModule {}
