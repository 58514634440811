import {Component, ViewChild} from '@angular/core';

import {FormComponent} from "../form/form.component";
import {ManageService} from "../manage.service";
import {RoleDto} from "../../core/resource-dto/role";

@Component({
    templateUrl: './add.component.html',
    providers: [ ManageService ],
    styleUrls: [
        '../../../assets/styles/pages/editor.less',
        '../../../assets/styles/pages/project.less'
    ]
})
export class RoleAddComponent {

    @ViewChild(FormComponent)
    formComponent:FormComponent;

    public role: RoleDto.Role = new RoleDto.Role();

    btnSaveClicked(e:any) {
        this.formComponent.btnSaveClicked(e);
    }
}
