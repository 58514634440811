<section class="box-typical">
    <header class="box-typical-header">
        <div class="tbl-row">
            <div class="tbl-row">
                <div class="tbl-cell tbl-cell-title">
                    <h3>Asendused</h3>
                </div>
            </div>
            <div class="tbl-cell tbl-cell-action-bordered" *ngxPermissionsOnly="'replacement.manage'">
                <button type="button" class="action-btn no-wrap" [routerLink]="['manage-substitution']">
                    <em class="font-icon font-icon-plus"></em>&nbsp;Lisa&nbsp;asendus
                </button>
            </div>
        </div>
    </header>
    <table bs-table-control
           #bsTableElement
           [bs-table-options]="bsTableOptions"
           class="table-striped table-sm"
           data-pagination="true"
           data-id-field="id"
    ></table>
</section>
