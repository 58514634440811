<form [formGroup]="form">
    <div class="alert alert-danger" role="alert" *ngIf="formErrors['invalid'] && showFormErrors">
        <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
            <span aria-hidden="true">&times;</span>
        </button>
        Vormil esineb vigu!
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="formErrors['user_company_already_exists']">
        <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
            <span aria-hidden="true">&times;</span>
        </button>
        Selline esindusõigus juba eksisteerib!
    </div>
    <div class="box-typical-body" style="padding:0 5px 0 15px">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="form-group row">
                    <label for="ettevõte" class="col-sm-2 form-control-label">Ettevõte</label>
                    <div *ngIf="this.company" class="col-sm-10">
                        <input [tabindex]="7" type="text" readonly class="form-control-plaintext"
                               [value]="company.name">
                    </div>
                    <div *ngIf="!this.company" class="col-sm-10">
                        <ng-select id="ettevõte" formControlName="company" bindLabel="text"
                                   [tabindex]="1"
                                   [items]="companyAdapter.optionsFiltered$ | async"
                                   [typeahead]="companyAdapter.querySource"
                                   [class.is-invalid]="hasErrors(form.get('company'))"
                        ></ng-select>
                        <ng-container *ngIf="hasErrors(form.get('company'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('company').errors?.required">
                                Palun määra ettevõte.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="kasutaja" class="col-sm-2 form-control-label">Kasutaja</label>
                    <div *ngIf="!this.isNew && this.user" class="col-sm-10">
                        <input [tabindex]="7" type="text" readonly class="form-control-plaintext"
                               [value]="user.forename + ' ' + user.surname">
                    </div>
                    <div *ngIf="isNew" class="col-sm-10">
                        <ng-select id="kasutaja" formControlName="user" bindLabel="text" bindValue="id"
                                   [tabindex]="1"
                                   [items]="userAdapter.optionsFiltered$ | async"
                                   [typeahead]="userAdapter.querySource"
                                   [class.is-invalid]="hasErrors(form.get('user'))"
                        ></ng-select>
                        <ng-container *ngIf="hasErrors(form.get('user'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('user').errors?.required">
                                Palun määra ettevõte.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">E-post</label>
                    <div class="col-sm-10">
                        <input [tabindex]="3" type="text" class="form-control"
                               [class.is-invalid]="hasErrors(form.get('email'))"
                               id="epostUus" placeholder="E-post" formControlName="email">
                        <ng-container *ngIf="hasErrors(form.get('email'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('email').errors?.required">
                                Palun sisesta e-posti aadress.
                            </small>
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('email').errors?.valid">
                                Palun sisesta korrektne e-posti aadress.
                            </small>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="form-group row">
                    <label for="rollid" class="col-sm-2 form-control-label">Rollid</label>
                    <div class="col-sm-10">
                        <ng-select id="rollid" formControlName="roles" bindLabel="text"
                                   [tabindex]="2"
                                   [multiple]="true"
                                   (remove)="onRoleRemove($event)"
                                   (add)="onRoleAdd($event)"
                                   [items]="roleAdapter.options$ | async"
                                   [typeahead]="roleAdapter.querySource"
                                   [class.is-invalid]="hasErrors(form.get('roles'))"
                        ></ng-select>
                        <ng-container *ngIf="hasErrors(form.get('roles'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('roles').errors?.required">
                                Palun määra rollid.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">Ametinimetused</label>
                    <div class="col-sm-10">
                        <ng-select addTag="true"
                                   multiple="true"
                                   placeholder="Sisesta ametinimetus"
                                   hideSelected="true"
                                   addTagText="Lisa ametinimetus"
                                   formControlName="jobTitles"
                                   bindLabel="text"
                                   [items]="jobTitleAdapter.options$ | async"
                                   [typeahead]="jobTitleAdapter.querySource"
                                   [class.is-invalid]="hasErrors(form.get('jobTitles'))"></ng-select>
                        <ng-container *ngIf="hasErrors(form.get('jobTitles'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('jobTitles').errors?.required">
                                Palun sisesta ametinimetus.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">Telefon</label>
                    <div class="col-sm-10">
                        <input [tabindex]="4" type="text" class="form-control"
                               [class.is-invalid]="hasErrors(form.get('phone'))"
                               id="telefonUus" placeholder="Telefon" formControlName="phone">
                        <ng-container *ngIf="hasErrors(form.get('phone'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('phone').errors?.required">
                                Palun sisesta telefon.
                            </small>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!!currentPermissions['user.access.company-role-assign-basis'] && basis?.controls.length > 0">
        <div class="modal-header form-group justify-content-start align-items-center pl-0">
            <h4 class="modal-title">Õiguste lisamise alused:
            </h4>
            <div class="col-sm-8">
                <i class="font-icon-question"
                   [tooltip]="basisOfAssignDescription"
                   containerClass="tooltip-basis"
                ></i>
            </div>
        </div>
        <div class="form-group row mt-2 pr-3 pl-3" formArrayName="basisOfAssigns"
             *ngFor="let item of basis.controls; let index = index">
            <label class="col-sm-2 form-control-label">{{item?.value?.name}}</label>
            <div class="col-sm-10" [formGroupName]="index">
                <input
                    class="form-control"
                    formControlName="basisOfAssign"
                    name="basisOfAssign"
                    (change)="updateRoleDto(item?.value)"
                    [class.is-invalid]="hasInputErrors(item)"
                />
                <ng-container *ngIf="hasInputErrors(item)">
                    <small class="invalid-feedback d-block m-0"
                           *ngIf="hasInputErrors(item) && item?.get('basisOfAssign').errors?.required">
                        Palun sisesta õiguse andmise alus.
                    </small>
                    <small class="invalid-feedback d-block m-0"
                           *ngIf="hasInputErrors(item) && item?.get('basisOfAssign').errors?.pattern">
                        Õiguse andmise alus ei ole õigel kujul. (https://jira.rkas.ee/browse/SD-XXXXX)
                    </small>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="formErrors['basis_of_assign_required']">
        <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
            <span aria-hidden="true">&times;</span>
        </button>
        Puudub õigus lisada kasutajale valitud rolli.
    </div>
</form>
