<section class="box-typical">
    <header class="box-typical-header box-typical-header-border">
        <div class="tbl-row">
            <div class="tbl-cell tbl-cell-title">
                <h3 *ngIf="isNew">Esindusõiguse lisamine</h3>
                <h3 *ngIf="!isNew">Esindusõiguse muutmine</h3>
            </div>
        </div>
    </header>
    <div class="box-typical-body box-typical-padding">
        <app-user-company [userCompany]="userCompany" (save)="formSaved($event)"></app-user-company>
    </div>
    <div class="box-typical-footer">
        <div class="tbl">
            <div class="tbl-row">
                <div class="tbl-cell tbl-cell-action text-right">
                    <button (click)="cancel()" class="btn btn-inline btn-secondary">Katkesta</button>
                    <button class="btn btn-inline btn-primary" (click)="btnSaveClicked($event)">Salvesta</button>
                </div>
            </div>
        </div>
    </div>
</section>
