import {Injectable, OnInit} from "@angular/core";

@Injectable()
export abstract class FilterListComponent implements OnInit {

    protected _filters: any;

    protected abstract getFilterKeys(): { [key: string]: string };

    public ngOnInit() {
        this.getFilters();
    }

    protected getStorage(): Storage {
        return sessionStorage;
    }

    protected getFilters() {
        this._filters = {};

        for (let key of Object.keys(this.getFilterKeys())) {
            let storageKey: string = this.getFilterKeys()[key];
            if (this.getStorage().getItem(storageKey)) {
                this._filters[key] = JSON.parse(this.getStorage().getItem(storageKey));
            }
        }
    }

    protected setFilter(key: string, value?: any) {
        if (this.getFilterKeys()[key]) {
            if (value || value === 0) {
                this._filters[key] = value;
                this.getStorage().setItem(this.getFilterKeys()[key], JSON.stringify(value));
            } else {
                delete (this._filters[key]);
                this.getStorage().removeItem(this.getFilterKeys()[key]);
            }
        }
    }
}
