<header class="section-header">
    <div class="tbl">
        <div class="tbl-row">
            <div class="tbl-cell">
                <ol class="breadcrumb breadcrumb-simple">
                    <li><a [routerLink]="['../..']">Rollid</a></li>
                    <li class="active">Rolli lisamine</li>
                </ol>
            </div>
        </div>
    </div>
</header>
<section class="box-typical">
    <header class="box-typical-header box-typical-header-border">
        <div class="tbl-row">
            <div class="tbl-cell tbl-cell-title">
                <h3>Rolli lisamine</h3>
            </div>
        </div>
    </header>
    <div class="box-typical-body box-typical-padding">
        <role-form [role]="role" *ngIf="role"></role-form>
    </div>
    <div class="box-typical-footer">
        <div class="tbl">
            <div class="tbl-row">
                <div class="tbl-cell tbl-cell-action text-right">
                    <a [routerLink]="['..']" class="btn btn-inline btn-secondary">Katkesta</a>
                    <button class="btn btn-inline btn-primary swal-btn-info" (click)="btnSaveClicked($event)">Salvesta</button>
                </div>
            </div>
        </div>
    </div>
</section>
