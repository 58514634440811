import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';

import {ActivatedRoute, Router} from "@angular/router";
import {FormComponent} from "../form/form.component";
import {ManageService} from "../manage/manage.service";
import {Subject} from "rxjs";
import {CompanyDto} from "../../core/resource-dto/company";
import {takeUntil} from "rxjs/operators";

@Component({
    templateUrl: './edit.component.html',
    providers: [ ManageService ],
    styleUrls: [
        '../../../assets/styles/pages/editor.less',
        '../../../assets/styles/pages/project.less'
    ]
})
export class CompanyEditComponent implements OnDestroy {

    private ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(FormComponent)
    formComponent:FormComponent;

    public company$ = this.manageService.company$;
    public btnUndeleteEnabled: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private manageService: ManageService
    ) {
        this.route.params.pipe(takeUntil(this.ngDestroy)).subscribe(params => {
            this.manageService.loadCompany(true, params['roleId'])
        });
        this.manageService.company$.pipe(takeUntil(this.ngDestroy)).subscribe((company: CompanyDto.Company) => {
            this.btnUndeleteEnabled = company.deleted && true;
        });
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    btnSaveClicked(e:any) {
        this.formComponent.btnSaveClicked(e);
    }

    btnSaveAndUndelete(e:any) {
        this.formComponent.btnSaveClicked(e, true);
    }
}
