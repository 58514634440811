<header class="section-header">
    <div class="tbl">
        <div class="tbl-row">
            <div class="tbl-cell">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active">Ettevõtted</li>
                </ol>
            </div>
        </div>
    </div>
</header>

<section class="box-typical toolbar">
    <header class="box-typical-header box-typical-header-border">
        <div class="tbl-row">
            <div class="tbl-cell tbl-cell-title">
                <h3>Ettevõtted</h3>
            </div>
            <div class="tbl-cell tbl-cell-action-bordered" *ngxPermissionsOnly="'company.manage'">
                <button type="button" class="action-btn no-wrap" [routerLink]="['add']">
                    <i class="font-icon font-icon-plus"></i>&nbsp;Lisa&nbsp;ettevõte
                </button>
            </div>
        </div>
    </header>

    <div id="toolbarCompany">
        <div class="row collapse show" [formGroup]="filterFormGroup" *ngxPermissionsOnly="'company.list.manage'">
            <div class="col">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="deletedCompanies" formControlName="includeDeletedCompanies">
                    <label class="form-check-label" for="deletedCompanies">Kustutatud ettevõtted</label>
                </div>
            </div>
        </div>
    </div>

    <div class="box-typical-body">
        <table class="table table-striped"
               data-filter-control="false"
               data-show-toggle="false"
               data-show-export="false"
               data-detail-view="false"
               data-show-pagination-switch="false"
               data-minimum-count-columns="2"
               data-pagination="true"
               data-id-field="id"
               data-show-footer="false"
               data-search="true"
               data-show-refresh="true"
               data-show-columns="true"
               bs-table-control [bs-table-options]="this.bsTableOptions" #bsTableElement
        >
            <thead>
                <tr>
                    <th data-field="name" data-sortable="true" data-filter-control="input">Nimi</th>
                    <th data-field="regNo" data-sortable="true" data-filter-control="input">Registri number</th>
                    <th data-field="vatRegNo" data-sortable="true" data-filter-control="input">KMKR number</th>
                    <th data-field="address" data-sortable="true" data-filter-control="input">Tänav</th>
                    <th data-field="cityName" data-sortable="true" data-filter-control="input">Linn</th>
                    <th data-field="stateName" data-sortable="true" data-filter-control="input">Maakond</th>
                    <th data-field="countryName" data-sortable="true" data-filter-control="input">Riik</th>
                    <th data-field="zip" data-sortable="true" data-filter-control="input">Postiindeks</th>
                    <th data-field="phone" data-sortable="true" data-filter-control="input">Telefon</th>
                    <th data-field="email" data-sortable="true" data-filter-control="input">E-mail</th>
                    <th data-field="partnerCode" data-sortable="true" data-filter-control="input">Tehingupartneri kood</th>
                    <th data-field="sapCode" data-sortable="true" data-filter-control="input">SAP kood</th>
                    <th data-field="governanceAreaType" data-sortable="true" data-filter-control="input">Valitsemisala</th>
                    <th data-field="readingForwardEmail" data-sortable="true" data-filter-control="input">Näitude edastamise email</th>
                </tr>
            </thead>
            <tbody></tbody>
        </table>
    </div>
</section>
