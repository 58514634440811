import { Injectable } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { ConfigService } from 'app/config/config.service';

import { AuthGuardedResource } from './auth-guarded.resource';

import { CountryDto } from '../resource-dto/country';
import {ResourceHandler} from "@ngx-resource/core/src/ResourceHandler";
import {IResourceMethod, ResourceAction, ResourceParams} from "@ngx-resource/core";

@Injectable()
@ResourceParams({
    pathPrefix: '/countries',
    withCredentials: true
})
export class CountryRes extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl(''));
    }

    @ResourceAction({
        expectJsonArray: false
    })
    query: IResourceMethod<CountryDto.QueryInput, CountryDto.QueryOutput>;

    @ResourceAction({
        path: '/{!countryId}'
    })
    get: IResourceMethod<{countryId: any}, CountryDto.Country>;
}
