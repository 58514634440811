import { Injectable } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { ConfigService } from 'app/config/config.service';

import { AuthGuardedResource } from './auth-guarded.resource';

import {UserDto} from '../resource-dto/user';
import {ResourceHandler} from "@ngx-resource/core/src/ResourceHandler";
import {IResourceMethod, IResourceMethodStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";

@Injectable()
@ResourceParams({
    pathPrefix: '/substitutions',
    withCredentials: true
})
export class SubstitutionRes extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl(''));
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        expectJsonArray: false,
    })
    querySubstitutions: IResourceMethodStrict<{userId: number}, UserDto.SubstitutionQueryInput, any, UserDto.SubstitutionQueryOutput>;

    @ResourceAction({
        method: ResourceRequestMethod.Post
    })
    saveSubstitution: IResourceMethodStrict<UserDto.SubstitutionInput, {companyId: number}, {}, UserDto.Substitution>;

    @ResourceAction({
        path: '/{!substitutionId}',
        method: ResourceRequestMethod.Put
    })
    updateSubstitution: IResourceMethodStrict<UserDto.SubstitutionInput, {companyId: number}, {substitutionId: number}, UserDto.Substitution>;

    @ResourceAction({
        path: '/{!substitutionId}',
        method: ResourceRequestMethod.Delete
    })
    deleteSubstitution: IResourceMethodStrict<{substitutionId: number}, {companyId: number}, any, void>;

}
