import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CompanyListComponent } from './list/list.component';
import { CompanyAddComponent } from './add/add.component';

import { NgxPermissionsGuard } from 'ngx-permissions';
import { GDPRGuard } from '../user-registry/gdpr/gdpr.guard';
import {CompanyEditComponent} from "./edit/edit.component";
import {ViewComponent} from "./view/view.component";
import {RepresentationRightComponent} from "../representation-right/representation-right.component";

const routes: Routes = [
    {
        path: 'companies',
        children: [
            { path: '', component: CompanyListComponent },
            { path: 'add', component: CompanyAddComponent },
            {
                path: 'edit',
                pathMatch: 'full',
                redirectTo: '/companies'
            },
            {
                path: 'edit/:roleId',
                component: CompanyEditComponent
            },
            {   path: 'view/:companyId',
                component: ViewComponent
            },
            {   path: 'representation-right/add/:companyId',
                component: RepresentationRightComponent
            },
            {   path: 'representation-right/edit/:companyId/:userId',
                component: RepresentationRightComponent
            },
        ],
        canActivate: [ NgxPermissionsGuard, GDPRGuard ],
        data: {
            permissions: {
                only: [
                    'company.manage',
                    'representation-right.access.all-rights',
                    'representation-right.access.company-rights'
                ]
            }
        }
    }
];

@NgModule({
    imports:    [ RouterModule.forChild(routes) ],
    exports:    [ RouterModule ]
})
export class CompanyRoutingModule { }
