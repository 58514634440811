import {AfterViewInit, Component, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {AuthService} from "@app/core/services/auth.service";
import {CompanyDto} from "@app/core/resource-dto/company";
import {CompanyRes} from "@app/core/resource/company.resource";
import {ManageService} from "../manage/manage.service";
import {CountryAdapter} from "@app/core/services/country-adapter";
import {ClassifierService} from "@app/core/services/classifier.service";
import {ClassifierDto} from "@app/core/resource-dto/classifier";
import {ClassifierLabelPipe} from "@app/shared/pipes/classifier-label.pipe";
import {CountryDto} from "@app/core/resource-dto/country";
import {PublicDataRes} from "@app/core/resource/public-data.resource";
import {PublicDataDto} from "@app/core/resource-dto/public-data";
import {PermissionsService} from "@app/shared/services/permissions.service";
import {Observable, Subject} from "rxjs";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {UserAdapter} from "@app/user-registry/form/user-adapter";

@Component({
    selector: 'app-company-form',
    templateUrl: './form.component.html',
    providers: [ CountryAdapter, UserAdapter ],
    styleUrls: [
        '../../../assets/styles/pages/editor.less',
        '../../../assets/styles/pages/project.less'
    ]
})
export class FormComponent implements AfterViewInit, OnDestroy {

    @Input()
    public company: CompanyDto.Company = null;

    private ngDestroy: Subject<void> = new Subject<void>();

    public showFormErrors: boolean = false;
    protected isSaveInProgress: boolean = false;

    public invoiceChannelTypes: any[];
    public governanceAreaTypes: any[];
    public companyTypes: any[];
    public searchVisible = false;
    public newCompany = false;

    public formErrors: any = {};

    public permissions$: Observable<any>;
    public currentPermissions: any = {};

    public formFieldErrors = {
        zip: <any>null,
        country: <any>null,
        regNo: <any>null,
        name: <any>null,
        email: <any>null,
        phone: <any>null,
        invoiceChannelType: <any>null,
        governanceAreaType: <any>null,
        invoiceEmail: <any>null,
        companyType: <any>null,
        readingForwardEmail: <any>null,
        stateName: <any>null,
        cityName: <any>null,
        address: <any>null,
        partnerCode: <any>null,
        vatRegNo: <any>null,
        sapCode: <any>null,
    };

    public validationMessages = {
        'country': {
            'required': 'Palun vali riik.',
        },
        'regNo': {
            'required': 'Palun sisesta registri number.',
            'maxlength': 'Sisend peab koosnema 8-st numbrist',
        },
        'name': {
            'required': 'Palun sisesta nimi.',
        },
        'email': {
            'required': 'Palun sisesta e-posti aadress.',
            'email': 'Palun sisesta korrektne e-posti aadress.'
        },
        'invoiceEmail': {
            'email': 'Palun sisesta korrektne e-posti aadress.'
        },
        'phone': {
            'required': 'Palun sisesta telefon.',
        },
        'vatRegNo': {
            'required': 'Palun sisesta KMKR number.',
        },
        'invoiceChannelType': {
            'required': 'Palun vali arve saatmise kanal.',
        },
        'governanceAreaType': {
            'required': 'Palun vali valitsemisala.',
        },
        'companyType': {
            'required': 'Palun vali tüüp.',
        },
        'readingForwardEmail': {
            'required': 'Palun sisesta näidusisestamise e-posti aadress.',
            'email': 'Palun sisesta korrektne e-posti aadress.'
        }
    };

    formGroup: FormGroup;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        public countryAdapter: CountryAdapter,
        public userAdapter: UserAdapter,
        private authService: AuthService,
        private companyRes: CompanyRes,
        private manageService: ManageService,
        private classifierService: ClassifierService,
        private classifierLabelPipe: ClassifierLabelPipe,
        private publicDataRes: PublicDataRes,
        private permissionsService: PermissionsService,
    ) {
        this.permissions$ = this.permissionsService.permissions$;
        this.permissions$.pipe(distinctUntilChanged(), takeUntil(this.ngDestroy)).subscribe((permissions: any) => {
            this.currentPermissions = permissions;
        });

        userAdapter.loadLargeClientManagers();
        countryAdapter.loadCountries();
        this.formGroup = this.formBuilder.group({
            'name': ['', [Validators.required]],
            'regNo': ['', [Validators.required, Validators.maxLength]],
            'vatRegNo': ['', []],
            'invoiceChannelType': ['', []],
            'phone': ['', [Validators.required]],
            'email': ['', [Validators.required]],
            'invoiceEmail': ['', []],
            'partnerCode': ['', []],
            'sapCode' : ['', []],
            'country': [null, [Validators.required]],
            'stateName': ['', []],
            'cityName': ['', []],
            'address': ['', []],
            'zip': ['', []],
            'governanceAreaType': ['', []],
            'companyType': [null, [Validators.required]],
            'readingForwardEmail': ['', []],
            'largeClientManager': [null, []]
        });
    }

    ngAfterViewInit() {
      this.classifierService.classifierSource$.pipe(takeUntil(this.ngDestroy)).subscribe(_ => {
        const invoiceChannelTypeClassifier: ClassifierDto.Classifier = this.classifierService.getClassifierByCode('INVOICE_CHANNEL_TYPE');
        this.invoiceChannelTypes = invoiceChannelTypeClassifier.values.map(item => {
          return {
            id: item.code,
            text: item.name
          }
        });

        const governanceAreaTypeClassifier: ClassifierDto.Classifier = this.classifierService.getClassifierByCode('GOVERNANCE_AREA_TYPE');
        this.governanceAreaTypes = governanceAreaTypeClassifier.values.map(item => {
          return {
            id: item.code,
            text: item.name
          }
        });

        const companyTypeClassifier: ClassifierDto.Classifier = this.classifierService.getClassifierByCode('COMPANY_TYPE');
        this.companyTypes = companyTypeClassifier.values.map(item => {
          return {
            id: item.code,
            text: item.name
          }
        });
      });

      if (!this.company.id) this.newCompany = true;

      if (!this.newCompany && !this.currentPermissions['company-reg-no.manage']) {
          this.formGroup.controls['regNo'].disable();
      } else {
          this.formGroup.controls['regNo'].enable();
      }

      this.patchForm();
      this.updateRegNoDisplay();
      this.formGroup.valueChanges.subscribe((formModel:any) => this.onFormValueChanged(formModel));
    }

    isInvalid(field: string) {
        const control = this.formGroup.get(field);
        return control.invalid && (this.showFormErrors);
    }

    updateRegNoDisplay() {
        if (!this.formGroup) return;
        const form = this.formGroup;

        if (!form.get('country').value || !form.get('companyType').value) {
            return;
        }

        if ((form.get('country').value.text === 'Eesti' || form.get('country').value.text === 'Eesti Vabariik') && form.get('companyType').value === 'BUSINESS') {
            this.searchVisible = true;
            form.get('regNo').setValidators([Validators.required, Validators.maxLength(8)]);
            form.get('regNo').setValue(form.get('regNo').value, {emitEvent: false});
            this.validationMessages.regNo.maxlength = 'Sisend peab koosnema 8-st numbrist';
        } else if ((form.get('country').value.text === 'Eesti' || form.get('country').value.text === 'Eesti Vabariik') && form.get('companyType').value === 'PRIVATE') {
            this.searchVisible = false;
            form.get('regNo').setValidators([Validators.required, Validators.maxLength(11)]);
            form.get('regNo').setValue(form.get('regNo').value, {emitEvent: false});
            this.validationMessages.regNo.maxlength = 'Sisend peab koosnema 11-st numbrist';
        } else {
            form.get('regNo').setValidators([ Validators.required, Validators.maxLength(20) ]);
            form.get('regNo').setValue(form.get('regNo').value, {emitEvent: false});
            this.searchVisible = false;
            this.validationMessages.regNo.maxlength = 'Sisend peab koosnema 20-st numbrist';
        }

        form.updateValueAndValidity({emitEvent: false});
    }

    onFormValueChanged(_?: any) {
        if (!this.formGroup) return;
        const form = this.formGroup;

        this.updateRegNoDisplay();


        for (const field in this.formFieldErrors) {
            this.formFieldErrors[field] = null;
            const control = form.get(field);

            if (control && (control.dirty || this.showFormErrors) && !control.valid) {

                const messages = this.validationMessages[field];

                this.formFieldErrors[field] = control.errors;

                for (const key in control.errors) {
                    this.formFieldErrors[field][key] = messages[key];
                }
            }
        }

        this.formErrors = {};
        if(this.formGroup.valid) {
            this.showFormErrors = false;
        } else {
            this.formErrors['invalid'] = true;
        }
    }

    getPublicCompanies() {
        if (!this.formGroup) return;
        const form = this.formGroup;

        if (!form.get('country').value || !form.get('regNo').value) return;

        this.countryAdapter.getCountryByName(form.get('country').value.text).then((country:CountryDto.Country) => {

            const uniqueCode = country.code + form.get('regNo').value;
            this.publicDataRes.getCompany({uniqueCode: uniqueCode}).then((company: PublicDataDto.Company) => {
                this.company.name = company.name;
                this.company.partnerCode = company.partnerCode;
                this.company.sapCode = company.sapCode;
                this.company.vatRegNo = company.vatRegNo;
                this.company.address = {
                    stateName: company.stateName,
                    cityName: company.cityName,
                    address1: company.address1,
                    zip: company.zip,
                    countryId: null,
                    countryName: null
                };

                this.formGroup.patchValue({
                    'name': this.company.name,
                    'vatRegNo': this.company.vatRegNo,
                    'partnerCode': this.company.partnerCode,
                    'sapCode': this.company.sapCode,
                    'stateName': this.company.address ? this.company.address.stateName : '',
                    'cityName': this.company.address ? this.company.address.cityName : '',
                    'address': this.company.address ? this.company.address.address1 : '',
                    'zip': this.company.address ? this.company.address.zip : '',
                });

                form.updateValueAndValidity({emitEvent: false});
            });
        });
    }

    patchForm() {
      if (!this.company) return;

      if(this.company.address) this.countryAdapter.querySource.next(this.company.address.countryName)
      let governanceAreaType = null;
      if(this.company.governanceAreaType) {
        const classifier = this.classifierService.getClassifierValueByCode('GOVERNANCE_AREA_TYPE', this.company.governanceAreaType);
        if(classifier) governanceAreaType = {id: classifier.code, text: classifier.name};
      }

      this.formGroup.patchValue({
          'name': this.company.name,
          'regNo': this.company.regNo,
          'vatRegNo': this.company.vatRegNo,
          'phone': this.company.phone,
          'email': this.company.email,
          'invoiceEmail': this.company.invoiceEmail,
          'partnerCode': this.company.partnerCode,
          'sapCode': this.company.sapCode,
          'country': this.company.address && this.company.address.countryId ? {id: this.company.address.countryId, text: this.company.address.countryName} : null,
          'stateName': this.company.address ? this.company.address.stateName : '',
          'cityName': this.company.address ? this.company.address.cityName : '',
          'address': this.company.address ? this.company.address.address1 : '',
          'zip': this.company.address ? this.company.address.zip : '',
          'invoiceChannelType': this.company.invoiceChannelType ? this.company.invoiceChannelType : null,
          'governanceAreaType': governanceAreaType,
          'companyType': this.company.companyType ? this.company.companyType : null,
          'readingForwardEmail': this.company.readingForwardEmail,
          'largeClientManager': this.company?.largeClientManagerUser ? {id: this.company.largeClientManagerUser.id, text: this.company.largeClientManagerUser.name}: null,
      }, {emitEvent: false});
    }

    prepareInputDtoForSubmit():CompanyDto.CompanyInput {
        const formModel = this.formGroup.value;

        let dto:CompanyDto.CompanyInput = new CompanyDto.CompanyInput();

        dto.name = formModel.name;
        dto.regNo = formModel.regNo;
        dto.vatRegNo = formModel.vatRegNo;
        dto.email = formModel.email;
        dto.invoiceEmail = formModel.invoiceEmail;
        dto.phone = formModel.phone;
        dto.partnerCode = formModel.partnerCode;
        dto.sapCode = formModel.sapCode;
        dto.address = {
            countryId: formModel.country ? formModel.country.id : null,
            stateName: formModel.stateName,
            cityName: formModel.cityName,
            address1: formModel.address,
            zip: formModel.zip
        };
        dto.governanceAreaType = formModel.governanceAreaType ? formModel.governanceAreaType.id : null;
        dto.invoiceChannelType = formModel.invoiceChannelType ? formModel.invoiceChannelType : null;
        dto.companyType = formModel.companyType ? formModel.companyType : null;
        dto.readingForwardEmail = formModel.readingForwardEmail;
        dto.largeClientManagerUserId = formModel.largeClientManager ? formModel.largeClientManager.id : null;

        return dto;
    }

    setAsTouched(group: FormGroup | FormArray) {
        group.markAsTouched({onlySelf: true});
        group.markAsDirty({onlySelf: true});
        for (let i in group.controls) {
            if (group.controls[i] instanceof FormControl) {
                group.controls[i].markAsTouched({onlySelf: true});
                group.controls[i].markAsDirty({onlySelf: true});
            } else {
                this.setAsTouched(group.controls[i]);
            }
        }
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    btnDismissErrorsClicked() {
        this.showFormErrors = false;
    }

    btnSaveClicked(e: any, undelete: boolean = false) {
        if(this.isSaveInProgress) return;

        this.setAsTouched(this.formGroup);

        this.formGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

        if(this.formGroup.valid) {
            this.isSaveInProgress = true;

            let dto:CompanyDto.CompanyInput = this.prepareInputDtoForSubmit();
            dto.id = this.company.id;

            if(dto.id) {
                this.manageService.updateCompany(dto, undelete).then(
                    (company: CompanyDto.Company) => {
                        this.company = company;
                        this.router.navigate(['..'], {relativeTo: this.route});
                    },
                    (error: any) => {
                        this.isSaveInProgress = false;
                        if(error.status == 409 && error.body && error.body.errorCode == 'company_already_exists') {
                            this.formErrors['company_already_exists'] = true;
                        }
                    }
                );
            } else {
                this.manageService.saveCompany(dto).then(
                    (company: CompanyDto.Company) => {
                        this.manageService.setCompany(company);
                        this.router.navigate(['..'], {relativeTo: this.route});
                    },
                    (error: any) => {
                        this.isSaveInProgress = false;
                        if(error.status == 409 && error.body && error.body.errorCode == 'company_already_exists') {
                            this.formErrors['company_already_exists'] = true;
                        }
                    }
                );
            }
        } else {
            this.showFormErrors = true;
            window.scrollTo(0, 0);
        }
    }
}
