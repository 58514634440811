import {Injectable, OnDestroy} from "@angular/core";
import {combineLatest, ReplaySubject, Subject} from "rxjs";
import {debounceTime, startWith, takeUntil} from "rxjs/operators";

@Injectable()
export abstract class QueryAdapter implements OnDestroy {

  protected readonly ngDestroy = new Subject<void>();

  protected map: Map<number, any>;
  protected index: any;

  protected readonly dataSource = new ReplaySubject<Map<number, any>>(1);
  protected readonly optionsSource = new ReplaySubject<any[]>(1);
  protected readonly optionsFilteredSource = new ReplaySubject<any[]>(1);

  public optionsFiltered$ = this.optionsFilteredSource.asObservable();
  public readonly options$ = this.optionsSource.asObservable();
  public readonly querySource = new Subject<string>();

  protected constructor() {
    combineLatest([
      this.querySource.pipe(
        startWith(''),
        debounceTime(200)
      ),
      this.dataSource
    ]).pipe(
      takeUntil(this.ngDestroy),
    ).subscribe(([term, source]) => {
      if(!!this.map) this.query(term);
    });
  }

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public query(term:string): Array<any> {
    if(!this.index) return [];

    let results: Array<any> = [];
    let lunarResults:any = this.index.search(term, {expand: true, bool: 'AND'});

    lunarResults.forEach((result:any) => {
      let item:any = null;
      if(this.map) item = this.map.get(+result.ref);
      if(item) {
        results.push({id: item.id, text: item.name});
      }
    });
    this.optionsFilteredSource.next(results);

    return results;
  }

  public updateSource() {
    this.dataSource.next(this.map);

    let options:any[] = [];
    this.map.forEach((company: any) => {options.push({id: company.id, text: company.name})});
    this.optionsSource.next(options);
  }

  protected clear() {
    let results: Array<any> = [];
    this.map.forEach((title: any) => {
      results.push({id: title.id, text: title.name});
    });
    this.optionsSource.next(results);

    return results;
  }

  protected search(term: string) {
    const results: Array<any> = [];
    const lunrResults:any = this.index.search(term, {expand: true, bool: 'AND'});

    lunrResults.forEach((result: any) => {
      let role:any = null;
      if(this.map) role = this.map.get(+result.ref);
      if(role) {
        results.push({id: role.id, text: role.name});
      }
    });

    this.optionsSource.next(results);

    return results;
  }
}
