import {Component, ViewChild, ElementRef, OnDestroy, AfterViewInit} from '@angular/core';
import { DatePipe } from "@angular/common";
import {ActivatedRoute, Router} from '@angular/router';

import { BsTableControl } from '../../shared/directives/bs-table-control.directive';
import {AuthService} from "../../core/services/auth.service";
import {UserLoginDto} from "../../core/resource-dto/user-login";
import {FormBuilder, FormGroup} from "@angular/forms";
import {PermissionsService} from "../../shared/services/permissions.service";
import {RoleDto} from "../../core/resource-dto/role";
import {RoleRes} from "../../core/resource/role.resource";
import {ClassifierLabelPipe} from "../../shared/pipes/classifier-label.pipe";
import {combineLatest, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import swal from "sweetalert";

declare let jQuery:any;

@Component({
    templateUrl: './list.component.html',
    providers: [ DatePipe ]
})
export class RoleListComponent implements AfterViewInit, OnDestroy {
    private ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(BsTableControl)
    bsTableControl:BsTableControl;

    @ViewChild('bsTableElement')
    bsTableElement:ElementRef;

    public permissions$:Observable<any>;
    public currentPermissions:any = {};

    filterFormGroup:FormGroup;

    public roles:RoleDto.Role[] = null;

    public bsTableOptions:any = {
        toolbar: '#toolbarRole',
        iconsPrefix: 'font-icon',
        icons: {
            paginationSwitchDown:'font-icon-arrow-square-down',
            paginationSwitchUp: 'font-icon-arrow-square-down up',
            refresh: 'font-icon-refresh',
            toggle: 'font-icon-list-square',
            columns: 'font-icon-list-rotate',
            export: 'font-icon-download',
            detailOpen: 'font-icon-plus',
            detailClose: 'font-icon-minus-1'
        },
        paginationPreText: '<i class="font-icon font-icon-arrow-left"></i>',
        paginationNextText: '<i class="font-icon font-icon-arrow-right"></i>',
        pagination: true,
        pageList: [10, 25, 50, 100, 'Kõik'],
        formatAllRows: function () {
            return 'Kõik';
        },
        showColumns: true,
        columns: [
            {},
            {},
            {
                formatter: (value: any, row: any, rowIndex: any) => {
                    return this.classifierLabelPipe.transform(row.roleType, 'ROLE_TYPE');
                },
            },
            {
                sortable: false,
                width: '152px',
                formatter: (value: any, row: any, rowIndex:any) => {
                    return '<button class="btn btn-primary btn-sm edit-link" data-id="'+row.id+'"><span class="glyphicon glyphicon-pencil"></span></button>&nbsp;<button class="btn btn-primary swal-btn-cancel btn-sm delete-link" data-id="'+row.id+'"><span class="glyphicon glyphicon-trash"></span></em></button>';
                },
            }
        ],
        data: []
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private roleRes: RoleRes,
        private authService: AuthService,
        private permissionsService: PermissionsService,
        private classifierLabelPipe: ClassifierLabelPipe,
        private formBuilder: FormBuilder
    ) {
        this.filterFormGroup = this.formBuilder.group({
            'isDeleted': false,
        });

        combineLatest([
            this.authService.userCompany$.pipe(distinctUntilChanged()),
            this.filterFormGroup.valueChanges.pipe(distinctUntilChanged())
        ]).pipe(takeUntil(this.ngDestroy)).subscribe(([userCompany, filterFormModel]: [UserLoginDto.UserCompany, any]) => {
            this.loadRoleList(filterFormModel);
        });

        this.permissions$ = this.permissionsService.permissions$;

        this.permissions$.pipe(distinctUntilChanged()).subscribe((permissions:any) => {
            this.currentPermissions = permissions;
        });

        this.filterFormGroup.get('isDeleted').setValue(false);
    }

    ngAfterViewInit() {
        let $table = jQuery(this.bsTableElement.nativeElement);

        if ($table.length) {
            $table.on('click', '.edit-link', (e:any) => {
                e.preventDefault();

                let id: any = jQuery(e.currentTarget).data('id');
                if (id) {
                    this.router.navigate(['edit', id], { relativeTo: this.route });
                }

                return false;
            });

            $table.on('click', '.delete-link', (e:any) => {
                e.preventDefault();

                let id:any = jQuery(e.currentTarget).data('id');

                if (id) {
                    let role: RoleDto.Role = this.roles.find((c: RoleDto.Role) => c.id == id);

                    if (role) {
                        swal(
                            {
                                title: "Kas oled kindel?",
                                text: 'Kas oled kindel, et soovid rolli "' + role.name + '" kustutada?',
                                type: "info",
                                showCancelButton: true,
                                cancelButtonText: "Tühista",
                                cancelButtonClass: "btn-default",
                                confirmButtonText: "Jah",
                                confirmButtonClass: "btn-primary",
                                closeOnConfirm: false,
                            },
                            (isConfirm:any) => {
                                if (isConfirm) {
                                    this.roleRes.delete({roleId: role.id}).then(() => {
                                        this.loadRoleList(this.filterFormGroup.getRawValue());
                                        swal({
                                            title: "Roll kustutatud!",
                                            type: "success",
                                            timer: 2000,
                                            showConfirmButton: false
                                        });
                                    });
                                }
                            }
                        );
                    }
                }

                return false;
            });
        }
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    loadRoleList(filterFormModel: any) {
        let query: RoleDto.QueryInput = new RoleDto.QueryInput;
        query.companyId = this.authService.getCurrentUserCompany().id;
        query.isDeleted = filterFormModel.isDeleted;

        this.roleRes.query(query).then(
            (queryOutput: RoleDto.QueryOutput) => {
                this.roles = queryOutput.roles;

                let rows:Array<Object> = this.roles.map(dto => {
                    return {
                        id: dto.id,
                        name: dto.name,
                        code: dto.code,
                        roleType: dto.roleType
                    };
                });

                this.bsTableControl.load(rows);
            }
        );
    }
}
