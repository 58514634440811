<header class="section-header">
    <div class="tbl">
        <div class="tbl-row">
            <div class="tbl-cell">
                <ol class="breadcrumb breadcrumb-simple" *ngIf="user">
                    <li><a [routerLink]="['/list']">Kasutajad</a></li>
                    <li *ngIf="!substitution?.id">
                        Kasutaja  <a [routerLink]="['/user', user.id]">"{{user?.forename}} {{user?.surname}}"</a> asenduse lisamine
                    </li>
                    <li *ngIf="substitution?.id">
                        Kasutaja <a [routerLink]="['/user', user.id]">"{{user?.forename}} {{user?.surname}}"</a> asenduse muutmine
                    </li>
                </ol>
            </div>
        </div>
    </div>
</header>

<section class="box-typical">
    <header class="box-typical-header box-typical-header-border">
        <div class="tbl-row">
            <div class="tbl-cell tbl-cell-title">
                <h3>Asenduse lisamine</h3>
            </div>
        </div>
    </header>
    <div class="box-typical-body box-typical-padding">

        <form [formGroup]="formGroup">
            <div *ngIf="showFormErrors && (formErrors['not_user'] || formErrors['invalid_interval'] || formErrors['invalid_project_manager_substitution'])"
                 class="alert alert-danger"
                 role="alert" >
                <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div *ngIf="formErrors['not_user']">
                    Üks valitud kasutajatest peab olema kasutajate nimekirjast valitud kasutaja (detailvaate kasutaja)
                </div>
                <div *ngIf="formErrors['invalid_interval']">
                    Perioodi algus peab olema varasem kui lõpp
                </div>
                <div *ngIf="formErrors['invalid_project_manager_substitution']">
                    {{formErrors['invalid_project_manager_substitution']}}
                </div>
            </div>

            <div class="box-typical-body" style="padding: 10px">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group row" style="padding-bottom: 16px;" [class.has-danger]="showFormErrors && formFieldErrors?.substitute">
                            <label class="col-sm-2 form-control-label">Kasutaja, kes asendab</label>
                            <div class="col-sm-10">
                                <ng-select id="substitute" formControlName="substitute" bindLabel="text"
                                  [tabIndex]="1"
                                  [items]="userAdapter.optionsFiltered$ | async"
                                  [typeahead]="userAdapter.querySource"
                                  [class.form-control-danger]="formGroup?.controls['substitute'].touched && formFieldErrors.substitute"
                                ></ng-select>
                                <div *ngIf="showFormErrors && formFieldErrors?.substitute?.required" class="form-control-feedback">{{formFieldErrors?.substitute?.required}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12" >
                        <div class="form-group row">
                            <label class="col-sm-2 form-control-label">Periood</label>
                            <div class="col-sm-10">
                                <div class='input-group date'>
                                    <input daterangepicker type="text" class="form-control" [disabled]="false" [startDate]="_validFrom" [endDate]="_validTo" (valueChange)="dateValueChange($event)">

                                    <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="font-icon font-icon-calend"></i>
                                                    </span>
                                    </div>
                                    <div *ngIf="showFormErrors && formFieldErrors?.validFrom?.required" class="form-control-feedback">{{formFieldErrors?.validFrom?.required}}</div>
                                    <div *ngIf="showFormErrors && formFieldErrors?.validTo?.required" class="form-control-feedback">{{formFieldErrors?.validTo?.required}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group row" style="padding-bottom: 16px;">
                            <label class="col-sm-2 form-control-label">Kasutaja, keda asendatakse</label>
                            <div class="col-sm-10">
                                <ng-select id="actingAs" formControlName="actingAs" bindLabel="text"
                                  [tabIndex]="1"
                                  [items]="userAdapter.optionsFiltered$ | async"
                                  [typeahead]="userAdapter.querySource"
                                  [class.form-control-danger]="formGroup?.controls['actingAs'].touched && formFieldErrors.actingAs"
                                ></ng-select>
                                <div *ngIf="showFormErrors && formFieldErrors?.actingAs?.required" class="form-control-feedback">{{formFieldErrors?.actingAs?.required}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="box-typical-footer">
        <div class="tbl">
            <div class="tbl-row">
                <div class="tbl-cell tbl-cell-action text-right" *ngIf="user">
                    <a [routerLink]="['/user', user.id]" class="btn btn-inline btn-secondary">Katkesta</a>
                    <button type="button" class="btn btn-inline btn-primary" (click)="btnSaveClicked()">Salvesta</button>
                </div>
            </div>
        </div>
    </div>
</section>
