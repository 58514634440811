import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserListComponent } from './list/list.component';
import { UserAddComponent } from './add/add.component';
import { UserDetailComponent } from './detail/detail.component';
import { CompanyAddComponent } from './detail/company-add.component';
import { CompanyEditComponent } from './detail/company-edit.component';
import { UserEditComponent } from './edit/edit.component';
import { GDPRComponent } from './gdpr/gdpr.component';
import { ManageComponent } from './manage/manage.component';

import { NgxPermissionsGuard } from 'ngx-permissions';
import { GDPRGuard } from './gdpr/gdpr.guard';
import {SubstitutionManageComponent} from "./detail/substitution/substitution-manage.component";
import {RepresentationRightComponent} from "../representation-right/representation-right.component";

const routes: Routes = [
    { path: '', canActivate: [ GDPRGuard ], children: [
        { path: '', redirectTo: '/list', pathMatch: 'full' },
        { path: 'list', pathMatch: 'full', component: UserListComponent, canActivate: [ NgxPermissionsGuard ] },
        { path: 'user/add', component: UserAddComponent, canActivate: [ NgxPermissionsGuard ] },
        { path: 'user/representation-right/add/:userId', component: RepresentationRightComponent},
        { path: 'user/representation-right/edit/:userId/:companyId', component: RepresentationRightComponent},
        {
            path: 'user/:userId',
            component: ManageComponent,
            canActivate: [ NgxPermissionsGuard ],
            children: [
                { path: '', component: UserDetailComponent, canActivate: [ NgxPermissionsGuard ] },
                { path: 'edit', component: UserEditComponent, canActivate: [ NgxPermissionsGuard ], data: { permissions: {} }},
                { path: 'add-company', component: CompanyAddComponent, canActivate: [ NgxPermissionsGuard ], data: { permissions: {} } },
                { path: 'edit-company/:companyId', component: CompanyEditComponent, canActivate: [ NgxPermissionsGuard ], data: { permissions: {} } },
                { path: 'manage-substitution', component: SubstitutionManageComponent, canActivate: [ NgxPermissionsGuard ], data: { permissions: {} } },
                { path: 'manage-substitution/:substitutionId', component: SubstitutionManageComponent, canActivate: [ NgxPermissionsGuard ], data: { permissions: {} } },
            ]
        },
    ], data: { permissions: {} }
    },
    { path: 'gdpr', component: GDPRComponent },
];

@NgModule({
    imports:    [ RouterModule.forChild(routes) ],
    exports:    [ RouterModule ]
})
export class UserRegistryRoutingModule { }
