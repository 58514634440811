import {Injectable} from "@angular/core";
import {IResourceMethod, ResourceAction, ResourceHandler, ResourceParams} from "@ngx-resource/core";
import {AuthGuardedResource} from "@app/core/resource/auth-guarded.resource";
import {AuthService} from "@app/core/services/auth.service";
import {ConfigService} from "@app/config/config.service";
import {JobTitleDto} from "@app/core/resource-dto/job-title";

@Injectable()
@ResourceParams({
    pathPrefix: '/job-titles',
    withCredentials: true
})
export class JobTitleRes extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);
        this.$setUrl(config.getBackendUrl(''));
    }

    @ResourceAction({
      expectJsonArray: true
    })
    query: IResourceMethod<{}, JobTitleDto.QueryOutput>;
}
