<ng-template #representativeSelectModal>
  <div class="modal-header">
    <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
      <i class="font-icon-close-2"></i>
    </button>
    <h4 class="modal-title" id="myModalLabel5">Esindusõiguse valimine</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group row">
        <label class="col-sm-11 offset-sm-1 form-control-label"><b>Siin saad valida, mis ettevõtte nimel soovid siseneda. Seda valikut saad pärast sisse logimist ka muuta.</b></label>
      </div>
      <div class="form-group row" style="margin:0 -30px;">
        <div class="col-sm-12">
          <div class="btn-group-vertical" aria-label="Basic example" style="padding:0px; width:100%;">
            <a class="btn btn-default-outline" *ngFor="let userCompany of currentUser?.companies; let i = index" (click)="btnCompanyClicked(i)">
              <div class="row">
                <div class="col-sm-3">
                  <i class="fa fa-user-circle-o" style="font-size:20px;" aria-hidden="true"></i>
                </div>
                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-sm-12 text-left"><b>{{userCompany?.name}}</b></div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
