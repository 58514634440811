import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {UserDto} from '@app/core/resource-dto/user';

import {CountryDto} from '@app/core/resource-dto/country';
import {AuthService} from '@app/core/services/auth.service';
import {ManageService} from '../manage/manage.service';
import {CountryAdapter} from '@app/core/services/country-adapter';
import {combineLatest, Subject} from 'rxjs';
import {PermissionsService} from '@app/shared/services/permissions.service';
import {startWith, takeUntil} from 'rxjs/operators';
import moment from 'moment';
import {LargeClientManagerAdapter} from '@app/user-registry/form/large-client-manager-adapter';
import {HttpStatusCode} from '@angular/common/http';
import {ErrorCode} from '@app/user-registry/error-code';

function validatePersonalCode(g: FormGroup): any {
    let c: AbstractControl = g.controls['personalCode'];

    let invalid: any = {
        valid: 'Vigane isikukood.'
    };

    if (!c) return null;
    let personalCode: string = c.value;

    if (!personalCode) return null;

    if (personalCode.length != 11) {
        c.setErrors(invalid);
        return null;
    } else {
        let letters: string[] = personalCode.split('');
        let digits: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (let i: number = 0; i < 11; i++) {
            let digit: number = Number(letters[i]);
            if (isNaN(digit) || digit < 0 || digit > 9) {
                c.setErrors(invalid);
                return null;
            }
            digits[i] = digit;
        }
        let weights0: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
        let weights1: number[] = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];
        let checksum0: number = 0;
        let checksum1: number = 0;
        for (let i: number = 0; i < 10; i++) {
            checksum0 += digits[i] * weights0[i];
            checksum1 += digits[i] * weights1[i];
        }
        let checksum: number = checksum0 % 11;
        if (checksum == 10) {
            checksum = checksum1 % 11;
            if (checksum == 10) checksum = 0;
        }
        if (checksum != digits[10]) {
            c.setErrors(invalid);
            return null;
        }

        c.setErrors(null);
        return null;
    }
}

@Component({
    selector: 'app-user-form',
    templateUrl: './form.component.html',
    providers: [CountryAdapter, LargeClientManagerAdapter],
    styleUrls: [
        '../../../assets/styles/pages/editor.less',
        '../../../assets/styles/pages/project.less'
    ]
})
export class UserFormComponent implements OnInit, OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();

    @Input()
    public user: UserDto.User = null;

    @Output()
    protected email: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    protected phone: EventEmitter<string> = new EventEmitter<string>();

    protected countryEe: CountryDto.Country;
    protected isSaveInProgress: boolean = false;

    public fieldSapCostCenterEnabled = false;
    public addUserWithoutPersonalCode: boolean = false;
    public isPersonalCodeFilled: boolean = false;
    public showFormErrors: boolean = false;
    public formErrors: any = {};
    public form: FormGroup;
    public isExternalUser = false;

    public constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        public countryAdapter: CountryAdapter,
        private authService: AuthService,
        private manageService: ManageService,
        private permissionsService: PermissionsService,
        public largeClientManagerAdapter: LargeClientManagerAdapter,
    ) {
        countryAdapter.loadCountries();
        countryAdapter.getCountryByCode('EE').then((country: CountryDto.Country) => {
            this.countryEe = country;
        });

        this.largeClientManagerAdapter.getLargeClientManagerUsers();

        combineLatest([
            this.authService.user$,
            this.permissionsService.permissions$
        ]).pipe(takeUntil(this.ngDestroy)).subscribe(([_, permissions]) => {
            this.fieldSapCostCenterEnabled = permissions['sap-code.manage'];
        });
    }

    public ngOnInit() {
        if (!this.user) return;

        if (this.user.country) this.countryAdapter.querySource.next(this.user.country.name)

        const birthdayDate = moment(this.user.birthday, 'DD.MM.YYYY').format('DD.MM.YYYY');

        this.form = new FormGroup({
            country: new FormControl(this.user.country ? {
                id: this.user.country.id,
                text: this.user.country.name
            } : '', [Validators.required]),
            forename: new FormControl(!!this.user.forename ? this.user.forename : '', [Validators.required]),
            personalCode: new FormControl(!!this.user?.personalCode ? this.user.personalCode : null, [Validators.required]),
            surname: new FormControl(!!this.user.surname ? this.user.surname : '', [Validators.required]),
            email: new FormControl(!!this.user.email ? this.user.email : '', [Validators.required, Validators.email]),
            gender: new FormControl(!!this.user.gender ? this.user.gender : '', [Validators.required]),
            birthday: new FormControl(!!this.user?.birthday ? birthdayDate : '', [Validators.required]),
            info: new FormControl(!!this.user.info ? this.user.info : ''),
            sapCostCenter: new FormControl(this.user.sapCostCenter ? this.user.sapCostCenter : '', Validators.maxLength(10)),
            withoutPersonalCode: new FormControl(this.addUserWithoutPersonalCode),
            largeClientManagerUser: new FormControl(this.user?.largeClientManagerUser),
        });

        this.isExternalUser = this.user.isExternal || Object.keys(this.user).length === 0;

        this.permissionsService.hasPermission(['user.manage.is-external']).then((hasPermission) => {
            if (hasPermission) {
                this.form.addControl('isExternal', new FormControl());
                this.form.patchValue({
                    'isExternal': this.isExternalUser ? 'Väline' : 'Sisene'
                });
            }
        })

        this.form.addControl('phoneNumbers', this.formBuilder.array([]));

        if (!!(this.user && this.user.phoneNumbers && this.user.phoneNumbers.length > 0)) {
            this.form.setControl('phoneNumbers', new FormArray(
                this.user.phoneNumbers.map((value) => {
                    return new FormGroup({
                        phoneNumber: new FormControl(value, Validators.required)
                    })
                })
            ));
        } else {
            this.phoneNumbers.push(new FormGroup({
                phoneNumber: new FormControl('', Validators.required)
            }));
        }

        combineLatest([
            this.form.get('country').valueChanges.pipe(startWith(this.form.get('country').value)),
            this.form.get('personalCode').valueChanges.pipe(startWith(this.form.get('personalCode').value))
        ]).pipe(takeUntil(this.ngDestroy)).subscribe(([country, personalCode]) => {
            if (country != null && personalCode != null && country.id === 1 && personalCode.length === 11) {
                this.getBirthOfDateFromPersonalCode(personalCode);
            }
        });
        this.form.get('email').valueChanges.subscribe((email: string) => this.email.emit(email));
        this.form.get('phoneNumbers').valueChanges.subscribe(value => {
            this.phone.emit(value[0].phoneNumber);
        })
        this.conditionalBirthdayValidation();

        if (this.user.id &&
            this.user.personalCode == undefined &&
            this.permissionsService.hasPermission((['user.add.without-personal-code']))
        ) {
            this.addUserWithoutPersonalCode = true;
        }
        this.userHasPersonalCode();
    }

    userHasPersonalCode() {
        this.permissionsService.hasPermission(['user.add.without-personal-code']).then((hasPermission: boolean) => {
            if (hasPermission && this.form.get('personalCode').value === null) {
                this.isPersonalCodeFilled = true;
            }
        })
    }

    public getBirthOfDateFromPersonalCode(personalCode: string) {
        const year = personalCode.substring(1, 3);
        const month = personalCode.substring(3, 5);
        const day = personalCode.substring(5, 7);
        const cut = (new Date()).getFullYear() - 2000;
        const birthday = day + '.' + month + '.' + (parseInt(year) > cut ? '19' : '20') + year;

        this.form.get('birthday').patchValue(moment(birthday, 'DD.MM.YYYY').format('DD.MM.YYYY'));
    }

    public ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public btnDismissErrorsClicked() {
        for (const field in this.formErrors) {
            this.formErrors[field] = null;
        }

        this.showFormErrors = false;
    }

    public checkInputForCustomValidation() {
        const values = this.form.getRawValue();

        Object.keys(values).forEach(value => {
            if (value === 'forename' || value === 'surname') {
                let val: string;
                if (this.form.get(value).value.includes('-')) {
                    val = this.form.get(value).value.replaceAll(' ', '');
                } else {
                    val = this.form.get(value).value.replace(/\s+-?\s*/, ' ');
                }
                this.form.get(value).patchValue(val.trim());
                const convertTextToUppercase = this.form.get(value).value.replace(/(^\w)|([-\s]\w)/g, match => match.toUpperCase());
                this.form.get(value).patchValue(convertTextToUppercase);
            }
            if (value === 'phone' || value === 'email') {
                this.form.get(value).patchValue(this.form.get(value).value.trim());
            }
        })
    }

    public conditionalBirthdayValidation() {
        this.form.get('withoutPersonalCode').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('birthday').setValidators(null);
                this.form.get('birthday').setErrors(null);
                this.form.get('personalCode').setErrors(null);
            } else {
                this.form.get('birthday').setValidators(Validators.required);
            }
        })
    }

    public btnSaveClicked(userCompanies: UserDto.CompanyData[]) {
        if (this.isSaveInProgress) return;

        this.checkInputForCustomValidation();

        if (this.addUserWithoutPersonalCode) {
            this.form.controls['personalCode'].setErrors(null);
            this.form.controls['birthday'].setErrors(null);
        }

        this.form.updateValueAndValidity();
        this.form.markAllAsTouched();
        this.form.setValidators([(c: FormGroup) => {
            return this.validatePersonalCode(c)
        }]);

        if (this.form.valid) {
            this.isSaveInProgress = true;

            let user: UserDto.UserInput = this.prepareUserInputDtoForSubmit(userCompanies);
            user.id = this.user.id;

            if (user.id) {
                this.manageService.updateUser(user).then(
                    (user: UserDto.User) => {
                        this.manageService.setUser(user);
                        this.router.navigate(['/user', user.id]);
                    },
                    (error: any) => {
                        this.isSaveInProgress = false;
                        this.showErrorBody(error);
                    }
                );
            } else {
                this.manageService.saveUser(user).then(
                    (user: UserDto.User) => {
                        this.manageService.setUser(user);
                        this.router.navigate(['/user', user.id]);
                    },
                    (error: any) => {
                        this.isSaveInProgress = false;
                        this.showErrorBody(error);
                    }
                );
            }
        } else {
            this.showFormErrors = true;
            window.scrollTo(0, 0);
        }
    }

    public showErrorBody(error: any) {
        if (error.status == HttpStatusCode.PreconditionFailed && error.body) {
            switch (error.body.errorCode) {
                case ErrorCode.PERSONAL_CODE_EXISTS:
                    this.formErrors[ErrorCode.PERSONAL_CODE_EXISTS] = true;
                    break;
                case ErrorCode.ADDING_LARGE_CLIENT_MANAGER_USER_NOT_ALLOWED:
                    this.formErrors[ErrorCode.ADDING_LARGE_CLIENT_MANAGER_USER_NOT_ALLOWED] = true;
                    break;
                case ErrorCode.INVALID_LARGE_CLIENT_MANAGER_USER_ID:
                    this.formErrors[ErrorCode.INVALID_LARGE_CLIENT_MANAGER_USER_ID] = true;
                    break;
                case ErrorCode.MANAGER_USER_NOT_ALLOWED:
                    this.formErrors[ErrorCode.MANAGER_USER_NOT_ALLOWED] = true;
                    break;
                default:
                    console.error(error.body);
            }
        }
    }

    private validatePersonalCode(g: FormGroup): any {
        if (
            this.form &&
            this.countryEe &&
            this.form.controls['country'].value &&
            this.form.controls['country'].value.id == this.countryEe.id
        ) {
            return validatePersonalCode(g);
        }
        return null;
    }

    public hasErrors(field) {
        return field.invalid && (field.dirty || field.touched);
    }

    private prepareUserInputDtoForSubmit(userCompanies: UserDto.CompanyInput[]): UserDto.UserInput {
        const formModel = this.form.value;
        let user: UserDto.UserInput = new UserDto.UserInput();

        if (!this.addUserWithoutPersonalCode) {
            user.personalCode = formModel.personalCode;

            user.birthday = formModel.birthday;
        } else {
            if (formModel.personalCode === '') {
                user.personalCode = undefined;
            }
            if (formModel.birthday === '' || formModel.birthday === null) {
                user.birthday = undefined;
            } else {
                user.birthday = formModel.birthday;
            }
        }

        user.phoneNumbers = formModel.phoneNumbers.map(phone => phone.phoneNumber.trim());
        this.permissionsService.hasPermission(['user.manage.is-external']).then((hasPermission) => {
            if (hasPermission) {
                if (formModel.isExternal === 'Sisene') {
                    user.isExternal = false;
                } else if (formModel.isExternal === 'Väline') {
                    user.isExternal = true;
                } else {
                    user.isExternal = formModel.isExternal;
                }
            }
        })

        user.forename = formModel.forename;
        user.surname = formModel.surname;
        user.email = formModel.email.trim();
        user.info = formModel.info;
        user.countryId = null;
        user.gender = formModel.gender
        if (userCompanies) {
            user.roles = userCompanies.map(userCompany => {
                return {
                    companyId: userCompany.companyId,
                    email: userCompany.email.trim(),
                    phone: userCompany.phone.trim(),
                    roles: userCompany.roles.map(role => {
                        return {
                            id: role.id,
                            basisOfAssign: role.basisOfAssign
                        };
                    }),
                    jobTitles: userCompany.jobTitles
                }
            });
        }
        if (formModel.country) {
            user.countryId = formModel.country.id;
        }
        user.sapCostCenter = formModel.sapCostCenter;
        if (formModel.largeClientManagerUser !== null) {
            user.largeClientManagerUserId = formModel.largeClientManagerUser.id;
        } else {
            user.largeClientManagerUserId = null;
        }

        user.birthday = ![null, undefined].includes(user.birthday) ?
            moment(user.birthday, 'DD.MM.YYYY').format('DD.MM.YYYY') :
            user.birthday;

        return user;
    }

    public setPermissionToAddWithoutPersonalCode() {
        if (this.form.value.withoutPersonalCode) {
            this.permissionsService.hasPermission((['user.add.without-personal-code']))
                .then((hasPermission: boolean) => {
                    if (hasPermission) {
                        this.addUserWithoutPersonalCode = true;
                    }
                });
        } else {
            this.addUserWithoutPersonalCode = false;
        }
    }

    get phoneNumbers(): FormArray {
        return this.form.controls['phoneNumbers'] as FormArray;
    }

    public addPhoneNumber() {
        this.phoneNumbers.push(new FormGroup({
            phoneNumber: new FormControl('', Validators.required)
        }));
    }

    public removePhoneNumber(index: number) {
        this.phoneNumbers.removeAt(index);
    }
}
