import {
    Directive, OnInit, OnDestroy, ElementRef, Input, Output, EventEmitter,
    OnChanges
} from '@angular/core';

declare let jQuery:any;

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[daterangepicker]'
})
export class DaterangepickerDirective implements OnInit, OnChanges, OnDestroy {
    private _$element: any;

    @Input()
    private options:any = {};

    @Input('startDate')
    private _startDate:any = null;

    @Input('endDate')
    private _endDate:any = null;

    @Output('startDateChange')
    private _startDateChange:EventEmitter<any> = new EventEmitter();

    @Output('endDateChange')
    private _endDateChange:EventEmitter<any> = new EventEmitter();

    @Output('valueChange')
    private _valueChange:EventEmitter<any> = new EventEmitter();

    constructor(private _element: ElementRef) {}

    ngOnInit() {
        this._$element = jQuery(this._element.nativeElement);

        let options:any = Object.assign(
            {
                locale: {
                    format: 'DD.MM.YYYY'
                }
            },
            this.options);

        this._$element.daterangepicker(options);

        this._$element.data('daterangepicker').setStartDate(this._startDate);
        this._$element.data('daterangepicker').setEndDate(this._endDate);
        if(this._startDate && this._endDate) {
            this._$element.val(this._startDate.format(options.locale.format) + ' - ' + this._endDate.format(options.locale.format));
        }

        this._$element.on('apply.daterangepicker', (e:any, picker:any) => {
            this._startDateChange.emit(picker.startDate);
            this._endDateChange.emit(picker.endDate);
            this._valueChange.emit({startDate: picker.startDate, endDate: picker.endDate});

            this._$element.val(picker.startDate.format(options.locale.format) + ' - ' + picker.endDate.format(options.locale.format));
        });
    }

    ngOnChanges(changes:any) {
        if(!this._$element) return;
        if (changes['_startDate']) {
            this._$element.data('daterangepicker').setStartDate(changes['_startDate'].currentValue);
        }
        if (changes['_endDate']) {
            this._$element.data('daterangepicker').setEndDate(changes['_endDate'].currentValue);
        }
    }

    ngOnDestroy() {
        if(this._$element) this._$element.data('daterangepicker').remove();
    }
}
