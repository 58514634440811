import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ManageService} from "./manage.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    templateUrl: './manage.component.html',
    providers: [ ManageService ]
})
export class ManageComponent implements OnDestroy {
    private ngDestroy: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private manageService:ManageService,
    ) {
        this.route.params.pipe(takeUntil(this.ngDestroy)).subscribe(params => {
            this.manageService.loadUser(params['userId'])
        });
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }
}
