import {Component, OnInit} from '@angular/core';
import {ConfigService} from "app/config/config.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
    public version:string;
    public name = this.config.get('name');
    public address = this.config.get('address');
    public helpdesk = this.config.get('helpdesk');

    constructor(
        protected config: ConfigService
    ) {

    }

    public ngOnInit() {
        this.version = this.config.getVersion();
    }
}
