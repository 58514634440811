import {Component, OnInit} from '@angular/core';
import {AuthService} from "./core/services/auth.service";
import {UserLoginDto} from "./core/resource-dto/user-login";
import {ConfigService} from "app/config/config.service";
import {Observable} from "rxjs";

declare let $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
    public logoPath = this.config.get('logo');
    public name = this.config.get('name');
    public isUserLoggedIn:boolean = false;
    public currentUser:UserLoginDto.User = null;
    public user$:Observable<UserLoginDto.User>;
    public userCompany$:Observable<UserLoginDto.UserCompany>;

    constructor(
        protected config: ConfigService,
        private _authService:AuthService
    ) {
        this.user$ = this._authService.user$;
        this.userCompany$ = this._authService.userCompany$;
    }

    public ngOnInit() {
        this._authService.user$.subscribe((user:UserLoginDto.User) => {
            this.currentUser = user;
            if (this.currentUser) {
                this.isUserLoggedIn = true;
            } else {
                this.isUserLoggedIn = false;
            }
        });
    }

    public getLogo() {
        if (!this.logoPath) {
            return null;
        }
        return '../assets/img/' + this.logoPath;
    }

    public btnLogoutClicked($event:any) {
        this._authService.redirectToCasLogout();
    }

    public toggleSidebar(): void {
        $('body').toggleClass('sidebar-hidden').trigger('sidebar.change');
    }
}
