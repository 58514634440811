import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserDto} from '../../core/resource-dto/user';
import {RoleAdapter} from '../form/role-adapter';
import {CompanyAdapter} from '../form/company-adapter';
import {ManageService} from '../manage/manage.service';
import {UserCompanyFormComponent} from '../form/user-company-form.component';
import {CountryAdapter} from '../../core/services/country-adapter';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: './company-add.component.html',
    providers: [CountryAdapter, RoleAdapter, CompanyAdapter],
    styleUrls: [
        '../../../assets/styles/pages/editor.less',
        '../../../assets/styles/pages/project.less'
    ],
})
export class CompanyAddComponent implements OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(UserCompanyFormComponent, {static: true})
    formComponent: UserCompanyFormComponent;

    public readonly user$: Observable<UserDto.User> = this.manageService.user$;

    public country: number = null;
    public user: UserDto.User = null;
    public userCompany: UserDto.Company = new UserDto.Company();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private companyAdapter: CompanyAdapter,
        private roleAdapter: RoleAdapter,
        private manageService: ManageService
    ) {
        this.companyAdapter.loadCompanies();
        this.roleAdapter.loadRoles();

        this.user$.pipe(takeUntil(this.ngDestroy)).subscribe((user: UserDto.User) => {
            this.user = user;
        });
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    btnSaveClicked(e: any) {
        this.formComponent.save();
    }

    formSaved(e: any) {
        this.router.navigate(['..'], {relativeTo: this.route});
    }
}
