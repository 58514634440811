import { Injectable } from '@angular/core';

import { ClassifierDto } from "../resource-dto/classifier";
import { ClassifierRes } from "../resource/classifier.resource";

import {ReplaySubject} from "rxjs";

@Injectable()
export class ClassifierService {

    private classifierMap:Map<string, any>;
    private classifierSource = new ReplaySubject<Map<string, ClassifierDto.Classifier>>(1);

    classifierSource$ = this.classifierSource.asObservable();

    constructor(private _classifierRes:ClassifierRes) { }

    public init() {
        this.loadClassifiers();
    }

    loadClassifiers() {
        this._classifierRes.query({}).then((classifiersQueryOutput: ClassifierDto.QueryOutput) => {
            this.updateClassifierMap(classifiersQueryOutput.classifiers);
            this.updateClassifierSoruce();
        });
    }

    updateClassifierMap(classifiers:ClassifierDto.Classifier[]) {
        this.classifierMap = new Map<string, ClassifierDto.Classifier>(classifiers.map((classifier:ClassifierDto.Classifier) => [classifier.code, classifier] as [string, ClassifierDto.Classifier]));
    }

    updateClassifierSoruce() {
        this.classifierSource.next(this.classifierMap);
    }

    public getClassifierByCode(code:string):ClassifierDto.Classifier {
        let classifier:ClassifierDto.Classifier = null;
        if(this.classifierMap) classifier = this.classifierMap.get(code);
        return classifier;
    }

    public getClassifierValueByCode(classifierCode:string, valueCode:string):ClassifierDto.ClassifierValue {
        let classifier:ClassifierDto.Classifier = this.getClassifierByCode(classifierCode);
        if(!classifier) return null;
        return classifier.values.find(c => c.code == valueCode);
    }
}
