import { Injectable } from '@angular/core';

import { ConfigService } from 'app/config/config.service';
import {UserLoginDto} from "../resource-dto/user-login";
import {ResourceHandler} from "@ngx-resource/core/src/ResourceHandler";
import {
    IResourceMethodStrict,
    Resource,
    ResourceAction,
    ResourceParams,
    ResourceRequestMethod
} from "@ngx-resource/core";

@Injectable()
@ResourceParams({
    pathPrefix: '/users/login',
    withCredentials: true
})
export class UserLoginRes extends Resource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected config: ConfigService
    ) {
        super(requestHandler);

        this.$setUrl(config.getBackendUrl(''));
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post
    })
    login: IResourceMethodStrict<{ companyId: number, userCompanyId: number }, {}, void, UserLoginDto.User>;
}
