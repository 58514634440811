import { Injectable } from '@angular/core';
import { NgxRolesService} from "ngx-permissions";
import { PermissionsService } from '@app/shared/services/permissions.service'

import { ConfigService } from '@app/config/config.service';
import { UserLoginDto } from "../resource-dto/user-login";
import { UserLoginRes } from "../resource/user-login.resource";

import {ReplaySubject} from "rxjs";
import {Representative} from "../model/representative.model";
import {HttpClient} from "@angular/common/http";
import {take} from "rxjs/operators";
import {getParameterByName} from "@app/core/util";
import {HttpStatusCode} from "@app/shared/constants";

@Injectable({providedIn: 'root'})
export class AuthService {

    private userSource = new ReplaySubject<UserLoginDto.User>(1);
    private userCompanySource = new ReplaySubject<UserLoginDto.UserCompany>(1);

    private representativeSource = new ReplaySubject<Representative>(1);

    user$ = this.userSource.asObservable();
    userCompany$ = this.userCompanySource.asObservable();

    public user: UserLoginDto.User = null;
    public userCompany: UserLoginDto.UserCompany = null;

    private invalidCompany: boolean;

    constructor(
        private config: ConfigService,
        private httpClient: HttpClient,
        private userLoginRes: UserLoginRes,
        private rolesService: NgxRolesService,
        private permissionsService: PermissionsService
    ) { }

    public navigateToLogin() {
        this.redirectToCasLogin();
    }

    /**
     * Login initialization.
     * Used by appInitializer to preload prepare user login permissions and permissions before app routing starts.
     *
     * @returns {Promise<any>}
     */
    public initLogin():Promise<any> {
        const companyId: any = getParameterByName('cid');
        let userCompanyId: any = getParameterByName('c');
        if (!userCompanyId) {
            userCompanyId = +sessionStorage.getItem('UserCompanySelection');
        }
        this.doApiLoginViaCas(companyId, userCompanyId);

        return this.user$.pipe(take(1)).toPromise();
    }

    public preparePermissions() {
        let permissions:string[] = [];

        if(this.userCompany) {
            permissions.push('user');
            this.userCompany.roles.forEach((role:UserLoginDto.Role) => {
                permissions.push('ROLE_' + role.code);
            });
            this.userCompany.permissions.forEach((permission:UserLoginDto.Permission) => {
                permissions.push(permission.code);
            });
        }

        return permissions;
    }

    public setCurrentUser(user: UserLoginDto.User): void {
        this.user = user;

        if(user) {
            this.userSource.next(user);
        } else {
            this.userSource.error(null);
        }
    }

    public getCurrentUser(): UserLoginDto.User {
        if(this.user) return this.user;

        return null;
    }

    public setCurrentCompanyId(companyId?:any) {
        if(!companyId) {
            companyId = +sessionStorage.getItem('UserCompanySelection');
        }

        this.validateAndUpdateUserCompany(companyId);
    }

    public validateAndUpdateUserCompany(companyId:number) {
        let userCompany:UserLoginDto.UserCompany = null;
        if(this.user && this.user.companies) {
            userCompany = this.user.companies.find((company: UserLoginDto.UserCompany) => company.id == companyId);
        }

        if(userCompany) {
            this.setCurrentUserCompany(userCompany);
        } else {
            sessionStorage.removeItem('UserCompanySelection');
        }
    }

    public setCurrentUserCompany(userCompany:UserLoginDto.UserCompany) {
        this.userCompany = userCompany;
        sessionStorage.setItem('UserCompanySelection', userCompany.id + '');

        this.userCompanySource.next(userCompany);

        let permissions:any = this.preparePermissions();

        this.permissionsService.setPermissions(permissions);

        this.representativeSource.next(new Representative(this.user, this.userCompany, permissions));

        // Log in after selecting user company
        this.doApiLoginViaCas(null, userCompany.id);
    }

    public getCurrentUserCompany():UserLoginDto.UserCompany {
        if(this.userCompany) return this.userCompany;

        if(sessionStorage.getItem('UserCompanySelection')) {
          this.validateAndUpdateUserCompany(Number(sessionStorage.getItem('UserCompanySelection')))
        }

        return null;
    }

    public doApiLoginViaCas(companyId?: number, userCompanyId?: number): void {
        const casLoginUrl = this.config.get('casLoginUrl');
        const url = casLoginUrl + '?service=' + encodeURIComponent(this.config.getBackendUrl('/login/cas')) + '&gateway=true';

        this.httpClient.get(url).subscribe({
            error: _ => console.error('Cas päringu viga!'),
            complete: () => {
                userCompanyId = userCompanyId || null;
                this.userLoginRes.login({companyId, userCompanyId}).then(this.setCurrentUser.bind(this), this.handleCasLoginError.bind(this));
            }
        });
    }

    public redirectToCasLogin() {
        let returnUrl = window.location.href;
        let apiLoginUrl = this.config.getBackendUrl('/login/cas?redirectTo=' + encodeURIComponent(returnUrl));
        let casLoginUrl = this.config.get('casLoginUrl');
        window.location.href = casLoginUrl + "?service=" + encodeURIComponent(apiLoginUrl) + "&locale=et";
    }

    public redirectToCasLogout() {
        let returnUrl = window.location.href;
        let apiLoginUrl = this.config.getBackendUrl('/login/cas?redirectTo=' + encodeURIComponent(returnUrl));
        let casLogoutUrl = this.config.get('casLogoutUrl');
        window.location.href = casLogoutUrl + "?service=" + encodeURIComponent(apiLoginUrl) + "&locale=et";
    }

    private handleCasLoginError(error: Response): void {
        if (error.status === HttpStatusCode.Unauthorized) {
            this.redirectToCasLogin();
        } else if (error.status === HttpStatusCode.NotFound) {
            this.userLoginRes.login().then(
                loginResult => {
                    this.invalidCompany = true;
                    this.setCurrentUser(loginResult);
                    localStorage.removeItem('UserCompanySelection');
                });
        }
    }
}
