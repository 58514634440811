<button class="dropdown-toggle" id="dd-user-menu" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <span class="d-none d-sm-none d-md-inline-block">{{currentUser?.forename}}&nbsp;{{currentUser?.surname}}&nbsp;-&nbsp;{{currentUserCompany?.name}}</span>
    <img src="../../../assets/img/avatar-2-64.png" alt="">
</button>
<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dd-user-menu">
    <a class="dropdown-item" *ngFor="let userCompany of currentUser?.companies; let i = index" (click)="btnCompanyClicked(i)">
        <span class="font-icon glyphicon glyphicon-user"></span>{{userCompany?.name}}
    </a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" (click)="btnLogoutClicked($event)"><span class="font-icon glyphicon glyphicon-log-out"></span>Logi välja</a>
</div>