import { Injectable } from '@angular/core';

import {ConfigService} from 'app/config/config.service';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class AdminService {

    constructor(
        private _http: HttpClient,
        private config: ConfigService
    ) {
    }

    public substituteSync() {
        this._http.post(this.config.getBackendUrl("/substitutions/sync"), null, {withCredentials: true}).subscribe();
    }

    public adImportSync() {
        this._http.post(this.config.getBackendUrl("/ad-import"), null, {withCredentials: true}).subscribe();
    }
}
