import { Injectable } from '@angular/core';

import { ReplaySubject } from "rxjs";

import { RoleRes } from "@app/core/resource/role.resource";
import { RoleDto } from "@app/core/resource-dto/role";
import {AuthService} from "@app/core/services/auth.service";
import * as lunar from 'elasticlunr';
import {QueryAdapter} from "@app/shared/QueryAdapter";

@Injectable()
export class RoleAdapter extends QueryAdapter {

    constructor(
        private _roleRes:RoleRes,
        private authService:AuthService
    ) { super() }

    loadRoles() {
        this._roleRes.query({companyId: this.authService.getCurrentUserCompany().id, isDeleted: false}).then((rolesQueryOutput: RoleDto.QueryOutput) => {
            let roles = rolesQueryOutput.roles.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
            this.updateMap(roles);
            this.updateSearchIndex();
            this.updateSource();
        });
    }

    updateMap(roles:RoleDto.Role[]) {
        this.map = new Map<number, any>(roles.map((role:any) => [role.id, role] as [number, any]));
    }

    updateSearchIndex() {
        this.index = new lunar.Index;

        this.index.pipeline.reset();
        this.index.pipeline.add(lunar.trimmer);

        this.index.addField('text');
        this.index.setRef('id');

        this.map.forEach((role: any) => {this.index.addDoc({id: role.id, text: role.name, basisOfAssignRequired: role.basisOfAssignRequired, basisOfAssign: role.basisOfAssign})});
    }

    updateSource() {
        this.dataSource.next(this.map);
    }

    query(term:string):Array<any> {
        if(!term || !term.length) {
            let results: Array<any> = [];

            this.map.forEach((role:any) => {
                results.push({id: role.id, text: role.name, basisOfAssignRequired: role.basisOfAssignRequired, basisOfAssign: role.basisOfAssign});
            });

            this.optionsSource.next(results);

            return results;
        }

        let results: Array<any> = [];

        let lunrResults:any = this.index.search(term, {expand: true, bool: 'AND'});

        lunrResults.forEach((result:any) => {
            let role:any = null;
            if(this.map) role = this.map.get(+result.ref);
            if(role) {
                results.push({id: role.id, text: role.name, basisOfAssignRequired: role.basisOfAssignRequired, basisOfAssign: role.basisOfAssign});
            }
        });

        this.optionsSource.next(results);

        return results;
    }
}
