<section class="tabs-section">
    <div class="tabs-section-nav tabs-section-nav-inline" >
        <ul class="nav" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" href="#sync" role="tab" data-toggle="tab">
                    Sünkimine
                </a>
            </li>
        </ul>
    </div><!--.tabs-section-nav-->

    <div class="tab-content" style="margin-bottom:-20px;">
        <div role="tabpanel" class="tab-pane active" id="sync">
            <form>
                <div class="box-typical-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group row" *ngIf="configService.enablePersonaSync()">
                                <div class="col-sm-6">
                                    <button class="btn btn-inline btn-primary ladda-button form-control" type="button" data-style="expand-left" (click)="substituteSync()">
                                        Asenduste uuendamine
                                    </button>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <button class="btn btn-inline btn-primary ladda-button form-control" type="button" data-style="expand-left" (click)="adImportSync()">
                                        AD import
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div><!--.tab-content-->
</section><!--.tabs-section-->
