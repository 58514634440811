import {CommonModule, DatePipe} from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPermissionsModule} from "ngx-permissions";

import { throwIfAlreadyLoaded } from '../core/module-import-guard';
import { SharedModule }    from '../shared/shared.module';

import { CompanyRoutingModule }   from './company-routing.module';

import { CompanyListComponent } from './list/list.component';
import {GDPRGuard} from "../user-registry/gdpr/gdpr.guard";
import {CompanyAddComponent} from "./add/add.component";
import {FormComponent} from "./form/form.component";
import {CompanyEditComponent} from "./edit/edit.component";
import {ViewComponent} from "./view/view.component";
import {ClassifierLabelPipe} from "../shared/pipes/classifier-label.pipe";
import {CoreModule} from "../core/core.module";
import {RepresentationRightComponent} from "../representation-right/representation-right.component";
import {ManageService} from "./manage/manage.service";
import {NgSelectModule} from "@ng-select/ng-select";
import {GovernanceAreaCompaniesComponent} from "@app/company/view/governance-area-companies.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CompanyRoutingModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild(),
    NgSelectModule,
  ],
    exports: [],
  declarations: [
    CompanyListComponent,
    CompanyAddComponent,
    CompanyEditComponent,
    FormComponent,
    ViewComponent,
    RepresentationRightComponent,
    GovernanceAreaCompaniesComponent
  ],
    providers: [
        ManageService,
        DatePipe,
        GDPRGuard,
        ClassifierLabelPipe
    ]
})
export class CompanyModule {
    constructor( @Optional() @SkipSelf() parentModule: CompanyModule) {
        throwIfAlreadyLoaded(parentModule, 'RoleModule');
    }
}
