import { Injectable } from '@angular/core';

import {ReplaySubject, Subject} from "rxjs";

import { CountryRes } from "@app/core/resource/country.resource";
import { CountryDto } from "@app/core/resource-dto/country";
import {debounceTime, take, takeUntil} from "rxjs/operators";

import * as lnr from 'elasticlunr';

@Injectable()
export class CountryAdapter {

  private readonly ngDestroy = new Subject<void>();
  public readonly countriesSource = new ReplaySubject<Map<number, any>>(1);
  private readonly countriesSource$ = this.countriesSource.asObservable();

  private countriesMap: Map<number, any>;
  private countriesMapByCode: Map<string, any>;
  private countriesMapByName:Map<string, any>;
  private countriesIndex: any;

  private optionsSource = new ReplaySubject<any[]>(1);
  options$ = this.optionsSource.asObservable();

  public readonly querySource = new Subject<string>();

  constructor(
    private _countryRes:CountryRes) {
    this.querySource.pipe(
      takeUntil(this.ngDestroy),
      debounceTime(200)
    ).subscribe((term: string) => {
      this.query(term);
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadCountries() {
      this._countryRes.query({}).then((countriesQueryOutput: CountryDto.QueryOutput) => {
          this.updateMap(countriesQueryOutput.countries);
          this.updateSearchIndex();
          this.updateSource();
      });
  }

  updateMap(countries:CountryDto.Country[]) {
      this.countriesMap = new Map<number, any>(countries.map((country:any) => [country.id, country] as [number, any]));
      this.countriesMapByCode = new Map<string, any>(countries.map((country:any) => [country.code, country] as [string, any]));
      this.countriesMapByName = new Map<string, any>(countries.map((country:any) => [country.name, country] as [string, any]));
  }

  updateSearchIndex() {
      this.countriesIndex = new lnr.Index;

      this.countriesIndex.pipeline.reset();
      this.countriesIndex.pipeline.add(lnr.trimmer);

      this.countriesIndex.addField('text');
      this.countriesIndex.setRef('id');

      this.countriesMap.forEach((country: any) => {this.countriesIndex.addDoc({id: country.id, text: country.name})});
  }

  updateSource() {
      this.countriesSource.next(this.countriesMap);
  }

  getCountryByCode(code:string):Promise<CountryDto.Country> {
      return new Promise<any>((resolve, reject) => {
          this.countriesSource$.pipe(take(1)).toPromise().then((countriesMap:Map<number, any>) => {
              let country:CountryDto.Country = this.countriesMapByCode.get(code);
              resolve(country);
          });
      })
  }

  getCountryByName(name:string):Promise<CountryDto.Country> {
      return new Promise<any>((resolve, reject) => {
          this.countriesSource$.pipe(take(1)).toPromise().then((countriesMap:Map<number, any>) => {
              let country:CountryDto.Country = this.countriesMapByName.get(name);
              resolve(country);
          });
      })
  }

  query(term:string):Array<any> {
      if(!this.countriesIndex) {
          return [];
      }

      let results: Array<any> = [];

      let lunrResults:any = this.countriesIndex.search(term, {expand: true, bool: 'AND'});

      lunrResults.forEach((result:any) => {
          let country:any = null;
          if(this.countriesMap) country = this.countriesMap.get(+result.ref);
          if(country) {
              results.push({id: country.id, text: country.name});
          }
      });

      this.optionsSource.next(results);

      return results;
  }
}
