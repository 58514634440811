import { Injectable } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { ConfigService } from 'app/config/config.service';

import { AuthGuardedResource } from './auth-guarded.resource';

import {ResourceHandler} from "@ngx-resource/core/src/ResourceHandler";
import {IResourceMethod, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {PublicDataDto} from "../resource-dto/public-data";

@Injectable()
@ResourceParams({
    pathPrefix: '/public-data',
    withCredentials: true
})
export class PublicDataRes extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl(''));
    }


    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/{!uniqueCode}'
    })
    getCompany: IResourceMethod<{uniqueCode: string}, PublicDataDto.Company>;
}
