import {Injectable} from '@angular/core';

import {AuthService} from '../services/auth.service';
import {ConfigService} from 'app/config/config.service';

import {AuthGuardedResource} from './auth-guarded.resource';

import {UserDto} from '../resource-dto/user';
import {ResourceHandler} from "@ngx-resource/core/src/ResourceHandler";
import {
    IResourceMethod,
    IResourceMethodStrict,
    ResourceAction,
    ResourceParams,
    ResourceRequestMethod
} from "@ngx-resource/core";

@Injectable()
@ResourceParams({
    pathPrefix: '/users',
    withCredentials: true
})
export class UserRes extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl(''));
    }


    @ResourceAction({
        expectJsonArray: false
    })
    query: IResourceMethod<UserDto.QueryInput, UserDto.QueryOutput>;

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/substitution-options'
    })
    querySubstitutionOptions: IResourceMethod<{companyId: number}, UserDto.QueryOutput>;

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/{!userId}'
    })
    get: IResourceMethod<{userId: number, companyId: number}, UserDto.User>;


    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/register'
    })
    register: IResourceMethod<UserDto.UserInput, UserDto.User>;

    @ResourceAction({
        method: ResourceRequestMethod.Post
    })
    save: IResourceMethodStrict<UserDto.UserInput, {companyId: number}, {}, UserDto.User>;

    @ResourceAction({
        path: '/{!userId}',
        method: ResourceRequestMethod.Delete
    })
    delete: IResourceMethod<{userId: number}, void>;

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/{!userId}'
    })
    update: IResourceMethodStrict<UserDto.UserInput, {companyId: number}, {userId: number}, UserDto.User>;


    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/{!userId}/gdpr-accept'
    })
    acceptGDPR: IResourceMethod<{userId: number}, void>;

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/{!userId}/companies',
        expectJsonArray: false,
    })
    queryCompany: IResourceMethodStrict<{userId: number}, UserDto.CompanyQueryInput, any, UserDto.CompanyQueryOutput>;

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/{!userId}/companies/{!companyId}',
        expectJsonArray: false,
    })
    getCompany: IResourceMethodStrict<{userId: number, companyId: number}, {companyId: number}, any, UserDto.Company>;


    @ResourceAction({
        path: '/{!userId}/companies',
        method: ResourceRequestMethod.Post
    })
    saveCompany: IResourceMethodStrict<UserDto.CompanyInput, {companyId: number}, {userId: number}, UserDto.Company>;


    @ResourceAction({
        path: '/{!userId}/companies/{!companyId}',
        method: ResourceRequestMethod.Put
    })
    updateCompany: IResourceMethodStrict<UserDto.CompanyInput, {companyId: number}, {userId: number, companyId: number}, UserDto.Company>;


    @ResourceAction({
        path: '/{!userId}/companies/{!companyId}',
        method: ResourceRequestMethod.Delete
    })
    deleteCompany: IResourceMethodStrict<{userId: number, companyId: number}, {companyId: number}, any, void>;

    @ResourceAction({
        path: '/{!userId}/role-assign-basis',
        method: ResourceRequestMethod.Get
    })
    roleAssignBasis: IResourceMethod<{userId: number}, UserDto.BasisOfAssign>
}
