import {Component, ViewChild, ElementRef, OnDestroy, AfterViewInit} from '@angular/core';

import {UserFormComponent} from "../form/form.component";
import {Router} from "@angular/router";
import {UserDto} from "@app/core/resource-dto/user";
import {ManageService} from "../manage/manage.service";
import {BsTableControl} from "@app/shared/directives/bs-table-control.directive";
import {PermissionsService} from "@app/shared/services/permissions.service";
import {AuthService} from "@app/core/services/auth.service";
import {UserLoginDto} from "@app/core/resource-dto/user-login";
import {Subject} from "rxjs";
import {RepresentationRightAddModalComponent} from "./representation-right-add-modal.component";
import {takeUntil} from "rxjs/operators";

@Component({
    templateUrl: './add.component.html',
    providers: [ ManageService ],
    styleUrls: [
        '../../../assets/styles/pages/editor.less',
        '../../../assets/styles/pages/project.less'
    ]
})
export class UserAddComponent implements AfterViewInit, OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(UserFormComponent)
    public formComponent: UserFormComponent;

    @ViewChild(BsTableControl)
    public bsTableControl: BsTableControl;

    @ViewChild('bsTableElement')
    public bsTableElement: ElementRef;

    @ViewChild(RepresentationRightAddModalComponent)
    representationRightAddModal:RepresentationRightAddModalComponent;

    public readonly user: UserDto.User = new UserDto.User();
    public userCompanies:UserDto.CompanyData[] = [];
    public currentUser:UserLoginDto.User = null;
    public modalButtonClicked = false;
    public showFormErrors = false;
    public email: string;
    public phone: string;

    public constructor(
        private router: Router,
        private permissionsService: PermissionsService,
        private _authService:AuthService

    ) {
        this._authService.user$.pipe(takeUntil(this.ngDestroy)).subscribe((user:UserLoginDto.User) => {
            this.currentUser = user;
        });
        this.permissionsService.hasPermission('representation-right.manage').then((hasPermission:boolean) => {
            if(hasPermission) {
                this.bsTableOptions.columns.push(
                    {
                        sortable: false,
                        width: '76px',
                        formatter: (value:any, row:any, rowIndex:any) => {
                            return '<button class="btn btn-primary swal-btn-cancel btn-sm delete-link" data-id="'+rowIndex+'">Kustuta</button>';
                        },
                    }
                );
            }
        });
    }

    public ngAfterViewInit() {
        let $table = jQuery(this.bsTableElement.nativeElement);

        if($table.length) {
            $table.on('click', '.delete-link', (e:any) => {
                e.preventDefault();

                let id:any = jQuery(e.target).data('id');
                this.userCompanies.splice(id, 1);
                this.bsTableControl.load(this.mapCompanyData());
                return false;
            });
        }

    }

  public btnSaveClicked(_:any) {
    if (this.userCompanies.length < 1) this.showFormErrors = true;
    this.formComponent.btnSaveClicked(this.userCompanies);
  }

  public btnDismissErrorsClicked() {
    this.showFormErrors = false;
  }

  public bsTableOptions:any = {
    toolbar: undefined,
    showFooter: false,
    pagination: false,
    columns: [
      {
        field: 'companyName',
        title: 'Ettevõte',
        sortable: true
      },
      {
        field: 'email',
        title: 'Email',
        sortable: true
      },
      {
        field: 'phone',
        title: 'Telefon',
        sortable: true
      },
      {
        field: 'roles',
        title: 'Rollid',
        sortable: true
      },
      {
        field: 'jobTitles',
        title: 'Ametinimetused',
        sortable: true
      }
    ],
    data: []
  };

    public ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public handleEmailChange(email: string) {
      this.email = email;
    }

    public handlePhoneChange(phone: string) {
      this.phone = phone;
    }

    public saveRepresentationRights(input: UserDto.CompanyData) {
      let index = this.userCompanies.findIndex(userCompany => userCompany.companyId == input.companyId);
      index == -1? this.userCompanies.push(input) : this.userCompanies[index] = input;

      this.bsTableControl.load(this.mapCompanyData());
    }

    private mapCompanyData() {
        return this.userCompanies.map((userCompany:UserDto.CompanyData) => {
            return {
                companyName: userCompany.companyName,
                roles: userCompany.roles.map(role => role.name).join(', '),
                phone: userCompany.phone,
                email: userCompany.email,
                jobTitles: userCompany.jobTitles.map(title => title.name).join(', '),
            };
        });
    }
}
