export namespace CompanyDto {

    export class QueryInput {
        companyId: number;
        isDeleted?: boolean;
        search?: string;
        offset?: number;
        limit?: number;
        sortFields?: string;
    }

    export class QueryOutput {
        companies: Company[];
        offset?: number;
        limit?: number;
        count?: number;
    }

    export class Company {
        id: number;
        name: string;
        regNo: string;
        vatRegNo: string;
        address: {
            address1: string;
            zip: string;
            cityName: string;
            stateName: string;
            countryId: number;
            countryName: string;
        };
        largeClientManagerUser: {
            id: number;
            name: string;
        }
        sameGovernanceAreaCompanies: GovernanceAreaCompany[]
        email: string;
        invoiceEmail: string;
        phone: string;
        partnerCode: string;
        sapCode: string;
        clientSap: string;
        governanceAreaType: string;
        invoiceChannelType: string;
        companyType: string;
        readingForwardEmail: string;
        deleted: string;
    }

    export class GovernanceAreaCompany {
      id: number;
      name: string;
      usersCount: number;
    }

    export class CompanyInput {
        id: number;
        name: string;
        regNo: string;
        vatRegNo: string;
        address: {
            address1: string;
            cityName: string;
            stateName: string;
            countryId: number;
            zip: string;
        };
        email: string;
        invoiceEmail: string;
        phone: string;
        partnerCode: string;
        sapCode: string;
        clientSap: string;
        governanceAreaType: string;
        invoiceChannelType: string;
        companyType: string;
        readingForwardEmail: string;
        largeClientManagerUserId: number;
    }
}
