<header class="section-header">
    <div class="tbl">
        <div class="tbl-row">
            <div class="tbl-cell">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active">Kasutajad</li>
                </ol>
            </div>
        </div>
    </div>
</header>

<section class="box-typical toolbar custom-section"
         [hidden]="!currentPermissions['user.manage'] && !currentPermissions['user.access.own-profile']">
    <header [hidden]="!currentPermissions['user.manage']" class="box-typical-header box-typical-header-border">
        <div class="tbl-row">
            <div class="tbl-cell">
                <ul class="nav nav-pills" *ngxPermissionsOnly="'user.list.quick-filter'">
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="quickFilter === 'active'"
                           (click)="btnQuickFilterActiveClicked()">Aktiivsed</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="quickFilter === 'inactive'"
                           (click)="btnQuickFilterInactiveClicked()">Mitteaktiivsed</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="quickFilter === 'all'" (click)="btnQuickFilterAllClicked()">Kõik</a>
                    </li>
                </ul>
            </div>
            <div class="tbl-cell tbl-cell-action-bordered">
                <a type="button" class="action-btn no-wrap" [href]="export()" target="_blank">
                    <em class="font-icon font-icon-cloud-download"></em>&nbsp;Expordi
                </a>
            </div>
            <div class="tbl-cell tbl-cell-action-bordered" *ngxPermissionsOnly="'user.manage'">
                <button type="button" class="action-btn no-wrap" [routerLink]="['/user/add']">
                    <i class="font-icon font-icon-plus"></i>&nbsp;Lisa&nbsp;kasutaja
                </button>
            </div>
        </div>
    </header>

    <div [hidden]="!currentPermissions['user.manage']" id="toolbar">
        <div class="row collapse show" [formGroup]="filterFormGroup">
            <div class="btn-group" [ngClass]="filters?.company?.length > 0 ? 'filter-active' : ''">
                <button type="button" class="btn btn-transparent dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" style="dropdown-toggle: black;">Ettevõte
                </button>
                <ul class="dropdown-menu" role="menu" #companyDropdown>
                    <li>
                        <div class="container" style="width: 300px;">
                            <div class="form-group">
                                <label class="form-label semibold">Otsi kõiki sisestatud ettevõtte inimesi:</label>
                                <div class="form-group">
                                    <div class='input-group date'>
                                        <input formControlName="company" type="text" class="form-control"
                                               placeholder="Otsi..." aria-describedby="basic-addon1">
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-xs-1" style="padding-right: 5px;">
                                    <button type="button" class="btn btn-secondary btn-sm"
                                            (click)="clearCompanyFilter()">Tühista filtreering
                                    </button>
                                </div>
                                <div class="col-xs-1" style="padding-right: 15px;">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="applyCompanyFilter()">
                                        Otsi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-group" [ngClass]="filters?.representationRight?.length > 0 ? 'filter-active' : ''">
                <button type="button" class="btn btn-transparent dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" style="dropdown-toggle: black;">Esindusõigus
                </button>
                <ul class="dropdown-menu" role="menu" #representationRightDropdown>
                    <li>
                        <div class="container" style="width: 300px;">
                            <div class="form-group">
                                <label class="form-label semibold">Otsi kõiki sisestatud esindusõigusega
                                    inimesi:</label>
                                <div class="form-group">
                                    <div class='input-group date'>
                                        <input formControlName="representationRight" type="text" class="form-control"
                                               placeholder="Otsi..." aria-describedby="basic-addon1">
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-xs-1" style="padding-right: 5px;">
                                    <button type="button" class="btn btn-secondary btn-sm"
                                            (click)="clearRepresentationRightFilter()">Tühista filtreering
                                    </button>
                                </div>
                                <div class="col-xs-1" style="padding-right: 15px;">
                                    <button type="button" class="btn btn-primary btn-sm"
                                            (click)="applyRepresentationRightFilter()">Otsi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-group" [ngClass]="filters?.personalCode?.length > 0 ? 'filter-active' : ''">
                <button type="button" class="btn btn-transparent dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" style="dropdown-toggle: black;">Isikukood
                </button>
                <ul class="dropdown-menu" role="menu" #personalCodeDropdown>
                    <li>
                        <div class="container" style="width: 300px;">
                            <div class="form-group">
                                <label class="form-label semibold">Otsi väljalt "Isikukood":</label>
                                <div class="form-group">
                                    <div class='input-group date'>
                                        <input formControlName="personalCode" type="text" class="form-control"
                                               placeholder="Otsi..." aria-describedby="basic-addon1">
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-xs-1" style="padding-right: 5px;">
                                    <button type="button" class="btn btn-secondary btn-sm"
                                            (click)="clearPersonalCodeFilter()">Tühista filtreering
                                    </button>
                                </div>
                                <div class="col-xs-1" style="padding-right: 15px;">
                                    <button type="button" class="btn btn-primary btn-sm"
                                            (click)="applyPersonalCodeFilter()">Otsi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-group" [ngClass]="filters?.country?.length > 0 ? 'filter-active' : ''">
                <button type="button" class="btn btn-transparent dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Riik
                </button>
                <ul class="dropdown-menu" role="menu" #countryDropdown>
                    <li>
                        <div class="container" style="width: 300px;">
                            <div class="form-group">
                                <label class="form-label semibold">Otsi väljalt "Riik":</label>
                                <div class="form-group">
                                    <div class='input-group date'>
                                        <input formControlName="country" type="text" class="form-control"
                                               placeholder="Otsi..." aria-describedby="basic-addon1">
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-xs-1" style="padding-right: 5px;">
                                    <button type="button" class="btn btn-secondary btn-sm"
                                            (click)="clearCountryFilter()">Tühista filtreering
                                    </button>
                                </div>
                                <div class="col-xs-1" style="padding-right: 15px;">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="applyCountryFilter()">
                                        Otsi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-group" [ngClass]="filters?.name?.length > 0 ? 'filter-active' : ''">
                <button type="button" class="btn btn-transparent dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Nimi
                </button>
                <ul class="dropdown-menu" role="menu" #nameDropdown>
                    <li>
                        <div class="container" style="width: 300px;">
                            <div class="form-group">
                                <label class="form-label semibold">Otsi väljalt "Nimi":</label>
                                <div class="form-group">
                                    <div class='input-group date'>
                                        <input formControlName="name" type="text" class="form-control"
                                               placeholder="Otsi..." aria-describedby="basic-addon1">
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-xs-1" style="padding-right: 5px;">
                                    <button type="button" class="btn btn-secondary btn-sm" (click)="clearNameFilter()">
                                        Tühista filtreering
                                    </button>
                                </div>
                                <div class="col-xs-1" style="padding-right: 15px;">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="applyNameFilter()">
                                        Otsi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-group" [ngClass]="filterFormGroup.get('source').value !== null ? 'filter-active' : ''">
                <button type="button" class="btn btn-transparent dropdown-toggle" data-toggle="dropdown"
                        data-container="body" aria-haspopup="true" aria-expanded="false">Allikas
                </button>
                <ul class="dropdown-menu" role="menu" #sourceDropdown>
                    <li>
                        <div class="container" style="width: 300px;">
                            <div class="form-group">
                                <label class="form-label semibold">Otsi väljalt "Allikas":</label>
                                <div class="form-group">
                                    <ng-select formControlName="source" [items]="sourceOptions" bindLabel="text"
                                               bindValue="id"></ng-select>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-xs-1" style="padding-right: 5px;">
                                    <button type="button" class="btn btn-secondary btn-sm"
                                            (click)="clearSourceFilter()">Tühista filtreering
                                    </button>
                                </div>
                                <div class="col-xs-1" style="padding-right: 15px;">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="applySourceFilter()">
                                        Otsi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-group"
                 [ngClass]="filterFormGroup.get('dateFrom').value !== null || filterFormGroup.get('dateTo').value !== null ? 'filter-active' : ''">
                <button type="button" class="btn btn-transparent dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Kuupäev
                </button>
                <ul class="dropdown-menu" role="menu" #daterangeDropdown>
                    <li>
                        <div class="container" style="width: 300px;">
                            <div class="form-group">
                                <label class="form-label semibold">Alates:</label>
                                <div class="form-group">
                                    <div class='input-group date'>
                                        <input type="text" value="" id="datetimePickerFrom" formControlName="dateFrom"
                                               class="form-control" #filterDateFrom>
                                        <div class="input-group-append">
                                            <span class="input-group-text">
                                                <i class="font-icon font-icon-calend"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group">
                                <label class="form-label semibold">Kuni:</label>
                                <div class="form-group">
                                    <div class='input-group date'>
                                        <input type="text" value="" id="datetimePickerTo" formControlName="dateTo"
                                               class="form-control" #filterDateTo>
                                        <div class="input-group-append">
                                            <span class="input-group-text">
                                                <i class="font-icon font-icon-calend"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-xs-1" style="padding-right: 5px;">
                                    <button type="button" class="btn btn-secondary btn-sm" (click)="clearDateFilter()">
                                        Tühista filtreering
                                    </button>
                                </div>
                                <div class="col-xs-1" style="padding-right: 15px;">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="applyDateFilter()">
                                        Otsi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-group" [ngClass]="filters?.largeClientManager?.length > 0 ? 'filter-active' : ''">
                <button type="button" class="btn btn-transparent dropdown-toggle" data-toggle="dropdown"
                        data-container="body" aria-haspopup="true" aria-expanded="false">Suurkliendihaldur
                </button>
                <ul class="dropdown-menu" role="menu" #largeClientManagerDropdown>
                    <li>
                        <div class="container" style="width: 300px;">
                            <div class="form-group">
                                <label class="form-label semibold">Otsi väljalt "Suurkliendihaldur":</label>
                                <div class="form-group">
                                    <ng-select
                                        clearable="true"
                                        [multiple]="true"
                                        bindLabel="name"
                                        bindValue="id"
                                        [closeOnSelect]="false"
                                        formControlName="largeClientManager"
                                        (change)="selectAllLargeClientManagers($event)"
                                    >
                                        <ng-option value="All">Kõik</ng-option>
                                        <ng-option
                                            *ngFor="let item of largeClientManagerOptions"
                                            [value]="item"
                                        >
                                            {{item.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-xs-1" style="padding-right: 5px;">
                                    <button type="button" class="btn btn-secondary btn-sm"
                                            (click)="clearLargeClientManagerFilter()">Tühista filtreering
                                    </button>
                                </div>
                                <div class="col-xs-1" style="padding-right: 15px;">
                                    <button type="button" class="btn btn-primary btn-sm"
                                            (click)="applyLargeClientManagerFilter()">Otsi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="btn-group" [class.filter-active]="!!filterFormGroup.get('ministry').value">
                <button type="button" class="btn btn-transparent dropdown-toggle" data-toggle="dropdown"
                        data-container="body" aria-haspopup="true" aria-expanded="false">Ministeerium
                </button>
                <ul class="dropdown-menu" role="menu" #ministryDropdown>
                    <li>
                        <div class="container" style="width: 300px;">
                            <div class="form-group">
                                <label class="form-label semibold">Otsi väljalt "Ministeerium":</label>
                                <ng-select
                                    [items]="ministryTypes"
                                    bindLabel="name"
                                    bindValue="id"
                                    formControlName="ministry"
                                    [closeOnSelect]="false"
                                ></ng-select>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-xs-1" style="padding-right: 5px;">
                                    <button type="button" class="btn btn-secondary btn-sm"
                                            (click)="clearMinistryFilter()">Tühista filtreering
                                    </button>
                                </div>
                                <div class="col-xs-1" style="padding-right: 15px;">
                                    <button type="button" class="btn btn-primary btn-sm"
                                            (click)="applyMinistryFilter()">Otsi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center ml-auto mr-3">
                <input type="search" formControlName="search" class="form-control pl-1" placeholder="Sisesta otsingu sõna..">
            </div>
        </div>
    </div>

    <div class="box-typical-body">
        <table class="table table-striped"
               data-filter-control="false"
               data-show-toggle="false"
               data-show-export="true"
               data-detail-view="false"
               data-show-pagination-switch="false"
               data-minimum-count-columns="2"
               data-pagination="true"
               data-id-field="id"
               data-show-footer="false"
               data-show-refresh="true"
               data-show-columns="true"
               bs-table-control [bs-table-options]="bsTableOptions" #bsTableElement
        >
            <thead>
            <tr>
                <th data-field="personalCode" data-sortable="true" data-filter-control="input">Isikukood</th>
                <th data-field="countryCode" data-sortable="true" data-filter-control="select">Riik</th>
                <th data-field="name" data-sortable="true" data-filter-control="input">Nimi</th>
                <th data-field="isExternal" data-sortable="true" data-filter-control="select">Allikas</th>
                <th data-field="registrationTimestamp" data-sortable="true" data-filter-control="input"
                    data-width="200">Registreerimise kuupäev
                </th>
            </tr>
            </thead>
            <tbody></tbody>
        </table>
    </div>
</section>
