import { Injectable } from '@angular/core';

import { CompanyRes } from "@app/core/resource/company.resource";
import { CompanyDto } from "@app/core/resource-dto/company";
import {AuthService} from "@app/core/services/auth.service";

import * as lnr from 'elasticlunr';
import {QueryAdapter} from "@app/shared/QueryAdapter";

@Injectable()
export class CompanyAdapter extends QueryAdapter{

  public constructor(
      private companyRes:CompanyRes,
      private authService:AuthService
  ) {
    super();
  }

  public loadCompanies() {
      let query: CompanyDto.QueryInput = new CompanyDto.QueryInput;
      query.companyId = this.authService.getCurrentUserCompany().id;
      query.limit = 99999;
      this.companyRes.query(query).then((companiesQueryOutput: CompanyDto.QueryOutput) => {
          this.updateMap(companiesQueryOutput.companies);
          this.updateSearchIndex();
          this.updateSource();
      });
  }

  public updateMap(companies:CompanyDto.Company[]) {
    this.map = new Map<number, any>(companies.map((company:any) => [company.id, company] as [number, any]));
  }

  public updateSearchIndex() {
    this.index = new lnr.Index;

    this.index.pipeline.reset();
    this.index.pipeline.add(lnr.trimmer);

    this.index.addField('text');
    this.index.setRef('id');

    this.map.forEach((company: any) => {this.index.addDoc({id: company.id, text: company.name})});
  }

  public updateSource() {
    this.dataSource.next(this.map);

    let options:any[] = [];
    this.map.forEach((company: any) => {options.push({id: company.id, text: company.name})});
    this.optionsSource.next(options);
  }
}
