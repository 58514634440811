import {Component, OnInit, ViewChild} from '@angular/core';

import '../assets/styles/main.less';
import {AuthService} from "./core/services/auth.service";
import {UserLoginDto} from "./core/resource-dto/user-login";
import {RepresentativeModalComponent} from "./representative-modal.component";
import {Router} from "@angular/router";
import {PermissionsService} from "./shared/services/permissions.service";

import {ClassifierService} from "./core/services/classifier.service";
import {ConfigService} from "app/config/config.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    @ViewChild(RepresentativeModalComponent, { static: true })
    representativeModal:RepresentativeModalComponent;

    public isUserLoggedIn:boolean = false;
    public currentUser:UserLoginDto.User = null;
    public currentUserCompany:UserLoginDto.UserCompany = null;

    constructor(
        private config: ConfigService,
        private router:Router,
        private authService:AuthService,
        private permissionsService:PermissionsService,
        private classifierService: ClassifierService,
        private titleService: Title
        //private matomoInjector: MatomoInjector
    ) {
        /*let matomoCfg = config.get('matomo');
        if (matomoCfg) {
            this.matomoInjector.init(matomoCfg.url, matomoCfg.siteId);
        }*/
        this.classifierService.init();
        if(config.get("title")) {
            this.titleService.setTitle(config.get("title"))
        }

    }

    public ngOnInit(): void {
        this.authService.user$.subscribe((user:UserLoginDto.User) => {
            this.currentUserCompany = this.authService.getCurrentUserCompany();
            this.currentUser = user;
            this.isUserLoggedIn = !!this.currentUser;

            this.checkCurrentUserRepresentative();
        });
    }

    public checkCurrentUserRepresentative(): void {
        if (!this.currentUserCompany) {
            if (this.currentUser.companies.length == 1) {
                if (this.currentUser.companies[0]) {
                    this.authService.setCurrentCompanyId(this.currentUser.companies[0].id);
                }
            } else if (this.currentUser.gdprAccepted) {
                this.representativeModal.show();
            }
        }
    }
}
