import { Injectable } from '@angular/core';
import { NgxPermissionsStore } from 'ngx-permissions';
import {Observable, of} from "rxjs";

@Injectable({providedIn: 'root'})
export class PermissionsService {

    private permissionsSource: any = this.permissionsStore.permissionsSource;
    public readonly permissions$: Observable<any> = this.permissionsSource.asObservable();

    constructor(private permissionsStore: NgxPermissionsStore) {}

    public hasPermission(permission: string | string[]): Promise<boolean> {
      if (!permission || (Array.isArray(permission) && !permission.length)) return Promise.resolve(true);

      return this.hasArrayPermission(Array.isArray(permission) ? permission : [permission]);
    }

    public setPermissions(permissions: string[]) {
      this.permissionsSource.next(permissions.reduce((acc, name) => ({...acc, [name]: {name}}), {}));
    }

    private hasArrayPermission(permissions: string[]): Promise<boolean> {
      return of(permissions.reduce((acc, curr) => acc || !!this.permissionsSource.value[curr], false)).toPromise();
    }
}
