import {UserLoginDto} from "../resource-dto/user-login";

export class Representative {
    constructor(user?:UserLoginDto.User, userCompany?:UserLoginDto.UserCompany, permissions?:any) {
        this.user = user;
        this.userCompany = userCompany;
        this.permissions = permissions;
    }

    user: UserLoginDto.User;
    userCompany: UserLoginDto.UserCompany;
    permissions: any;
}