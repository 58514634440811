import {Component, ViewChild} from '@angular/core';

import {FormComponent} from "@app/company/form/form.component";
import {CompanyDto} from "@app/core/resource-dto/company";
import {ManageService} from "@app/company/manage/manage.service";

@Component({
    templateUrl: './add.component.html',
    providers: [ ManageService ],
    styleUrls: [
        '../../../assets/styles/pages/editor.less',
        '../../../assets/styles/pages/project.less'
    ]
})
export class CompanyAddComponent {

    @ViewChild(FormComponent)
    formComponent: FormComponent;

    public company:CompanyDto.Company = new CompanyDto.Company();

    btnSaveClicked(e:any) {
        this.formComponent.btnSaveClicked(e);
    }
}
