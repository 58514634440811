export namespace RoleDto {

    export class QueryInput {
        status?: string;
        companyId: number;
        isDeleted: boolean;
    }

    export class QueryOutput {
        roles: Role[];
    }

    export class Role {
        id: number;
        code: string;
        name: string;
        roleType: string;
        createdAt: string;
        modifiedAt: string;
        deletedAt: string;
        createdBy: User;
        modifiedBy: User;
        deletedBy: User;
        basisOfAssignRequired: boolean;
    }

    export class User {
        id: number;
        name: string;
    }

    export class RoleInput {
        id: number;
        code: string;
        name: string;
        roleType: string;
        basisOfAssignRequired: boolean;
    }
}
