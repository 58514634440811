<section class="box-typical">
    <header class="box-typical-header box-typical-header-border">
        <div class="tbl-row">
            <div class="tbl-cell tbl-cell-title">
                <h3>Ettevõtte andmed</h3>
            </div>
        </div>
    </header>
    <div class="box-typical-body box-typical-padding">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="tbl">
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Tüüp:</div>
                        <div class="tbl-cell"><strong>{{company?.companyType | classifier:'COMPANY_TYPE'}}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Riik:</div>
                        <div class="tbl-cell"><strong>{{company?.address?.countryName}}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Registrinumber:</div>
                        <div class="tbl-cell"><strong>{{company?.regNo}}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Nimi:</div>
                        <div class="tbl-cell"><b>{{company?.name}}</b></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Tehingupartneri kood:</div>
                        <div class="tbl-cell"><b>{{company?.partnerCode? company.partnerCode : '-'}}</b></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">KMKR number:</div>
                        <div class="tbl-cell"><b>{{company?.vatRegNo? company.vatRegNo : '-'}}</b></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">SAP kood:</div>
                        <div class="tbl-cell"><b>{{company?.sapCode? company.sapCode : '-'}}</b></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Valitsemisala:</div>
                        <div class="tbl-cell"><b>{{company?.governanceAreaType ? (company?.governanceAreaType | classifier:'GOVERNANCE_AREA_TYPE') : '-'}}</b></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Näitude edastamise email:</div>
                        <div class="tbl-cell"><b>{{company?.readingForwardEmail ? company.readingForwardEmail : '-'}}</b></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="tbl">
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Maakond:</div>
                        <div class="tbl-cell"><strong>{{company?.address?.stateName}}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Linn:</div>
                        <div class="tbl-cell"><strong>{{company?.address?.cityName}}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Tänav:</div>
                        <div class="tbl-cell"><strong>{{company?.address?.address1}}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Postiindeks:</div>
                        <div class="tbl-cell"><strong>{{company?.address?.zip}}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Telefon:</div>
                        <div class="tbl-cell"><strong>{{company?.phone? company.phone : '-'}}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">E-mail:</div>
                        <div class="tbl-cell"><strong>{{company?.email? company.email : '-'}}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Arvelduse e-mail:</div>
                        <div class="tbl-cell"><strong>{{company?.invoiceEmail? company.invoiceEmail : '-'}}</strong></div>
                    </div>
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-lbl w200">Arve saatmise kanal:</div>
                        <div class="tbl-cell"><strong>{{company?.invoiceChannelType? (company?.invoiceChannelType | classifier:'INVOICE_CHANNEL_TYPE') : '-'}}</strong></div>
                    </div>
                    <div class="tbl-row" *ngIf="!!company?.largeClientManagerUser">
                        <div class="tbl-cell tbl-cell-lbl w200">Suurkliendihaldur:</div>
                      <div class="tbl-cell"><strong>{{ company?.largeClientManagerUser.name}}</strong></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box-typical-footer">
        <div class="tbl">
            <div class="tbl-row">
                <div class="tbl-cell tbl-cell-action text-right">
                    <a [routerLink]="['/companies']" class="btn btn-inline btn-secondary">Tagasi</a>
                    <a (click)="editCompany()" class="btn btn-inline btn-primary">Muuda</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="box-typical">
    <header class="box-typical-header">
        <div class="tbl-row">
            <div class="tbl-row">
                <div class="tbl-cell tbl-cell-title">
                    <h3>Esindajad</h3>
                </div>
            </div>
            <div class="tbl-cell tbl-cell-action-bordered" *ngxPermissionsOnly="'representation-right.manage'">
                <button type="button" class="action-btn no-wrap" (click)="navigateToAddRepresentationRight()">
                    <em class="font-icon font-icon-plus"></em>&nbsp;Lisa&nbsp;esindaja
                </button>
            </div>
        </div>
    </header>
    <table bs-table-control
           #bsTableElement
           [bs-table-options]="bsTableOptions"
           class="table-striped table-sm"
           data-pagination="true"
           data-id-field="id"
    ></table>
</section>

<app-governance-area-companies *ngIf="company?.largeClientManagerUser" [governanceAreaCompanies]="company.sameGovernanceAreaCompanies"></app-governance-area-companies>




