<div class="app app-header-fixed app-aside-dock" [class.with-side-menu]="isUserLoggedIn">
    <app-representative-modal (show)="true"></app-representative-modal>

    <app-header></app-header>

    <app-side-menu *ngIf="isUserLoggedIn"></app-side-menu>

    <div class="page-content">
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>

    <app-footer></app-footer>
</div>
