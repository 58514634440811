<form [formGroup]="formGroup">
    <div class="alert alert-danger" role="alert" *ngIf="formErrors['invalid'] && showFormErrors">
        <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
            <span aria-hidden="true">&times;</span>
        </button>
        Vormil esineb vigu!
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="formErrors['company_already_exists']">
        <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
            <span aria-hidden="true">&times;</span>
        </button>
        Selline roll juba eksisteerib!
    </div>
    <div class="box-typical-body" style="padding:0 5px 0 15px">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="code">Kood</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" [class.is-invalid]="isInvalid('code')"
                               formControlName="code" id="code">
                        <div class="invalid-feedback"
                             [hidden]="!formFieldErrors?.code?.required">{{formFieldErrors?.code?.required}}</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="name">Nimi</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" [class.is-invalid]="isInvalid('name')"
                               formControlName="name" id="name">
                        <div class="invalid-feedback"
                             [hidden]="!formFieldErrors?.name?.required">{{formFieldErrors?.name?.required}}</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="roleType">Rolli tüüp</label>
                    <div class="col-sm-8">
                        <ng-select
                            bindValue="code"
                            bindLabel="name"
                            formControlName="roleType"
                            id="roleType"
                            [items]="roleTypes"
                            [class.is-invalid]="isInvalid('roleType')"
                        ></ng-select>
                        <div class="invalid-feedback" [class.d-block]="formFieldErrors?.roleType?.required">
                            {{ formFieldErrors?.roleType?.required }}
                        </div>
                        <div class="invalid-feedback" [class.d-block]="!!formErrors?.role_already_exists">
                            Selline roll juba eksisteerib
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="form-group row align-items-center">
                    <label class="col-sm-4 col-form-label" for="basisOfAssign">Nõutud rolli andmise alus</label>
                    <div class="col-sm-8">
                        <input type="checkbox" formControlName="basisOfAssignRequired" id="basisOfAssign">
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
