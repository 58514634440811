<form [formGroup]="formGroup">
    <div class="alert alert-danger" role="alert" *ngIf="formErrors['invalid'] && showFormErrors">
        <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
            <span aria-hidden="true">&times;</span>
        </button>
        Vormil esineb vigu!
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="formErrors['company_already_exists']">
        <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
            <span aria-hidden="true">&times;</span>
        </button>
        Selline ettevõte juba eksisteerib!
    </div>
    <div class="box-typical-body" style="padding:0 5px 0 15px">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tüüp</label>
                            <div class="col-sm-8">
                                <ng-select [items]="companyTypes"
                                           bindLabel="text"
                                           bindValue="id"
                                           formControlName="companyType"
                                           [class.is-invalid]="isInvalid('companyType')"></ng-select>
                                <div class="invalid-feedback" [class.d-block]="formFieldErrors?.companyType?.required">{{formFieldErrors?.companyType?.required}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Riik</label>
                            <div class="col-sm-8">
                                <ng-select
                                  formControlName="country"
                                  bindLabel="text"
                                  typeToSearchText="Kirjuta, et otsida..."
                                  [items]="countryAdapter.options$ | async"
                                  [typeahead]="countryAdapter.querySource"
                                  [class.is-invalid]="isInvalid('country')"
                                ></ng-select>
                                <div class="invalid-feedback" [class.d-block]="formFieldErrors?.country?.required">{{formFieldErrors?.country?.required}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Registrinumber</label>
                            <div class="col-sm-8 input-group">
                                <input type="text" class="form-control" [class.is-invalid]="isInvalid('regNo')" formControlName="regNo">
                                <span class="input-group-btn">
                                    <button *ngIf="searchVisible" class="btn btn-primary swal-btn-info" (click)="getPublicCompanies()">Otsi</button>
                                </span>
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.regNo?.required">{{formFieldErrors?.regNo?.required}}</div>
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.regNo?.maxlength">{{formFieldErrors?.regNo?.maxlength}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Nimi</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" [class.is-invalid]="isInvalid('name')" formControlName="name">
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.name?.required">{{formFieldErrors?.name?.required}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tehingupartneri kood</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" [class.is-invalid]="isInvalid('partnerCode')" formControlName="partnerCode">
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.partnerCode?.required">{{formFieldErrors?.partnerCode?.required}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">KMKR number</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" [class.is-invalid]="isInvalid('vatRegNo')" formControlName="vatRegNo">
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.vatRegNo?.required">{{formFieldErrors?.vatRegNo?.required}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">SAP kood</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" [class.is-invalid]="isInvalid('sapCode')" formControlName="sapCode">
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.sapCode?.required">{{formFieldErrors?.sapCode?.required}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Arve saatmise kanal</label>
                            <div class="col-sm-8">
                                <ng-select
                                  bindLabel="text"
                                  bindValue="id"
                                  formControlName="invoiceChannelType"
                                  [items]="invoiceChannelTypes"
                                  [class.is-invalid]="isInvalid('invoiceChannelType')"
                                ></ng-select>
                                <div class="invalid-feedback" [class.d-block]="formFieldErrors?.invoiceChannelType?.required">{{formFieldErrors?.invoiceChannelType?.required}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Näitude edastamise email</label>
                            <div class="col-sm-8">
                                <app-form-email [showErrors]="showFormErrors" formControlName="readingForwardEmail" [errorMessage]="validationMessages.readingForwardEmail" [isRequired]="false" separator=", "></app-form-email>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Maakond</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" [class.is-invalid]="isInvalid('stateName')" formControlName="stateName">
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.stateName?.required">{{formFieldErrors?.stateName?.required}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Linn</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" [class.is-invalid]="isInvalid('cityName')" formControlName="cityName">
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.cityName?.required">{{formFieldErrors?.cityName?.required}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Tänav</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" [class.is-invalid]="isInvalid('address')" formControlName="address">
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.address?.required">{{formFieldErrors?.address?.required}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Postiindeks</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" [class.is-invalid]="isInvalid('zip')" formControlName="zip">
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.zip?.required">{{formFieldErrors?.zip?.required}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Telefon</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" [class.is-invalid]="isInvalid('phone')" formControlName="phone">
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.phone?.required">{{formFieldErrors?.phone?.required}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">E-mail</label>
                            <div class="col-sm-8">
                                <app-form-email [showErrors]="showFormErrors" formControlName="email" [errorMessage]="validationMessages.email" [isRequired]="!currentPermissions['company.manage.without-contacts']"></app-form-email>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Arvelduse e-mail</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" [class.is-invalid]="isInvalid('invoiceEmail')" formControlName="invoiceEmail">
                                <div class="invalid-feedback" [hidden]="!formFieldErrors?.invoiceEmail?.email">{{formFieldErrors?.invoiceEmail?.email}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Valitsemisala</label>
                            <div class="col-sm-8">
                                <ng-select
                                  bindLabel="text"
                                  formControlName="governanceAreaType"
                                  [items]="governanceAreaTypes"
                                  [class.is-invalid]="isInvalid('governanceAreaType')"
                                ></ng-select>
                                <div class="invalid-feedback" [class.d-block]="formFieldErrors?.governanceAreaType?.required">{{formFieldErrors?.governanceAreaType?.required}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngxPermissionsOnly="'company.manage.ministry-manager'">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Suurkliendihaldur</label>
                            <div class="col-sm-8">
                                <ng-select
                                    bindLabel="text"
                                    formControlName="largeClientManager"
                                    [items]="userAdapter.options$ | async"
                                    [typeahead]="userAdapter.querySource"
                                ></ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
