<div class="mobile-menu-left-overlay"></div>
<nav class="side-menu">
    <section>
        <ul class="side-menu-list">
            <li class="blue" *ngxPermissionsOnly="'application.workorder'">
                <a data-toggle="collapse" href="#portalApplications">
                    <i class="fa fa-calendar" ></i>
                    <span class="lbl">Kliendiportaal</span>
                    <span class="caret" style="color: grey"></span>
                </a>
                <div class="collapse" id="portalApplications" style="padding-left: 30px; padding-right: 5px">
                    <a href="{{config.getCoreAppUrl('workorder', '/')}}">
                        <i class="glyphicon glyphicon-list-alt"></i>
                        <span class="lbl">Töötaotlused</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('workorder', '/inspections')}}" *ngxPermissionsOnly="[
                    'inspection.access.all',
                    'inspection.access.property-inspections',
                    'th.access.records-north',
                    'th.access.records-south',
                    'th.access.records-east',
                    'th.access.records-west',
                    'hk.access.records-north',
                    'hk.access.records-south',
                    'hk.access.records-east',
                    'hk.access.records-west'
                    ]">
                        <i class="glyphicon glyphicon-ok"></i>
                        <span class="lbl">Ülevaatused</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('workorder', '/acts')}}" *ngxPermissionsOnly="[
                    'maintenance-contract.access.all-contracts',
                    'maintenance-contract.access.company-contracts',
                    'maintenance-contract.access.project-manager-and-dispatcher-contracts',
                    'maintenance-contract.access.works-manager-contracts',
                    'th.access.records-north',
                    'th.access.records-south',
                    'th.access.records-east',
                    'th.access.records-west',
                    'hk.access.records-north',
                    'hk.access.records-south',
                    'hk.access.records-east',
                    'hk.access.records-west'
                    ]">
                        <i class="glyphicon glyphicon-euro"></i>
                        <span class="lbl">Aktid</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('workorder', '/contracts')}}" *ngxPermissionsOnly="[
                    'act.access.all-acts',
                    'act.access.company-acts',
                    'act.access.property-acts',
                    'act.access.regional-property-acts',
                    'th.access.records-north',
                    'th.access.records-south',
                    'th.access.records-east',
                    'th.access.records-west',
                    'hk.access.records-north',
                    'hk.access.records-south',
                    'hk.access.records-east',
                    'hk.access.records-west'
                    ]">
                        <i class="glyphicon glyphicon-duplicate"></i>
                        <span class="lbl">Hoolduslepingud</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('workorder', '/admin')}}" *ngxPermissionsOnly="['sync.start']">
                        <i class="glyphicon glyphicon-cog"></i>
                        <span class="lbl">Administreerimine</span>
                    </a>
                </div>
            </li>
            <li class="blue-dirty" *ngxPermissionsOnly="'application.user-registry'">
                <a data-toggle="collapse" href="#usersApplications">
                    <i class="glyphicon glyphicon-user"></i>
                    <span class="lbl">Kasutajate register</span>
                    <span class="caret" style="color: grey"></span>
                </a>
                <div class="collapse show" id="usersApplications" style="padding-left: 30px; padding-right: 5px">
                    <a [routerLink]="['/list']" routerLinkActive="active">
                        <i class=" glyphicon glyphicon-user"></i>
                        <span class="lbl">Kasutajate nimekiri</span>
                    </a>
                    <a [routerLink]="['/companies']" routerLinkActive="active" *ngxPermissionsOnly="[
                    'company.manage',
                    'representation-right.access.all-rights',
                    'representation-right.access.company-rights'
                    ]">
                        <i class=" glyphicon glyphicon-th-list"></i>
                        <span class="lbl">Ettevõtted</span>
                    </a>
                    <a [routerLink]="['/roles']" routerLinkActive="active" *ngxPermissionsOnly="['role.manage']">
                        <i class=" glyphicon glyphicon-th-list"></i>
                        <span class="lbl">Rollid</span>
                    </a>
                </div>
            </li>
            <li class="aquamarine" *ngxPermissionsOnly="'application.property-registry'">
                <a data-toggle="collapse" href="#propertyApplications">
                    <i class="fa fa-calendar"></i>
                    <span class="lbl">Kinnisturegister</span>
                    <span class="caret" style="color: grey"></span>
                </a>
                <div class="collapse" id="propertyApplications" style="padding-left: 30px; padding-right: 5px">
                    <a href="{{config.getCoreAppUrl('propertyregistry', '/#/properties')}}">
                        <i class="glyphicon glyphicon-home"></i>
                        <span class="lbl">Kinnistud</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('propertyregistry', '/#/buildings')}}">
                        <i class="glyphicon glyphicon-home"></i>
                        <span class="lbl">Hooned</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('propertyregistry', '/#/appliances')}}">
                        <i class="glyphicon glyphicon-list-alt"></i>
                        <span class="lbl">Seadmed</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('propertyregistry', '/#/packs')}}">
                        <i class="glyphicon glyphicon-euro"></i>
                        <span class="lbl">Hinnapaketid</span>
                    </a>
                </div>
            </li>
            <div *ngIf="config.get('name') === 'Riigi Kinnisvara AS'">
                <li class="red" *ngxPermissionsOnly="'application.tuumik'">
                    <a data-toggle="collapse" href="#costDivApplications">
                        <i class="fa fa-calendar"></i>
                        <span class="lbl">Kulude jagamine</span>
                        <span class="caret" style="color: grey"></span>
                    </a>
                    <div class="collapse" id="costDivApplications" style="padding-left: 30px; padding-right: 5px">
                        <header class="side-menu-title" style="padding-top:10px;">Kulude jagamine</header>
                        <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                            <i class="glyphicon glyphicon-euro gold"></i>
                            <span class="lbl">Jooksvad kulud</span>
                        </a>
                        <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                            <i class="glyphicon glyphicon-euro"></i>
                            <span class="lbl">Arveldamata kulud</span>
                        </a>
                        <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                            <i class="glyphicon glyphicon-euro"></i>
                            <span class="lbl">Arved</span>
                        </a>
                        <div class="dropdown-divider"></div>
                        <header class="side-menu-title" style="padding-top:10px;">Ostuarved</header>
                        <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                            <i class="glyphicon glyphicon-duplicate"></i>
                            <span class="lbl">Konteeringud</span>
                        </a>
                        <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                            <i class="glyphicon glyphicon-duplicate"></i>
                            <span class="lbl">Ostuarved</span>
                        </a>
                        <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                            <i class="glyphicon glyphicon-duplicate"></i>
                            <span class="lbl">XML adapterid</span>
                        </a>
                        <div class="dropdown-divider"></div>
                        <header class="side-menu-title" style="padding-top:10px;">Administreerimine</header>
                        <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                            <i class="glyphicon glyphicon-duplicate"></i>
                            <span class="lbl">Üürnike jaotus</span>
                        </a>
                        <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                            <i class="glyphicon glyphicon-duplicate"></i>
                            <span class="lbl">Kontoplaan</span>
                        </a>
                        <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                            <i class="glyphicon glyphicon-duplicate"></i>
                            <span class="lbl">Üürilepingud</span>
                        </a>
                        <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                            <i class="glyphicon glyphicon-duplicate"></i>
                            <span class="lbl">Näitude sisestamine</span>
                        </a>
                    </div>
                </li>
            </div>
            <li class="orange-red" *ngxPermissionsOnly="'application.contract-registry'">
                <a href="{{config.getCoreAppUrl('lepingud', '/')}}">
                    <i class="glyphicon glyphicon-duplicate" ></i>
                    <span class="lbl">Lepinguregister</span>
                </a>
            </li>
            <div *ngIf="config.get('name') === 'Riigi Kinnisvara AS'">
                <li class="purple" *ngxPermissionsOnly="'application.projects'">
                    <a href="{{config.getCoreAppUrl('projektid', '/')}}">
                        <i class=" glyphicon glyphicon-hdd" ></i>
                        <span class="lbl">Projektipank</span>
                    </a>
                </li>
            </div>
            <li class="red">
                <a [routerLink]="['', 'admin']" *ngxPermissionsOnly="['sync.start']">
                    <i class="glyphicon glyphicon-cog"></i>
                    <span class="lbl">Administreerimine</span>
                </a>
            </li>
        </ul>
    </section>
</nav><!--.side-menu-->
