import {Injectable} from '@angular/core';

import {UserDto} from "../../core/resource-dto/user";
import {UserRes} from "../../core/resource/user.resource";
import {AuthService} from "../../core/services/auth.service";
import {SubstitutionRes} from "../../core/resource/substitution.resource";
import {ReplaySubject} from "rxjs";

@Injectable()
export class ManageService {
    protected userSource = new ReplaySubject<UserDto.User>(1);

    public readonly user$ = this.userSource.asObservable();

    private _user:UserDto.User;

    constructor(
        private _authService:AuthService,
        private _userRes:UserRes,
        private _substitutionRes:SubstitutionRes

    ) {
        this.user$.subscribe((user:UserDto.User) => {
            this._user = user;
        })
    }

    loadUser(userId?:number) {
        if(userId) {
            this._userRes.get({userId: userId, companyId: this._authService.getCurrentUserCompany().id}).then((user: UserDto.User) => {
                this.setUser(user);
            });
        } else {
            if(this._user && this._user.id) {
                this._userRes.get({userId: this._user.id, companyId: this._authService.getCurrentUserCompany().id}).then((user: UserDto.User) => {
                    this.setUser(user);
                });
            }
        }
    }

    setUser(user:UserDto.User) {
        this.userSource.next(user);
    }

    saveUser(input:UserDto.UserInput) {
        return this._userRes.save(input, {companyId: this._authService.getCurrentUserCompany().id});
    }


    deleteUser(userid: number) {
        return this._userRes.delete({userId: userid});
    }

    updateUser(input:UserDto.UserInput) {
        return this._userRes.update(input, {companyId: this._authService.getCurrentUserCompany().id}, {userId: input.id});
    }

    getUserCompany(userId:number, companyId:number) {
        return this._userRes.getCompany({userId: userId, companyId: companyId}, {companyId: this._authService.getCurrentUserCompany().id});
    }

    saveCompany(input:UserDto.CompanyInput) {
        return this._userRes.saveCompany(input, {companyId: this._authService.getCurrentUserCompany().id}, {userId: this._user.id});
    }

    updateCompany(input:UserDto.CompanyInput, companyId:number) {
        return this._userRes.updateCompany(input, {companyId: this._authService.getCurrentUserCompany().id}, {userId: this._user.id, companyId: companyId});
    }

    deleteCompany(companyId:number) {
        return this._userRes.deleteCompany({userId: this._user.id, companyId: companyId}, {companyId: this._authService.getCurrentUserCompany().id});
    }

    querySubstitutions(userId:number) {
        return this._substitutionRes.querySubstitutions(null, {userId: userId, companyId: this._authService.getCurrentUserCompany().id});
    }

    saveSubstitution(input:UserDto.SubstitutionInput) {
        return this._substitutionRes.saveSubstitution(input, {companyId: this._authService.getCurrentUserCompany().id}, {userId: this._user.id});
    }

    updateSubstitution(input:UserDto.SubstitutionInput, substitutionId:number, userId: number) {
        return this._substitutionRes.updateSubstitution(input, {companyId: this._authService.getCurrentUserCompany().id}, {substitutionId: substitutionId});
    }

    deleteSubstitution(substitutionId:number) {
        return this._substitutionRes.deleteSubstitution({substitutionId: substitutionId}, {companyId: this._authService.getCurrentUserCompany().id});
    }

    public getBasisOfAssign(userId: number): Promise<any> {
        return this._userRes.roleAssignBasis({userId: userId});
    }
}
