import {
    Component, ElementRef, OnDestroy,
    OnInit, ViewChild
} from '@angular/core';
import {ManageService} from '../../manage/manage.service';
import {DatePipe} from '@angular/common';
import {BsTableControl} from "../../../shared/directives/bs-table-control.directive";
import {UserDto} from "../../../core/resource-dto/user";
import {PermissionsService} from "../../../shared/services/permissions.service";
import {UserRes} from "../../../core/resource/user.resource";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../core/services/auth.service";
import {SubstitutionRes} from "../../../core/resource/substitution.resource";
import {Observable, Subject} from "rxjs";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";

declare let jQuery: any;
declare let swal: any;


@Component({
    selector: 'susbstitution-table',
    templateUrl: './susbstitution-table.component.html'
})
export class SusbstitutionTableComponent implements OnInit, OnDestroy {

    private ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(BsTableControl)
    bsTableControl: BsTableControl;

    @ViewChild('bsTableElement', { static: true })
    bsTableElement: ElementRef;

    public userSubstitutions: UserDto.Substitution[] = [];
    public bsTableOptions: any = {
        toolbar: undefined,
        showFooter: false,
        pagination: false,
        columns: [
            {
                field: 'substitution',
                title: 'Asendus',
                sortable: true,
                formatter: (value:any, row:any, rowIndex:any) => {
                    if(!value.allowed){
                        return value.name + ' asendab kasutajat <i>' + value.actingAsName + '</i>';
                    }
                    else {
                        const path = "/user/" + value.userId;
                        if (value.type == 'SUBSTITUTABLE') {
                            return '<a href="'+ path + '" class="user-link" data-id="' + value.userId + '">' + value.name + '</a> asendab kasutajat ' + value.actingAsName;
                        }
                        else if (value.type == 'SUBSTITUTE') {
                            return value.name + ' asendab kasutajat <a href="'+ path + '" class="user-link" data-id="' + value.userId + '">' + value.actingAsName+ '</a>';
                        }
                    }
                }
            },
            {
                field: 'period',
                title: 'Periood',
                sortable: true,
                sorter: function (a:string, b:string){
                    let aStartDate = a.split(" - ")[0].split(".");
                    let bStartDate = b.split(" - ")[0].split(".");

                    const aYear = Number(aStartDate[2]);
                    const aMonth = Number(aStartDate[1]) - 1;
                    const aDate = Number(aStartDate[0]);

                    const bYear = Number(bStartDate[2]);
                    const bMonth = Number(bStartDate[1]) - 1;
                    const bDate = Number(bStartDate[0]);

                    const aFormatted = new Date(aYear, aMonth, aDate);
                    const bFormatted = new Date(bYear, bMonth, bDate);
                    if (new Date(aFormatted).getTime() > new Date(bFormatted).getTime()) return 1;
                    if (new Date(aFormatted).getTime() < new Date(bFormatted).getTime()) return -1;
                    return 0;
                }
            },
            {
                field: 'personalId',
                title: 'Isikukood',
                sortable: true
            },
            {
                field: 'phone',
                title: 'Telefon',
                sortable: true
            },
            {
                field: 'email',
                title: 'E-post',
                sortable: true
            }
        ],
        data: []
    };

    public user: UserDto.User = null;

    public user$: Observable<UserDto.User>;

    public permissions$:Observable<any>;
    public currentPermissions:any = {};

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private datePipe: DatePipe,
        private authService: AuthService,
        private userRes: UserRes,
        private substitutionRes: SubstitutionRes,
        private manageService: ManageService,
        private permissionsService: PermissionsService
    ) {

        this.user$ = manageService.user$;

        this.permissionsService.hasPermission('replacement.manage').then((hasPermission: boolean) => {
            if (hasPermission) {
                this.bsTableOptions.columns.push(
                    {
                        sortable: false,
                        width: '152px',
                        formatter: (value: any, row: any, rowIndex: any) => {
                            return '<button class="btn btn-primary btn-sm edit-link" data-id="' + row.id + '">Muuda</button> <button class="btn btn-primary swal-btn-cancel btn-sm delete-link" data-id="' + row.id + '">Kustuta</button>';
                        },
                    }
                );
            }
        });
    }

    ngOnInit() {

        let $table = jQuery(this.bsTableElement.nativeElement);

        if ($table.length) {

            $table.on('click', '.user-link', (e: any) => {
                e.preventDefault();

                let id: any = jQuery(e.target).data('id');
                if (id) {
                    this.router.navigate(['../', id], {relativeTo: this.route});
                }
                return false;
            });

            $table.on('click', '.edit-link', (e: any) => {
                e.preventDefault();
                let id: any = jQuery(e.target).data('id');
                if (id) {
                    this.router.navigate(['manage-substitution', id], {relativeTo: this.route});
                }
                return false;
            });

            $table.on('click', '.delete-link', (e: any) => {
                e.preventDefault();
                let id: any = jQuery(e.target).data('id');
                if (id) {
                    swal(
                        {
                            title: "Kas oled kindel?",
                            text: 'Asendus kustutatakse.',
                            type: "info",
                            showCancelButton: true,
                            cancelButtonText: "Tühista",
                            cancelButtonClass: "btn-default",
                            confirmButtonText: "Jah",
                            confirmButtonClass: "btn-primary",
                            closeOnConfirm: false,
                        },
                        (isConfirm: any) => {
                            if (isConfirm) {
                                this.manageService.deleteSubstitution(id).then(() => {
                                    this.manageService.loadUser();
                                    swal({
                                        title: "Asendus kustutatud!",
                                        type: "success",
                                        timer: 2000,
                                        showConfirmButton: false
                                    });
                                });
                            }
                        }
                    );
                }


                return false;
            });
        }


        this.user$.pipe(takeUntil(this.ngDestroy)).subscribe((user: UserDto.User) => {
            this.user = user;
            this.permissions$ = this.permissionsService.permissions$;

            this.permissions$.pipe(distinctUntilChanged()).subscribe((permissions:any) => {
                this.currentPermissions = permissions;

                this.substitutionRes.querySubstitutions(null, {userId: user.id, companyId: this.authService.getCurrentUserCompany().id}, null, null).then(
                    (result: UserDto.SubstitutionQueryOutput) => {
                        this.updateUserSubstitutions(result.substitutions);
                    },
                    (error: any) => {
                        if (error.status == 404) {
                            this.updateUserSubstitutions([]);
                            return;
                        }
                        throw error;
                    }
                );
            });
        });
    }

    updateUserSubstitutions(substitutions: UserDto.Substitution[]) {

        this.bsTableControl.load(substitutions.map((s: UserDto.Substitution, index: number) => {
            let substitution = {};
            let personalId = '';
            let phone = '';
            let email = '';
            let allowed = this.currentPermissions['user.access.all-users'] || this.currentPermissions['user.access.company-users'];

            if(s.type == 'SUBSTITUTABLE') {
                substitution = {
                    type: s.type,
                    allowed: allowed,
                    userId: s.substitute.userId,
                    name: s.substitute.name,
                    actingAsName: s.actingAs.name
                };
                personalId = s.substitute.personalCode;
                phone = s.substitute.phone;
                email = s.substitute.email;
            } else if (s.type == 'SUBSTITUTE') {
                substitution = {
                    type: s.type,
                    allowed: allowed,
                    userId: s.actingAs.userId,
                    name: s.substitute.name,
                    actingAsName: s.actingAs.name
                };
                personalId = s.actingAs.personalCode;
                phone = s.actingAs.phone;
                email = s.actingAs.email;
            }
            if(!allowed){
                substitution = {
                    type: s.type,
                    allowed: allowed,
                    name: s.substitute.name,
                    actingAsName: s.actingAs.name
                };
            }

            let period = this.datePipe.transform(s.validFrom, 'dd.MM.yyyy');
            period += ' - ';
            if (s.validTo) {
                period += this.datePipe.transform(s.validTo, 'dd.MM.yyyy');
            }

            return {
                id: s.id,
                substitution: substitution,
                personalId: personalId,
                period: period,
                phone: phone,
                email: email
            };
        }));
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }
}
