<ng-container [formGroup]="formModel">
    <ng-container *ngFor="let emailControl of emailArray.controls; let i = index">
        <div class="input-group m-b-1">
            <input type="text" class="form-control" (blur)="onTouched()" [disabled]="disabled" [formControl]="emailControl" [class.form-control-danger]="isErrorVisible(emailControl) && !emailControl.valid">
            <button class="btn btn-primary swal-btn-info" *ngIf="i == 0" (click)="addEmailField()"><em class="fa fa-plus"></em></button>
            <button class="btn btn-secondary swal-btn-info m-t-2" *ngIf="i != 0" (click)="removeEmail(i)"><em class="fa fa-minus"></em></button>
        </div>
        <div *ngIf="isErrorVisible(emailControl) && emailControl.errors?.required" class="form-control-feedback text-danger">{{errorMessage.required}}</div>
        <div *ngIf="isErrorVisible(emailControl) && emailControl.value && emailControl.errors?.email" class="form-control-feedback text-danger">{{errorMessage.email}}</div>
    </ng-container>
</ng-container>
