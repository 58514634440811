import { Component, Inject, forwardRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../core/services/auth.service';

import { UserRes } from '../../core/resource/user.resource';
import {AppComponent} from "../../app.component";

declare let swal: any;

@Component({
    templateUrl: './gdpr.component.html'
})
export class GDPRComponent {

    private readonly _returnUrl: string;
    private _acceptChecked: boolean = false;

    public constructor(
      @Inject(forwardRef(() => AppComponent))
      private _app: AppComponent,
      private _router: Router,
      private _route: ActivatedRoute,
      public _authService: AuthService, private _userRes: UserRes) {

        this._returnUrl = _route.snapshot.queryParams['returnUrl'] || '/';

        if (this._authService.getCurrentUser().gdprAccepted) {
            this._userRes.acceptGDPR({userId: this._authService.getCurrentUser().id}).then(() => {
                this._authService.getCurrentUser().gdprAccepted = true;
                swal({
                    title: "Nõustumine on antud",
                    type: "success",
                    timer: 1000,
                    showConfirmButton: false
                }, () => {
                    swal.close();
                    this.redirect();
                })
            }, _ => {
                swal({
                    title: "Ootamatu viga, võtke ühendust administraatoriga.",
                    type: "error",
                    timer: 5000,
                    showConfirmButton: false
                });
            });
        }
    }

    public accept(): void {
        if (this._acceptChecked) {
            this._userRes.acceptGDPR({userId: this._authService.getCurrentUser().id}).then(() => {
                this._authService.getCurrentUser().gdprAccepted = true;

                swal({
                    title: "Nõustumine salvestatud",
                    type: "success",
                    timer: 3000,
                    showConfirmButton: false
                }, () => {
                    swal.close();
                    this.redirect();
                })
            }, error => {
                swal({
                    title: "Ootamatu viga, võtke ühendust administraatoriga.",
                    type: "error",
                    timer: 5000,
                    showConfirmButton: false
                });
            });
        }
    }

    private redirect(): void {
        if (this._returnUrl.startsWith("http")) {
            window.location.assign(this._returnUrl);
        } else {
            this._router.navigateByUrl(this._returnUrl);

            setTimeout(() => { this._app.checkCurrentUserRepresentative() }, 300);
        }
    }
}
