import {Injectable, OnDestroy} from "@angular/core";
import {UserRes} from "@app/core/resource/user.resource";
import {UserDto} from "@app/core/resource-dto/user";
import {ReplaySubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {AuthService} from "@app/core/services/auth.service";
import LargeClientManagerUser = UserDto.LargeClientManagerUser;
import {HttpStatusCode} from "@angular/common/http";

@Injectable()
export class LargeClientManagerAdapter implements OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();
    private readonly optionsSource = new ReplaySubject<any[]>(1);
    private readonly largeClientManagerAdapterCallerSource = new ReplaySubject<string>(1);
    public readonly options$ = this.optionsSource.asObservable();

    public largeClientManagerUsers: UserDto.User[];
    public largeClientManagerOptions: LargeClientManagerUser[] = [];

    constructor(
        private userRes: UserRes,
        private authService: AuthService,
    ) {
        this.largeClientManagerAdapterCallerSource.pipe(takeUntil(this.ngDestroy)).subscribe(_ => {
            this.getLargeClientManagerUsers();
        });
    }

    public ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public getLargeClientManagerUsers() {
        let queryInput: UserDto.QueryInput = new UserDto.QueryInput();
        queryInput.companyId = this.authService.getCurrentUserCompany().id;
        queryInput.roleCodes = ['rkas-large-client-manager'];
        queryInput.limit = 50;

        this.userRes.query(queryInput).then(
            (queryOutput: UserDto.QueryOutput) => {
                this.largeClientManagerUsers = queryOutput.users;

                let rows: Array<LargeClientManagerUser> = this.largeClientManagerUsers.map(dto => {
                    return {
                        id: dto.id,
                        name: dto.forename + ' ' + dto.surname,
                    };
                });

                rows.forEach(r => {
                    this.largeClientManagerOptions.push(r);
                })

                this.optionsSource.next(this.largeClientManagerOptions);

            },
            (error: any) => {
                if (error.status == HttpStatusCode.NotFound) {
                    this.largeClientManagerOptions = [];
                    return;
                }
                throw error;
            });
    }
}
