import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {AuthService} from "@app/core/services/auth.service";
import {ManageService} from "../manage.service";
import {ClassifierService} from "@app/core/services/classifier.service";
import {ClassifierDto} from "@app/core/resource-dto/classifier";
import {RoleDto} from "@app/core/resource-dto/role";


@Component({
    selector: 'role-form',
    templateUrl: './form.component.html',
    styleUrls: [
        '../../../assets/styles/pages/editor.less',
        '../../../assets/styles/pages/project.less'
    ]
})
export class FormComponent implements OnInit {
    @Input('role')
    public role: RoleDto.Role = null;

    public showFormErrors: boolean = false;
    protected isSaveInProgress: boolean = false;

    public roleTypes: any[] = [];

    public formErrors: any = {};

    public formFieldErrors = {
        code: <any>null,
        name: <any>null,
        roleType: <any>null,
    };

    protected validationMessages = {
        'code': {
            'required': 'Palun sisesta rolli kood.',
        },
        'name': {
            'required': 'Palun sisesta nimi.',
        },
        'roleType': {
            'required': 'Palun vali rolli tüüp.',
        },
    };

    formGroup: FormGroup;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private manageService: ManageService,
        private classifierService: ClassifierService,
    ) {
        this.formGroup = this.formBuilder.group({
            'code': ['', [Validators.required]],
            'name': ['', [Validators.required]],
            'roleType': ['', [Validators.required]],
            'basisOfAssignRequired': false
        });

        this.classifierService.classifierSource$.subscribe((classifierMap: Map<string, ClassifierDto.Classifier>) => {
            let roleTypeClassifier: ClassifierDto.Classifier = this.classifierService.getClassifierByCode('ROLE_TYPE');
            this.roleTypes = roleTypeClassifier.values.map(item => {
                return {
                    id: item.code,
                    text: item.name
                }
            });
        });
    }

    ngOnInit() {
        if (!this.role) return;

        this.formGroup.patchValue({
            'code': this.role.code,
            'name': this.role.name,
            'roleType': this.role.roleType ? this.role.roleType : null,
            'basisOfAssignRequired': this.role.basisOfAssignRequired
        });

        this.formGroup.valueChanges.subscribe((formModel: any) => this.onFormValueChanged(formModel));
    }

    isInvalid(field: string) {
        const control = this.formGroup.get(field);
        return control.invalid && (this.showFormErrors);
    }

    onFormValueChanged(formModel?: any) {
        if (!this.formGroup) return;
        const form = this.formGroup;

        for (const field in this.formFieldErrors) {
            this.formFieldErrors[field] = null;
            const control = form.get(field);

            if (control && (control.dirty || this.showFormErrors) && !control.valid) {
                const messages = this.validationMessages[field];

                this.formFieldErrors[field] = control.errors;

                for (const key in control.errors) {
                    this.formFieldErrors[field][key] = messages[key];
                }
            }
        }

        this.formErrors = {};
        if (this.formGroup.valid) {
            this.showFormErrors = false;
        } else {
            this.formErrors['invalid'] = true;
        }
    }

    prepareInputDtoForSubmit(): RoleDto.RoleInput {
        const formModel = this.formGroup.value;

        let dto: RoleDto.RoleInput = new RoleDto.RoleInput();

        dto.code = formModel.code;
        dto.name = formModel.name;
        dto.basisOfAssignRequired = formModel.basisOfAssignRequired;

        return dto;
    }

    setAsTouched(group: FormGroup | FormArray) {
        group.markAsTouched({onlySelf: true});
        group.markAsDirty({onlySelf: true});
        for (let i in group.controls) {
            if (group.controls[i] instanceof FormControl) {
                group.controls[i].markAsTouched({onlySelf: true});
                group.controls[i].markAsDirty({onlySelf: true});
            } else {
                this.setAsTouched(group.controls[i]);
            }
        }
    }

    btnDismissErrorsClicked() {
        this.showFormErrors = false;
    }

    btnSaveClicked(e: any, undelete: boolean = false) {
        if (this.isSaveInProgress) return;
        const formModel = this.formGroup.value;

        this.setAsTouched(this.formGroup);

        this.formGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

        if (this.formGroup.valid) {
            this.isSaveInProgress = true;

            let dto: RoleDto.RoleInput = this.prepareInputDtoForSubmit();
            dto.id = this.role.id;

            if (dto.id) {
                dto.roleType = this.role.roleType

                this.manageService.updateRole(dto, undelete).then(
                    (role: RoleDto.Role) => {
                        this.role = role;
                        this.router.navigate(['..'], {relativeTo: this.route});
                    },
                    (error: any) => {
                        this.isSaveInProgress = false;
                        if (error.status == 409 && error.body && error.body.errorCode == 'role_already_exists') {
                            this.formErrors['role_already_exists'] = true;
                        }
                    }
                );
            } else {
                dto.roleType = formModel.roleType ? formModel.roleType.id : null;

                this.manageService.saveRole(dto).then(
                    (role: RoleDto.Role) => {
                        this.manageService.setRole(role);
                        this.router.navigate(['..'], {relativeTo: this.route});
                    },
                    (error: any) => {
                        this.isSaveInProgress = false;
                        if (error.status == 409 && error.body && error.body.errorCode == 'role_already_exists') {
                            this.formErrors['role_already_exists'] = true;
                        }
                    }
                );
            }
        } else {
            this.showFormErrors = true;
            window.scrollTo(0, 0);
        }
    }
}
