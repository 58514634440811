import {Component, OnDestroy, ViewChild} from '@angular/core';

import {ActivatedRoute, Router} from "@angular/router";
import {FormComponent} from "../form/form.component";
import {ManageService} from "../manage.service";
import {Subject} from "rxjs";
import {RoleDto} from "@app/core/resource-dto/role";
import {takeUntil} from "rxjs/operators";

declare let jQuery:any;

@Component({
    templateUrl: './edit.component.html',
    providers: [ ManageService ],
    styleUrls: [
        '../../../assets/styles/pages/editor.less',
        '../../../assets/styles/pages/project.less'
    ]
})
export class RoleEditComponent implements OnDestroy {
    private ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(FormComponent)
    formComponent:FormComponent;

    public role$ = this.manageService.role$;

    public btnUndeleteEnabled: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private manageService: ManageService
    ) {
        this.route.params.pipe(takeUntil(this.ngDestroy)).subscribe(params => {
            this.manageService.loadRole(params['roleId'])
        });

        this.manageService.role$.pipe(takeUntil(this.ngDestroy)).subscribe((role: RoleDto.Role) => {
            this.btnUndeleteEnabled = role.deletedAt && true;
        });
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    btnSaveClicked(e:any) {
        this.formComponent.btnSaveClicked(e);
    }

    btnSaveAndUndelete(e:any) {
        this.formComponent.btnSaveClicked(e, true);
    }
}
