import {Injectable} from '@angular/core';

import {AuthService} from "../core/services/auth.service";
import {RoleDto} from "../core/resource-dto/role";
import {RoleRes} from "../core/resource/role.resource";
import {ReplaySubject} from "rxjs";

@Injectable()
export class ManageService {
    protected roleSource = new ReplaySubject<RoleDto.Role>(1);

    role$ = this.roleSource.asObservable();

    private role: RoleDto.Role;

    constructor(
        private authService: AuthService,
        private roleRes: RoleRes

    ) {
        this.role$.subscribe((role: RoleDto.Role) => {
            this.role = role;
        })
    }

    loadRole(roleId?: number) {
        this.roleRes.get(null, null, {roleId: roleId}).then((role: RoleDto.Role) => {
            this.setRole(role);
        });
    }

    setRole(role: RoleDto.Role) {
        this.roleSource.next(role);
    }

    saveRole(input: RoleDto.RoleInput) {
        return this.roleRes.save(input);
    }

    updateRole(input: RoleDto.RoleInput, undelete: boolean) {
        return this.roleRes.update(input, {undelete: undelete}, {roleId: input.id});
    }
}
