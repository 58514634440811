import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserDto} from '../../core/resource-dto/user';
import {ManageService} from '../manage/manage.service';
import {RoleAdapter} from '../form/role-adapter';
import {CompanyAdapter} from '../form/company-adapter';
import {UserCompanyFormComponent} from '../form/user-company-form.component';
import {CountryAdapter} from '../../core/services/country-adapter';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: './company-edit.component.html',
    providers: [CountryAdapter, RoleAdapter, CompanyAdapter],
    styleUrls: [
        '../../../assets/styles/pages/editor.less',
        '../../../assets/styles/pages/project.less'
    ],
})
export class CompanyEditComponent implements OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(UserCompanyFormComponent, {static: true})
    formComponent: UserCompanyFormComponent;

    public user: UserDto.User = null;
    public userCompany: UserDto.Company = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private companyAdapter: CompanyAdapter,
        private roleAdapter: RoleAdapter,
        private manageService: ManageService
    ) {
        this.companyAdapter.loadCompanies();
        this.roleAdapter.loadRoles();

        manageService.user$.pipe(takeUntil(this.ngDestroy)).subscribe((user: UserDto.User) => {
            this.user = user;
        });

        combineLatest([manageService.user$, this.route.params]).pipe(takeUntil(this.ngDestroy)).subscribe(([user, params]) => {
            if (!user) return;
            this.manageService.getUserCompany(user.id, params.companyId).then(
                (company: UserDto.Company) => {
                    this.userCompany = company;
                    if (!this.userCompany) {
                        this.router.navigate(['../..'], {relativeTo: route});
                    }
                },
                (_: any) => {
                    this.router.navigate(['../..'], {relativeTo: route});
                }
            );
        });
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    btnSaveClicked(e: any) {
        this.formComponent.save();
    }

    formSaved(_: any) {
        this.router.navigate(['../..'], {relativeTo: this.route}).then(_ => {
        });
    }
}
