import {Injectable, OnDestroy} from "@angular/core";
import {UserDto} from "@app/core/resource-dto/user";
import {UserRes} from "@app/core/resource/user.resource";
import {AuthService} from "@app/core/services/auth.service";
import {ReplaySubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Injectable()
export class LargeClientUsersService implements OnDestroy {

    private ngDestroy: Subject<void> = new Subject<void>();
    protected userCompaniesSource = new ReplaySubject<UserDto.Company[]>(1);
    public readonly companies$ = this.userCompaniesSource.asObservable();
    private companies: UserDto.Company[];

    constructor(
        private userRes: UserRes,
        private authService: AuthService,
    ) {
        this.companies$.pipe(takeUntil(this.ngDestroy)).subscribe((companies: UserDto.Company[]) => {
            this.companies = companies;
        })
    }

    public ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public getUserCompanies(userId: number) {
        this.userRes.queryCompany({userId: userId}, {companyId: this.authService.getCurrentUserCompany().id}, null, null).then(
            (result: UserDto.CompanyQueryOutput) => {
                this.userCompaniesSource.next(result.companies);
            }
        );
    }
}
