<header class="section-header">
    <div class="tbl">
        <div class="tbl-row">
            <div class="tbl-cell">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active">Rollid</li>
                </ol>
            </div>
        </div>
    </div>
</header>

<section class="box-typical toolbar">
    <header class="box-typical-header box-typical-header-border">
        <div class="tbl-row">
            <div class="tbl-cell tbl-cell-title">
                <h3>Rollid</h3>
            </div>
            <div class="tbl-cell tbl-cell-action-bordered" *ngxPermissionsOnly="'role.manage'">
                <button type="button" class="action-btn no-wrap" [routerLink]="['add']">
                    <i class="font-icon font-icon-plus"></i>&nbsp;Lisa&nbsp;roll
                </button>
            </div>
        </div>
    </header>

    <div id="toolbarRole">
        <div class="row collapse show" [formGroup]="filterFormGroup">
            <div class="col">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="isDeleted" formControlName="isDeleted">
                    <label class="form-check-label" for="isDeleted">Kustutatud rollid</label>
                </div>
            </div>
        </div>
    </div>

    <div class="box-typical-body">
        <table class="table table-striped"
               data-filter-control="false"
               data-show-toggle="false"
               data-show-export="false"
               data-detail-view="false"
               data-show-pagination-switch="false"
               data-minimum-count-columns="2"
               data-pagination="true"
               data-id-field="id"
               data-show-footer="false"
               data-search="true"
               data-show-refresh="true"
               data-show-columns="true"
               bs-table-control [bs-table-options]="this.bsTableOptions" #bsTableElement
        >
            <thead>
                <tr>
                    <th data-field="name" data-sortable="true" data-filter-control="input">Nimi</th>
                    <th data-field="code" data-sortable="true" data-filter-control="input">Kood</th>
                    <th data-field="roleType" data-sortable="true" data-filter-control="input">Rolli tüüp</th>
                </tr>
            </thead>
            <tbody></tbody>
        </table>
    </div>
</section>
