import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "./core/services/auth.service";
import {UserLoginDto} from "./core/resource-dto/user-login";
import {Subject} from "rxjs";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import swal from "sweetalert";

@Component({
    selector: 'app-representative-modal',
    templateUrl: './representative-modal.component.html',
})
export class RepresentativeModalComponent implements OnDestroy {

    private ngDestroy: Subject<void> = new Subject<void>();

    public currentUser:UserLoginDto.User = null;

    private modalRef: BsModalRef;

    @ViewChild('representativeSelectModal', {static: true})
    protected elementRef: ElementRef;

    constructor(
        private _authService: AuthService,
        private modalService: BsModalService
    ) {
        this._authService.user$.pipe(
          distinctUntilChanged(),
          takeUntil(this.ngDestroy)
        ).subscribe((user:UserLoginDto.User) => {
            this.currentUser = user;
            if(this.currentUser == null) this.show();
        });
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public show():void {
        if(this.currentUser.companies.length) {
          this.modalRef = this.modalService.show(this.elementRef);
        } else {
            swal("Teil puudub rakenduse kasutamisõigus, palun pöörduge helpdesk@rkas.ee poole.");
        }
    }

    public btnCompanyClicked(index:number) {
        if(this.currentUser.companies[index]) {
            this._authService.setCurrentCompanyId(this.currentUser.companies[index].id);
        }
        this.modalRef.hide();
    }
}
