import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {RoleAdapter} from "./role-adapter";
import {CompanyAdapter} from "./company-adapter";
import {UserDto} from "@app/core/resource-dto/user";
import {UserRes} from "@app/core/resource/user.resource";
import {Observable, Subject} from "rxjs";
import {ManageService as UserManageService} from "../manage/manage.service";
import {ManageService as CompanyManageService} from "../../company/manage/manage.service";
import {PermissionsService} from "@app/shared/services/permissions.service";
import {CompanyDto} from "@app/core/resource-dto/company";
import {UserAdapter} from "./user-adapter";
import {ActivatedRoute} from "@angular/router";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {JobTitleAdapter} from "@app/user-registry/form/job-title-adapter";
import {HttpStatusCode} from "@angular/common/http";
import {ClassifierService} from "@app/core/services/classifier.service";
import RoleData = UserDto.RoleData;
import {RoleDto} from "@app/core/resource-dto/role";
import {RoleRes} from "@app/core/resource/role.resource";

@Component({
    selector: 'app-user-company',
    templateUrl: './user-company-form.component.html',
})
export class UserCompanyFormComponent implements OnInit, OnChanges, OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();

    @Input()
    public userCompany: UserDto.Company = null;

    public user: UserDto.User = null;
    public company: CompanyDto.Company = null;
    public isNew = true;

    @Output('save')
    protected _save: EventEmitter<any> = new EventEmitter();

    protected isSaveInProgress: boolean = false;
    public formErrors: any = {};
    public showFormErrors: boolean = false;
    public permissions$: Observable<any>;
    public currentPermissions: any = {};
    public basisOfAssignDescription: string = '';

    public form: FormGroup = new FormGroup({
        company: new FormControl(null, [Validators.required]),
        roles: new FormControl([], [Validators.required]),
        user: new FormControl(null, [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        phone: new FormControl('', [Validators.required]),
        jobTitles: new FormControl([], [Validators.required]),
        basisOfAssigns: new FormArray([])
    });

    public constructor(
        private formBuilder: FormBuilder,
        public companyAdapter: CompanyAdapter,
        public userAdapter: UserAdapter,
        public roleAdapter: RoleAdapter,
        public jobTitleAdapter: JobTitleAdapter,
        private userRes: UserRes,
        private userManageService: UserManageService,
        private companyManageService: CompanyManageService,
        private permissionsService: PermissionsService,
        private route: ActivatedRoute,
        private classifierService: ClassifierService,
        private roleRes: RoleRes
    ) {
        this.permissions$ = this.permissionsService.permissions$;
        this.permissions$.pipe(distinctUntilChanged(), takeUntil(this.ngDestroy)).subscribe((permissions: any) => {
            this.currentPermissions = permissions;
        });

        this.route.params.pipe(takeUntil(this.ngDestroy)).subscribe(params => {
            if (params['companyId'] && params['userId'] || params['userId']) {
                this.isNew = false;
            }
        });

        this.mapBasisOfAssignToRoles();

        this.form.get("user").valueChanges.pipe(distinctUntilChanged()).subscribe(user => {
            if (user) {
                this.userManageService.loadUser(user.id);
            }
        });

        this.companyAdapter.options$.pipe(takeUntil(this.ngDestroy)).subscribe((options: any[]) => {
            if (options.length > 1) {
                if (!this.userCompany || !this.userCompany.id) {
                    this.form.get('company').enable();
                }
            } else {
                if (options.length == 1) {
                    this.form.get('company').setValue(options[0]);
                }
                this.form.get('company').disable();
            }
        });
    }

    public mapBasisOfAssignToRoles() {
        this.form.get('roles').valueChanges.pipe(distinctUntilChanged()).subscribe(roles => {
            const required = roles.filter(role => role.basisOfAssignRequired === true);
            required.forEach(requiredRole => {
                const roleData: RoleData = this.form.get('basisOfAssigns').value.find(value => value.id === requiredRole.id);
                if (roleData !== undefined) {
                    requiredRole.basisOfAssign = roleData.basisOfAssign;
                }
            });
        });
    }

    public ngOnInit() {
        this.userManageService.user$.pipe(takeUntil(this.ngDestroy)).subscribe((user: UserDto.User) => {
            this.user = user;
            this.form.get("user").setValue({
                id: user.id,
                text: user.forename + ' ' + user.surname
            }, {emitEvent: false});
            this.form.get('user').setValidators(null);
            this.form.get('user').setErrors(null);
        });

        this.companyManageService.company$.pipe(takeUntil(this.ngDestroy)).subscribe((company: CompanyDto.Company) => {
            this.company = company;
            this.form.patchValue({
                'company': this.company
            });
            this.form.get('company').setValidators(null);
            this.form.get('company').setErrors(null);
        });

        this.classifierService.classifierSource$.pipe(takeUntil(this.ngDestroy)).subscribe(_ => {
            const basisOfAssignClassifier =
                this.classifierService.getClassifierValueByCode('ADDITIONAL_PARAMETER', 'ROLE_ASSIGN_BASIS_DESCRIPTION');
            if (basisOfAssignClassifier) {
                this.basisOfAssignDescription = basisOfAssignClassifier.name
            }
        });

        this.jobTitleAdapter.loadJobTitles();

    }

    public ngOnChanges(changes: any) {
        if (changes['userCompany']) {
            this.resetFormValue();
        }
    }

    public ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    get basis() {
        return this.form.get('basisOfAssigns') as FormArray;
    }

    public onRoleRemove(event: any) {
        if (event.value.basisOfAssignRequired) {
            const remove = this.basis.controls.map((value, index) => {
                return {index: index, id: value.value.id};
            }).filter(v => v.id == event.value.id);

            this.basis.removeAt(remove[0].index);
        }
    }

    public onRoleAdd(event: any) {
        if (event.basisOfAssignRequired) {
            const data = this.formBuilder.group({
                basisOfAssign: new FormControl(
                    event.basisOfAssign, [Validators.required, Validators.pattern('https://jira.rkas.ee/browse/SD-' + '\\d{4,5}')]
                ),
                name: new FormControl(event.text),
                id: new FormControl(event.id)
            });
            this.basis.push(data);
        }
    }

    public updateRoleDto(inputRole: UserDto.RoleData) {
        const formModel = this.form.getRawValue();

        formModel.roles.filter((role) => {
            if (role.text === inputRole.name) {
                role.basisOfAssign = inputRole.basisOfAssign
            }
        });
    }

    public hasErrors(field) {
        return field.invalid && (field.dirty || field.touched);
    }

    public hasInputErrors(field: AbstractControl): boolean {
        return field.invalid && (field.dirty || field.touched);
    }

    public btnDismissErrorsClicked() {
        for (const field in this.formErrors) {
            this.formErrors[field] = null;
        }

        this.showFormErrors = false;
    }

    public resetFormValue() {
        let formValue: any = this.prepareFormModel();

        this.getInitialBasisOfAssignRequiredRoles();

        formValue.basisOfAssigns.map(requiredRole => {
            const data = this.formBuilder.group({
                basisOfAssign: new FormControl(
                    requiredRole.basisOfAssign, [Validators.required, Validators.pattern('https://jira.rkas.ee/browse/SD-' + '\\d{4,5}')]
                ),
                name: new FormControl(requiredRole.text),
                id: new FormControl(requiredRole.id)
            });
            this.basis.push(data);
        })

        this.form.setValue(formValue);

        if (this.userCompany && this.userCompany.id) {
            this.form.get('company').disable();
        }
    }

    public save() {
        if (this.isSaveInProgress) return;

        this.form.updateValueAndValidity();
        this.form.markAllAsTouched();

        if (!this.currentPermissions['user.access.company-role-assign-basis'] && !this.form.get('basisOfAssigns').value) {
            this.formErrors['basis_of_assign_required'] = true
        }

        if (this.form.valid) {
            this.isSaveInProgress = true;
            let companyInput: UserDto.CompanyInput = this.prepareInputDtoForSubmit();
            if (this.userCompany.id) {
                this.userManageService.updateCompany(companyInput, this.userCompany.companyId).then(
                    (company: UserDto.Company) => {
                        this.isSaveInProgress = false;
                        this.userManageService.loadUser();
                        this._save.emit(true);
                    },
                    (error: any) => {
                        this.isSaveInProgress = false;
                        if (error.status == HttpStatusCode.Conflict && error.body && error.body.errorCode == 'user_company_already_exists') {
                            this.formErrors['user_company_already_exists'] = true;
                        }
                    }
                );
            } else {
                this.userManageService.saveCompany(companyInput).then(
                    (company: UserDto.Company) => {
                        this.isSaveInProgress = false;
                        this.userManageService.loadUser();
                        this._save.emit(true);
                    },
                    (error: any) => {
                        this.isSaveInProgress = false;
                        if (error.status == HttpStatusCode.Conflict && error.body && error.body.errorCode == 'user_company_already_exists') {
                            this.formErrors['user_company_already_exists'] = true;
                        }
                    }
                );
            }
        } else {
            this.showFormErrors = true;
            window.scrollTo(0, 0);
        }
    }

    private getInitialBasisOfAssignRequiredRoles() {
        if (!!this.userCompany?.companyId) {
            this.roleRes.query({
                companyId: this.userCompany.companyId,
                isDeleted: false
            }).then((rolesQueryOutput: RoleDto.QueryOutput) => {
                const basisOfAssignRequiredRoles = rolesQueryOutput.roles.filter(role => role.basisOfAssignRequired).map(r => r.id);
                const requiredRole = this.userCompany.roles.filter(val => basisOfAssignRequiredRoles.includes(val.roleId))
                const isBasisOfAssignFilled = requiredRole.filter(r => !r.basisOfAssign);

                if (isBasisOfAssignFilled.length > 0) {
                    isBasisOfAssignFilled.forEach(role => {
                        const data = this.formBuilder.group({
                            basisOfAssign: new FormControl(
                                role.basisOfAssign, [Validators.required, Validators.pattern('https://jira.rkas.ee/browse/SD-' + '\\d{4,5}')]
                            ),
                            name: new FormControl(role.name),
                            id: new FormControl(role.roleId)
                        });
                        this.basis.push(data);
                    })
                }
            });
        }
    }

    private prepareFormModel() {
        let userCompany: UserDto.Company = this.userCompany;

        if (!userCompany) return {
            company: null,
            user: null,
            roles: [],
            email: '',
            phone: '',
            jobTitles: [],
        };

        this.roleAdapter.updateSource();
        let valueMap: any = {
            company: userCompany.companyId ? {id: userCompany.companyId, text: userCompany.name} : null,
            user: this.user,
            roles: userCompany.roles.map((r: UserDto.RoleData) => ({
                id: r.roleId,
                text: r.name,
                basisOfAssign: r.basisOfAssign,
            })),
            email: userCompany.email,
            phone: userCompany.phone,
            jobTitles: userCompany.jobTitles.map((t: UserDto.JobTitle) => ({id: t.id, text: t.name})),
            basisOfAssigns: userCompany.roles.filter(role => role.basisOfAssign !== null).map(requiredRole => ({
                id: requiredRole.roleId,
                name: requiredRole.name,
                basisOfAssign: requiredRole.basisOfAssign
            }))
        };

        return valueMap;
    }

    private prepareInputDtoForSubmit(): UserDto.CompanyInput {
        const formModel = this.form.getRawValue();

        let dto: UserDto.CompanyInput = new UserDto.CompanyInput();

        dto.companyId = formModel.company.id;
        dto.email = formModel.email;
        dto.phone = formModel.phone;
        let roles: UserDto.RoleData[] = [];
        formModel.roles.forEach((role: any) => {
            let roleDto = new UserDto.RoleData;
            roleDto.id = role.id;
            roleDto.basisOfAssign = role.basisOfAssign;
            roles.push(roleDto);
        });
        dto.roles = roles;
        dto.jobTitles = [];
        if (formModel.jobTitles.length > 0) {
            for (const title of formModel.jobTitles) {
                let jobDto = new UserDto.JobTitle();
                jobDto.id = title.id;
                jobDto.name = title.text;
                dto.jobTitles.push(jobDto);
            }
        }

        return dto;
    }
}
