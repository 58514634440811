<form [formGroup]="form">
    <div class="alert alert-danger" role="alert" *ngIf="formErrors['invalid'] && showFormErrors">
        <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
            <span aria-hidden="true">&times;</span>
        </button>
        Vormil esineb vigu!
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="formErrors['personal_code_exists']">
        <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
            <span aria-hidden="true">&times;</span>
        </button>
        Antud isikukoodiga kasutaja juba on olemas! Palun pöörduge <a
        href="mailto:helpdesk@rkas.ee">helpdesk@rkas.ee</a> poole.
    </div>
    <div class="box-typical-body" style="padding:0px 5px 0px 15px" [formGroup]="form">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="form-group row" style="padding-bottom: 16px">
                    <label class="col-sm-2 form-control-label">Riik</label>
                    <div class="col-sm-10">
                        <ng-select formControlName="country" bindLabel="text"
                                   [items]="countryAdapter.options$ | async"
                                   [typeahead]="countryAdapter.querySource"
                                   [class.is-invalid]="hasErrors(form.get('country'))"
                        ></ng-select>
                        <ng-container *ngIf="hasErrors(form.get('country'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('country').errors?.required">
                                Palun vali riik.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">Eesnimi</label>
                    <div class="col-sm-10">
                        <input [tabindex]="3" type="text" class="form-control"
                               [class.is-invalid]="hasErrors(form.get('forename'))"
                               id="eesnimi" formControlName="forename">
                        <ng-container *ngIf="hasErrors(form.get('forename'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('forename').errors?.required">
                                Palun sisesta eesnimi.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row" formArrayName="phoneNumbers">
                    <ng-container *ngFor="let userPhoneNumber of phoneNumbers.controls; let index = index;"
                         [formGroupName]="index">
                        <label class="col-sm-2 form-control-label" for="phone">Telefon {{index > 0 ? index + 1 : ''}}</label>
                        <div class="col-sm-10" [ngClass]="index > 0 ? 'mt-1' : ''">
                            <input class="form-control"
                                   id="phone"
                                   formControlName="phoneNumber"
                                   [class.is-invalid]="hasErrors(form.get(['phoneNumbers', index]))"
                            >
                            <button *ngIf="index > 0" (click)="removePhoneNumber(index)"
                                    class="remove-phone" type="button">X
                            </button>
                        </div>
                    </ng-container>
                    <button (click)="addPhoneNumber()" type="button" class="phone-number">+ Lisa telefoninumber
                    </button>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">Sugu</label>
                    <div class="col-sm-10">
                        <ng-select formControlName="gender"
                                   [class.is-invalid]="hasErrors(form.get('gender'))">
                            <ng-option value="M">Mees</ng-option>
                            <ng-option value="N">Naine</ng-option>
                        </ng-select>
                        <ng-container *ngIf="hasErrors(form.get('gender'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('gender').errors?.required">
                                Palun sisesta sugu.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">Allikas</label>
                    <div class="col-sm-10">
                        <ng-select *ngxPermissionsOnly="['user.manage.is-external']; else elseBlock"
                                   formControlName="isExternal"
                                   bindLabel="value"
                        >
                            <ng-option value="true">Väline</ng-option>
                            <ng-option value="false">Sisene</ng-option>
                        </ng-select>
                        <ng-template #elseBlock>
                            <input [tabindex]="7"
                                   type="text"
                                   readonly
                                   class="form-control-plaintext"
                                   value="{{isExternalUser ? 'Väline' : 'Sisene'}}"
                            >
                        </ng-template>
                    </div>
                </div>
                <div class="form-group row" [hidden]="!fieldSapCostCenterEnabled">
                    <label class="col-sm-2 form-control-label">SAP kood</label>
                    <div class="col-sm-10">
                        <input [tabindex]="9" type="text" class="form-control"
                               [class.is-invalid]="hasErrors(form.get('sapCostCenter'))"
                               formControlName="sapCostCenter" maxlength="3">
                        <ng-container *ngIf="hasErrors(form.get('sapCostCenter'))">
                            <small class="invalid-feedback d-block m-0"
                                   *ngIf="form.get('sapCostCenter').errors?.maxlength">
                                Lubatud kuni 10 tähemärki.
                            </small>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="form-group row align-items-center" *ngIf="isPersonalCodeFilled">
                    <label class="col-sm-2 form-control-label">Ilma isikukoodita</label>
                    <div class="col-sm-10">
                        <input (change)="setPermissionToAddWithoutPersonalCode()" [checked]="addUserWithoutPersonalCode"
                               [ngModel]="addUserWithoutPersonalCode" type="checkbox"
                               formControlName="withoutPersonalCode">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">Isikukood</label>
                    <div class="col-sm-10">
                        <input [tabindex]="2" type="text" class="form-control"
                               [class.is-invalid]="hasErrors(form.get('personalCode'))"
                               id="isikukood" placeholder="Isikukood" formControlName="personalCode">
                        <ng-container *ngIf="hasErrors(form.get('personalCode'))">
                            <small class="invalid-feedback d-block m-0"
                                   *ngIf="form.get('personalCode').errors?.required">
                                Palun sisesta isikukood.
                            </small>
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('personalCode').errors?.valid">
                                Vigane isikukood
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">Perenimi</label>
                    <div class="col-sm-10">
                        <input [tabindex]="4" type="text" class="form-control"
                               [class.is-invalid]="hasErrors(form.get('surname'))"
                               id="perenimi" formControlName="surname">
                        <ng-container *ngIf="hasErrors(form.get('surname'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('surname').errors?.required">
                                Palun sisesta perenimi.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">Sünniaeg</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" bsDatepicker formControlName="birthday"
                               [class.is-invalid]="hasErrors(form.get('birthday'))"
                               [bsConfig]="{ dateInputFormat: 'DD.MM.YYYY' }">
                        <ng-container *ngIf="hasErrors(form.get('birthday'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('birthday').errors?.required">
                                Palun sisesta sünnikuupäev.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label-label">E-post</label>
                    <div class="col-sm-10">
                        <input [tabindex]="6" type="text" class="form-control"
                               [class.is-invalid]="hasErrors(form.get('email'))"
                               id="epost1" formControlName="email">
                        <ng-container *ngIf="hasErrors(form.get('email'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('email').errors?.required">
                                Palun sisesta e-posti aadress.
                            </small>
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('email').errors?.valid">
                                Palun sisesta korrektne e-posti aadress.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">Lisainfo</label>
                    <div class="col-sm-10">
                        <input [tabindex]="8" type="text" class="form-control" id="lisainfo" formControlName="info">
                    </div>
                </div>
                <div class="form-group row" *ngxPermissionsOnly="['user.manage.large-client-manager-value']">
                    <label class="col-sm-2 form-control-label">Suurkliendihaldur</label>
                    <div class="col-sm-10">
                        <ng-select formControlName="largeClientManagerUser" bindLabel="name"
                                   [class.is-invalid]="hasErrors(form.get('largeClientManagerUser'))"
                                   [items]="largeClientManagerAdapter.options$ | async">
                        </ng-select>
                        <ng-container>
                            <div class="alert alert-danger" role="alert"
                                 *ngIf="formErrors['adding_large_client_manager_user_not_allowed']">
                                <button type="button" class="close" aria-label="Close"
                                        (click)="btnDismissErrorsClicked()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                Puuduvad õigused suurkliendihalduri lisamiseks.
                            </div>
                            <div class="alert alert-danger" role="alert"
                                 *ngIf="formErrors['invalid_large_client_manager_user_id']">
                                <button type="button" class="close" aria-label="Close"
                                        (click)="btnDismissErrorsClicked()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                Suurkliendihalduriks lisatud kasutajal puudub suurkliendihalduri roll.
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="formErrors['manage_user_not_allowed']">
                                <button type="button" class="close" aria-label="Close"
                                        (click)="btnDismissErrorsClicked()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                Kasutaja muutmine pole lubatud
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
