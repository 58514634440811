import { Injectable } from '@angular/core';
import {AuthService} from "@app/core/services/auth.service";
import {UserRes} from "@app/core/resource/user.resource";
import {UserDto} from "@app/core/resource-dto/user";

import * as lnr from 'elasticlunr';
import {QueryAdapter} from "@app/shared/QueryAdapter";

@Injectable()
export class UserAdapter extends QueryAdapter{

    constructor(
        private userRes:UserRes,
        private authService:AuthService
    ) { super() }

    loadUsers() {
        let queryInput: UserDto.QueryInput = new UserDto.QueryInput();
        queryInput.companyId = this.authService.getCurrentUserCompany().id;
        queryInput.limit = 99999;
        this.userRes.query(queryInput).then((userQueryOutput: UserDto.QueryOutput) => {
            this.updateMap(userQueryOutput.users);
            this.updateSearchIndex();
            this.updateSource();
        });
    }

    loadLargeClientManagers() {
      let queryInput: UserDto.QueryInput = new UserDto.QueryInput();
      queryInput.roleCodes = ['rkas-large-client-manager']
      queryInput.limit = 99999;
      this.userRes.query(queryInput).then((userQueryOutput: UserDto.QueryOutput) => {
        this.updateMap(userQueryOutput.users);
        this.updateSearchIndex();
        this.updateSource();
      });
    }

    loadSubstitutionOptions() {
        this.userRes.querySubstitutionOptions({companyId: this.authService.getCurrentUserCompany().id}).then((userQueryOutput: UserDto.QueryOutput) => {
            this.updateMap(userQueryOutput.users);
            this.updateSearchIndex();
            this.updateSource();
        });
    }

    updateMap(users:UserDto.User[]) {
        this.map = new Map<number, any>(users.map((user:any) => [user.id, user] as [number, any]));
    }

    updateSearchIndex() {
        this.index = new lnr.Index;

        this.index.pipeline.reset();
        this.index.pipeline.add(lnr.trimmer);

        this.index.addField('text');
        this.index.setRef('id');

        this.map.forEach((user: any) => {this.index.addDoc({id: user.id, text: user.forename +' '+ user.surname })});
    }

    updateSource() {
        this.dataSource.next(this.map);

        let options:any[] = [];
        this.map.forEach((user: any) => {options.push({id: user.id, text: user.forename +' '+ user.surname })});
        this.optionsSource.next(options);
    }

    query(term:string):Array<any> {
        if(!this.index) return [];

        let results: Array<any> = [];
        let lunarResults: any = this.index.search(term, {expand: true, bool: 'AND'});
        lunarResults.forEach((result:any) => {
            let user:any = null;
            if(this.map) user = this.map.get(+result.ref);
            if(user) {
                results.push({id: user.id, text: user.forename +' '+ user.surname});
            }
        });
        this.optionsFilteredSource.next(results);
        return results;
    }
}
