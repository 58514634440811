import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../services/auth.service";
import {UserLoginDto} from "../resource-dto/user-login";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

declare let jQuery:any;

@Component({
    selector: 'representative-select',
    templateUrl: './representative-select.component.html',
    host: {'class': 'dropdown user-menu'}
})
export class RepresentativeSelectComponent implements OnInit, OnDestroy {
    private ngDestroy: Subject<void> = new Subject<void>();

    public currentUser:UserLoginDto.User = null;
    public currentUserCompany:UserLoginDto.UserCompany = null;

    constructor(
        private _authService:AuthService
    ) {
        this._authService.user$.pipe(takeUntil(this.ngDestroy)).subscribe((user:UserLoginDto.User) => {
            this.currentUser = user;
        });

        this._authService.userCompany$.pipe(takeUntil(this.ngDestroy)).subscribe((userCompany:UserLoginDto.UserCompany) => {
            this.currentUserCompany = this._authService.getCurrentUserCompany();
        });
    }

    public ngOnInit() { }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public btnCompanyClicked(index:number) {
        let userCompany:UserLoginDto.UserCompany = this.currentUser.companies[index];
        if(userCompany) {
            this._authService.setCurrentCompanyId(userCompany.id);
        }
    }

    public btnLogoutClicked($event:any) {
        this._authService.redirectToCasLogout();
    }
}
