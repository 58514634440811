import {Inject, Injectable} from '@angular/core';
import packageInfo from '../../../package.json';
import {CoreConfig} from "../core/core-config.model";
import {UR_CORE_CONFIG} from "@app/core/injection-tokens";

@Injectable({providedIn: 'root'})
export class ConfigService {

    static VERSION: any = packageInfo.version;

    constructor(@Inject(UR_CORE_CONFIG) private config: CoreConfig) { }

    public get(key: string): any {
      return this.config[key];
    }

    public getBackendUrl(endpoint: string): string {
        return this.get('backendUrl') + endpoint;
    }

    public getCoreAppUrl(appKey:string, path:string): string {
        if(this.get('coreAppUrls')[appKey]) {
            return this.get('coreAppUrls')[appKey] + path;
        }
        return null;
    }

    public getVersion() {
        return ConfigService.VERSION;
    }

    public enablePersonaSync(): boolean {
        return this.get('enablePersonaSync');
    }
}
