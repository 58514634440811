<div class="modal fade test show" id="addRepresentativeRight" role="dialog" aria-hidden="true"
     aria-labelledby="myLargeModalLabel" #addRepresentativeRight [formGroup]="form">
    <div class="modal-dialog">
        <div class="modal-content" style="width: 600px; margin-left: -50px;">
            <div class="modal-header">
                <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                    <i class="font-icon-close-2"></i>
                </button>
                <h4 class="modal-title" id="myModalLabel2">Seotud kasutajate määramine</h4>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <label for="ettevõte" class="col-sm-2 form-control-label">Ettevõte</label>
                    <div class="col-sm-10">
                        <ng-select id="ettevõte" formControlName="company" bindLabel="text"
                                   [tabindex]="1"
                                   [items]="companyAdapter.optionsFiltered$ | async"
                                   [typeahead]="companyAdapter.querySource"
                                   [class.is-invalid]="hasErrors(form.get('company'))"
                        ></ng-select>
                        <ng-container *ngIf="hasErrors(form.get('company'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('company').errors?.required">
                                Palun määra ettevõte.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="rollid" class="col-sm-2 form-control-label">Rollid</label>
                    <div class="col-sm-10">
                        <ng-select id="rollid" formControlName="roles" bindLabel="text"
                                   [tabindex]="2"
                                   [multiple]="true"
                                   (remove)="onRoleRemove($event)"
                                   (add)="onRoleAdd($event)"
                                   [items]="roleAdapter.options$ | async"
                                   [typeahead]="roleAdapter.querySource"
                                   [class.is-invalid]="hasErrors(form.get('roles'))"
                        ></ng-select>
                        <ng-container *ngIf="hasErrors(form.get('roles'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('roles').errors?.required">
                                Palun määra rollid.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">E-post</label>
                    <div class="col-sm-10 pb-0">
                        <input [tabindex]="3" type="text" class="form-control" [defaultValue]=""
                               [class.is-invalid]="hasErrors(form.get('email'))"
                               id="epostUus" placeholder="E-post" formControlName="email">
                        <ng-container *ngIf="hasErrors(form.get('email'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('email').errors?.required">
                                Palun sisesta e-posti aadress.
                            </small>
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('email').errors?.valid">
                                Palun sisesta korrektne e-posti aadress.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">Telefon</label>
                    <div class="col-sm-10">
                        <input type="text"
                               [class.is-invalid]="hasErrors(form.get('phone'))"
                               class="form-control" id="telefon" placeholder="Telefon" formControlName="phone">
                        <ng-container *ngIf="hasErrors(form.get('phone'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('phone').errors?.required">
                                Palun sisesta telefon.
                            </small>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 form-control-label">Ametinimetus</label>
                    <div class="col-sm-10">
                        <ng-select
                            addTag="true"
                            multiple="true"
                            placeholder="Sisesta ametinimetus"
                            addTagText="Lisa ametinimetus"
                            hideSelected="true"
                            formControlName="jobTitles"
                            bindLabel="text"
                            [items]="jobTitleAdapter.options$ | async"
                            [typeahead]="jobTitleAdapter.querySource"
                            [class.is-invalid]="hasErrors(form.get('jobTitles'))"
                        ></ng-select>
                        <ng-container *ngIf="hasErrors(form.get('jobTitles'))">
                            <small class="invalid-feedback d-block m-0" *ngIf="form.get('jobTitles').errors?.required">
                                Palun sisesta ametinimetus.
                            </small>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div
                *ngIf="!!currentPermissions['user.access.company-role-assign-basis'] && basis?.controls.length > 0">
                <div class="modal-header form-group justify-content-start align-items-center">
                    <h4 class="modal-title">Õiguste lisamise alused:
                    </h4>
                    <div class="col-sm-5">
                        <i class="font-icon-question"
                           [tooltip]="basisOfAssignDescription"
                           containerClass="tooltip-basis"
                        ></i>
                    </div>
                </div>
                <div class="form-group row mt-2 pr-3 pl-3" formArrayName="basisOfAssigns"
                     *ngFor="let item of basis.controls; let index = index">
                    <label class="col-sm-2 form-control-label">{{item?.value?.name}}</label>
                    <div class="col-sm-10" [formGroupName]="index">
                        <input
                            class="form-control"
                            formControlName="basisOfAssign"
                            name="basisOfAssign"
                            (change)="updateRoleDto(item?.value)"
                            [class.is-invalid]="hasInputErrors(item)"
                        />
                        <ng-container *ngIf="hasInputErrors(item)">
                            <small class="invalid-feedback d-block m-0"
                                   *ngIf="hasInputErrors(item) && item?.get('basisOfAssign').errors?.required">
                                Palun sisesta õiguse andmise alus.
                            </small>
                            <small class="invalid-feedback d-block m-0"
                                   *ngIf="hasInputErrors(item) && item?.get('basisOfAssign').errors?.pattern">
                                Õiguse andmise alus ei ole õigel kujul. (https://jira.rkas.ee/browse/SD-XXXXX)
                            </small>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="formErrors['basisOfAssignRequired']">
                <button type="button" class="close" aria-label="Close" (click)="btnDismissErrorsClicked()">
                    <span aria-hidden="true">&times;</span>
                </button>
                Puudub õigus lisada kasutajale valitud rolli.
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-rounded btn-default" data-dismiss="modal">Tühista</button>
                <button type="button" class="btn btn-rounded btn-primary" (click)="save()">Salvesta</button>
            </div>
        </div>
    </div>
</div>
