import { Injectable } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { ConfigService } from 'app/config/config.service';

import { AuthGuardedResource } from './auth-guarded.resource';

import { CompanyDto } from '../resource-dto/company';
import {ResourceHandler} from "@ngx-resource/core/src/ResourceHandler";
import {
    IResourceMethod,
    IResourceMethodStrict,
    ResourceAction,
    ResourceParams,
    ResourceRequestMethod
} from "@ngx-resource/core";

@Injectable()
@ResourceParams({
    pathPrefix: '/companies',
    withCredentials: true
})
export class CompanyRes extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl(''));
    }

    @ResourceAction({
        expectJsonArray: false
    })
    query: IResourceMethod<CompanyDto.QueryInput, CompanyDto.QueryOutput>;

    @ResourceAction({
        path: '/{!companyId}'
    })
    get: IResourceMethodStrict<any ,{companyId: any}, {companyId: any}, CompanyDto.Company>;

    @ResourceAction({
        method: ResourceRequestMethod.Post
    })
    save: IResourceMethodStrict<CompanyDto.CompanyInput, {companyId: number}, {}, CompanyDto.Company>;

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/{!companyId}'
    })
    update: IResourceMethodStrict<CompanyDto.CompanyInput, {companyId: number, undelete: boolean}, {companyId: number}, CompanyDto.Company>;

    @ResourceAction({
        path: '/{!companyId}',
        method: ResourceRequestMethod.Delete
    })
    delete: IResourceMethodStrict<{companyId: number}, {companyId: number}, any, void>;
}
