<div class="page-center" *ngIf="!_authService.getCurrentUser().gdprAccepted">
    <div class="page-center-in">
        <div class="container-fluid" style="padding-top: 70px;">
            <section class="box-typical">
                <header class="box-typical-header  box-typical-header-border">
                    <div class="tbl-row">
                        <div class="tbl-cell tbl-cell-title">
                            <h3>Isikuandmete töötlemine Riigi Kinnisvara AS-is</h3>
                        </div>
                    </div>
                </header>
                <section class="proj-page-section">
                    <div class="row">
                        <div class="col-12">
                            <div class="comment-row-item">
                                Riigi Kinnisvara AS kasutab Sinu isikuandmeid toodete ja teenuste pakkumiseks ning kasutajakogemuse parandamiseks. Teenuste kasutamiseks vajame Sinu nõusolekut andmete töötlemiseks.
                            </div>
                        </div>
                    </div>
                </section>
                <div class="table-responsive bootstrap-table">
                    <div class="fixed-table-container">
                        <table id="table" class="table-striped table-sm table table-hover">
                            <thead>
                                <tr>
                                    <th><div class="th-inner">Teenuse nimetus</div></th>
                                    <th><div class="th-inner">Töödeldavad isikuandmed</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>RKAS kinnisvara haldustarkvara</td>
                                    <td>eesnimi, perekonnanimi, isikukood, e-mail, telefoninumber, kodakondsus, asukohaandmed</td>
                                </tr>
                                <tr>
                                    <td>RKAS active directory</td>
                                    <td>eesnimi, perekonnanimi, isikukood, e-mail, telefoninumber, kodakondsus</td>
                                </tr>
                                <tr>
                                    <td>aktid.rkas.ee</td>
                                    <td>eesnimi, perekonnanimi, isikukood, e-mail, telefoninumber</td>
                                </tr>
                                <tr>
                                    <td>eel.rkas.ee</td>
                                    <td>eesnimi, perekonnanimi, isikukood, e-mail</td>
                                </tr>
                                <tr>
                                    <td>projektid.rkas.ee</td>
                                    <td>eesnimi, perekonnanimi, isikukood, e-mail, telefoninumber</td>
                                </tr>
                                <tr *ngIf="!_authService.getCurrentUser().isExternal">
                                    <td>Personali andmed</td>
                                    <td>aadress, konto number, CV, motivatsioonikiri, laste info, puude info, haridus, tervisekontroll</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="comment-row-item">
                            <div class="checkbox">
                                <input id="accept" type="checkbox" (change)="_acceptChecked = !_acceptChecked" />
                                <label for="accept">Nõustun Riigi Kinnisvara AS isikuandmete kasutamise <a href="javascript:;" data-toggle="modal" data-target="#conditions" style="text-decoration:underline">üldtingimustega</a></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-typical-footer">
                    <div class="tbl">
                        <div class="tbl-row">
                            <div class="tbl-cell tbl-cell-action text-right">
                                <button type="button" class="btn btn-inline btn-primary" (click)="accept()" [disabled]="!_acceptChecked">Nõustun</button>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    </div>
</div><!--.page-center-->

<div class="modal fade representative" id="conditions" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                    <i class="font-icon-close-2"></i>
                </button>
                <h4 class="modal-title" id="myModalLabel5">Teave isikuandmete töötlemise kohta</h4>
            </div>
            <div class="modal-body">
                <p>
                    Käesoleva dokumendi eesmärk on tagada füüsiliste isikute kaitse isikuandmete töötlemisel Riigi Kinnisvara ASiga sõlmitud lepingu(te) raames.
                </p>
                <p>
                    25.05.2018.a hakkab isikuandmete kaitse õigust reguleerima otsekohalduv <a href="http://eur-lex.europa.eu/legal-content/ET/TXT/PDF/?uri=CELEX:32016R0679&from=ET" target="_blank">Euroopa Parlamendi ja Nõukogu 27.04.2016.a määrus (EL) 2016/679</a>. Riigi Kinnisvara AS võtab kasutusele asjakohased meetmed, et kindlustada isikuandmete kaitse ja tagada isikuandmete töötlemine vastavuses eelnimetatud määrusega ja Riigi Kinnisvara ASis kehtestatud korraga.
                </p>
                <h5 style="font-weight: 600">MÕISTED:</h5>
                <ol style="margin-left: 30px; margin-bottom: 1rem;">
                    <li><b>Andmesubjekt</b> on isik, kelle kohta on Riigi Kinnisvara ASil teavet või teave, millega on võimalik füüsiline isik tuvastada.</li>
                    <li><b>Isikuandmed</b> on igasugune teave füüsilise isiku kohta, mis võimaldab isikut otseselt või kaudselt tuvastada.<br/>Otsest tuvastamist võimaldavad näiteks inimese nimi, sünniaeg, isikukood, e-posti aadress, elukoha aadress, kasutajatunnused, telefoni number, isiku foto. Kaudselt on isik võimalik tuvastada näiteks IP-aadressi, hüüdnime, majandusliku seisundi või auto andmete põhjal.</li>
                    <li><b>Isikuandmete töötlemine</b> on igasugune Andmesubjekti Isikuandmetega tehtav toiming. Näiteks Isikuandmete kogumine, salvestamine, korrastamine, säilitamine, muutmine ja avalikustamine, juurdepääsu võimaldamine, päringute teostamine ja väljavõtete tegemine, kasutamine, edastamine, ristkasutamine, ühendamine, sulgemine, kustutamine või hävitamine, või mitu eelnimetatud toimingut, sõltumata toimingute teostamise viisist ja kasutatavatest vahenditest.</li>
                </ol>
                <p>
                    Riigi Kinnisvara AS kohustub Isikuandmete töötlemisel rakendama Isikuandmete töötlemise põhimõtteid. Riigi Kinnisvara AS on andmete töötlejana kohustatud andmete töötlemisel täitma:
                </p>
                <ul style="list-style-type: disc; margin-left: 30px; margin-bottom: 1rem;">
                    <li>seaduslikkuse, õigluse ja läbipaistvuse põhimõtet,</li>
                    <li>eesmärgi piirangu põhimõtet,</li>
                    <li>võimalikult väheste andmete kogumise põhimõtet,</li>
                    <li>õigsuse põhimõtet,</li>
                    <li>säilitamise piirangu põhimõtet,</li>
                    <li>usaldusväärsuse ja konfidentsiaalsuse põhimõtet,</li>
                    <li>vastutuse põhimõtet.</li>
                </ul>
                <p>Riigi Kinnisvara AS on kohustatud rakendama andmetele vastava turvalisuse taseme tagamiseks asjakohaseid tehnilisi ja korralduslike meetmeid. Riigi Kinnisvara ASil on kohustus esitada isikule teavet tema Isikuandmete töötlemise tingimuste ning tema õiguste kohta.</p>
                <p>Isikule tehakse Isikuandmete kogumisel teatavaks vähemalt järgmised andmed:</p>
                <ul style="list-style-type: disc; margin-left: 30px; margin-bottom: 1rem;">
                    <li>vastutava ja volitatud töötleja andmed;</li>
                    <li>andmekaitsespetsialisti andmed;</li>
                    <li>Isikuandmete töötlemise õiguslik alus ja eesmärk;</li>
                </ul>
                <p>Riigi Kinnisvara AS kohustub kustutama Isikuandmed või hävitama Isikuandmeid sisaldava teabe pärast andmete kasutamise vajaduse lõppu 3 kuu jooksul.</p>
                <p>Riigi Kinnisvara AS peab töötlemistoimingute registrit ja on määranud andmekaitsespetsialisti, kelle ülesandeks on kontrollida käesoleva korra ja korraga seotud õigusaktide järgimist.</p>
                <h5 style="font-weight: 600">Isiku õigused</h5>
                <p>Isikul on õigus taotleda Riigi Kinnisvara ASilt teavet, kui töödeldakse temaga seotud Isikuandmeid. Isikul on õigus:</p>
                <ul style="list-style-type: disc; margin-left: 30px; margin-bottom: 1rem;">
                    <li>taotleda juurdepääsu tema kohta käivatele Isikuandmetele;</li>
                    <li>nõuda andmete parandamist, kui talletatud info on vale või puudulik;</li>
                    <li>piirata Isikuandmete töötlemist;</li>
                    <li>esitada vastuväiteid Isikuandmete töötlemisele;</li>
                    <li>nõuda Isikuandmete kustutamist;</li>
                    <li>nõuda isikuandmete ülekandmist;</li>
                    <li>Isikuandmete töötlemise nõusoleku tagasivõtmisele.</li>
                </ul>
                <p>Juhtudel, mil isik soovib piirata Isikuandmete töötlemist või tühistada enda antud nõusolek Isikuandmete töötlemiseks või nõuda enda Isikuandmete kustutamist, tuleb esitada Riigi Kinnisvara ASi andmekaitsespetsialistile vastavasisuline kirjalik taotlus.</p>
                <p>Isikul on õigus nõuda, et tema kohta ei võetaks vastu otsust, mis põhineb automatiseeritud töötlusel.</p>
                <p>Samuti on isikul õigus saada või edastada enda Isikuandmeid edasi teisele andmetöötlejale. Nimetatud õigus katab ainult neid andmeid, mille kohta isik on Riigi Kinnisvara ASile andnud enda nõusoleku. Et nimetatud õigust kasutada, tuleb isikul esitada Riigi Kinnisvara AS andmekaitsespetsialistile vastavasisuline kirjalik taotlus.</p>
                <h5 style="font-weight: 600">Kontakt küsimuste korral</h5>
                <p>Küsimuste korral seoses Isikuandmete töötlemisega võtta ühendust:</p>
                <p><a href="mailto:andres.truss@rkas.ee">andres.truss@rkas.ee</a></p>
                <p>Vastusega mitte rahul olles, on isikul õigus esitada kaebus Andmekaitse Inspektsioonile.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-rounded btn-default" data-dismiss="modal">Sulge</button>
            </div>
        </div>
    </div>
</div><!--.modal-->