import { AuthService } from '../services/auth.service';
import { ConfigService } from 'app/config/config.service';
import {IResourceActionInner, IResourceResponse, Resource, ResourceParams} from "@ngx-resource/core";
import {ResourceHandler} from "@ngx-resource/core/src/ResourceHandler";

@ResourceParams({
    withCredentials: true
})
export class AuthGuardedResource extends Resource {
    constructor (
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config:ConfigService
    ) {
        super(requestHandler);
    }

    $handleErrorResponse(options: IResourceActionInner, resp: IResourceResponse) {
        //Unauthenticated
        if (resp.status === 401) {
            console.log('auth guarder resource. handle 401');
            //this.authService.setCurrentUser(null);
            this.authService.navigateToLogin();
        }

        //Unauthorized
        if (resp.status === 403) {
            console.log('auth guarder resource. handle 403');
            //this.authService.setCurrentUser(null);
            //this.authService.navigateToLogin();
        }

        super.$handleErrorResponse(options, resp);
    }
}
