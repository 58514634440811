import {Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit} from '@angular/core';
import { DatePipe } from "@angular/common";
import {ActivatedRoute, Router} from '@angular/router';
import {UserLoginDto} from "../../core/resource-dto/user-login";
import { BsTableControl } from '../../shared/directives/bs-table-control.directive';
import {AuthService} from "../../core/services/auth.service";
import {combineLatest, Observable, Subject} from "rxjs";
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";
import {PermissionsService} from "../../shared/services/permissions.service";
import {CompanyRes} from "../../core/resource/company.resource";
import {CompanyDto} from "../../core/resource-dto/company";
import {ClassifierLabelPipe} from "../../shared/pipes/classifier-label.pipe";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";

declare let jQuery:any;
declare let swal:any;

@Component({
    templateUrl: './list.component.html',
    providers: [ DatePipe ]
})
export class CompanyListComponent implements AfterViewInit, OnDestroy {
    private ngDestroy: Subject<void> = new Subject<void>();

    @ViewChild(BsTableControl)
    bsTableControl:BsTableControl;

    @ViewChild('bsTableElement')
    bsTableElement:ElementRef;

    public permissions$:Observable<any>;
    public currentPermissions:any = {};

    public filterFormGroup: FormGroup = this.formBuilder.group({
      includeDeletedCompanies: [false]
    });

    public columnsFormat: any = [
        {
            formatter: (value:any, row:any, rowIndex:any) => {
                const path = "/companies/view/" + row.companyId;
                return '<a href="'+ path + '" class="user-link" data-id="'+row.companyId+'">'+row.name+'</a>';
            }
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {}
    ];

    public companies:CompanyDto.Company[] = null;

    public bsTableOptions:any = {
        toolbar: '#toolbarCompany',
        iconsPrefix: 'font-icon',
        icons: {
            paginationSwitchDown:'font-icon-arrow-square-down',
            paginationSwitchUp: 'font-icon-arrow-square-down up',
            refresh: 'font-icon-refresh',
            toggle: 'font-icon-list-square',
            columns: 'font-icon-list-rotate',
            export: 'font-icon-download',
            detailOpen: 'font-icon-plus',
            detailClose: 'font-icon-minus-1'
        },
        paginationPreText: '<i class="font-icon font-icon-arrow-left"></i>',
        paginationNextText: '<i class="font-icon font-icon-arrow-right"></i>',
        sidePagination: 'server',
        pagination: true,
        pageList: [10, 25, 50, 100],
        pageSize: 10,
        formatAllRows: function () {
            return 'Kõik';
        },
        showColumns: true,
        columns: this.columnsFormat,
        data: [],
        ajax: (params: any) => this.ajaxCallBack(params),
        cookie: true,
        filterShowClear: true,
        cookieIdTable: 'company-list',
        cookieStorage: 'sessionStorage'
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private datePipe:DatePipe,
        private companyRes:CompanyRes,
        private authService:AuthService,
        private permissionsService: PermissionsService,
        private formBuilder: FormBuilder,
        private classifierLabelPipe: ClassifierLabelPipe
    ) {
        this.permissions$ = this.permissionsService.permissions$;
        this.filterFormGroup.get('includeDeletedCompanies').setValue(false);
    }

    ngAfterViewInit() {
        let $table = jQuery(this.bsTableElement.nativeElement);

        this.permissions$.pipe(distinctUntilChanged()).subscribe((permissions:any) => {
            this.currentPermissions = permissions;
            if (permissions['company.list.manage']) {
                this.columnsFormat = [
                    {
                        formatter: (value:any, row:any, rowIndex:any) => {
                            const path = "/companies/view/" + row.companyId;
                            return '<a href="'+ path + '" class="user-link" data-id="'+row.companyId+'">'+row.name+'</a>';
                        }
                    },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {
                        sortable: false,
                        width: '152px',
                        formatter: (value: any, row: any, rowIndex:any) => {
                            return '<button class="btn btn-primary btn-sm edit-link" data-id="'+row.companyId+'"><span class="glyphicon glyphicon-pencil"></span>' +
                                (!row.deleted ? '</button>&nbsp;<button class="btn btn-primary swal-btn-cancel btn-sm delete-link" data-id="'+row.companyId+'"><span class="glyphicon glyphicon-trash"></span></em></button>' : '');
                        },
                    }
                ];
                this.bsTableOptions.columns = this.columnsFormat;
            }
        });


        if($table.length) {
            $table.on('click', '.user-link', (e:any) => {
                e.preventDefault();

                let id:any = jQuery(e.target).data('id');
                if(id) {
                    this.router.navigate(['/companies/view', id]);
                }

                return false;
            });
        }

        if ($table.length) {
            $table.on('click', '.edit-link', (e:any) => {
                e.preventDefault();

                let id:any = jQuery(e.currentTarget).data('id');
                if (id) {
                    this.router.navigate(['edit', id], { relativeTo: this.route });
                }

                return false;
            });

            $table.on('click', '.delete-link', (e:any) => {
                e.preventDefault();

                let id:any = jQuery(e.currentTarget).data('id');

                if (id) {
                    let company:CompanyDto.Company = this.companies.find((c:CompanyDto.Company) => c.id == id);

                    if (company) {
                        swal(
                            {
                                title: "Kas oled kindel?",
                                text: 'Kas oled kindel, et soovid ettevõtte "' + company.name + '" kustutada?',
                                type: "info",
                                showCancelButton: true,
                                cancelButtonText: "Tühista",
                                cancelButtonClass: "btn-default",
                                confirmButtonText: "Jah",
                                confirmButtonClass: "btn-primary",
                                closeOnConfirm: false,
                            },
                            (isConfirm:any) => {
                                if (isConfirm) {
                                    this.companyRes.delete({companyId: company.id}, {companyId: this.authService.getCurrentUserCompany().id}).then(() => {
                                        this.bsTableControl.refresh();
                                        swal({
                                            title: "Ettevõtte kustutatud!",
                                            type: "success",
                                            timer: 2000,
                                            showConfirmButton: false
                                        });
                                    });
                                }
                            }
                        );
                    }
                }

                return false;
            });
        }

        combineLatest([
            this.authService.userCompany$.pipe(distinctUntilChanged()),
            this.filterFormGroup.valueChanges.pipe(takeUntil(this.ngDestroy), distinctUntilChanged())
        ]).subscribe(([userCompany, filterFormModel]: [UserLoginDto.UserCompany, any]) => {
            this.bsTableControl.refresh();
        });
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    ajaxCallBack(params: any) {
        let query: CompanyDto.QueryInput = new CompanyDto.QueryInput;

        if (params.data.search) {
            query.search = params.data.search;
        }
        query.isDeleted = this.filterFormGroup.get('includeDeletedCompanies').value;
        query.companyId = this.authService.getCurrentUserCompany().id;
        query.limit = params.data.limit;
        query.offset = Math.floor(params.data.offset / params.data.limit);

        if (params.data.sort) {
            query.sortFields = params.data.order == 'desc'? encodeURIComponent('-' + params.data.sort) : '+' + params.data.sort;
        }

        this.companyRes.query(query).then(
            (queryOutput: CompanyDto.QueryOutput) => {
                this.companies = queryOutput.companies;

                let rows:Array<Object> = this.companies.map(dto => {
                    return {
                        companyId: dto.id,
                        name: dto.name,
                        regNo: dto.regNo,
                        vatRegNo: dto.vatRegNo,
                        countryName: dto.address ? dto.address.countryName : '',
                        email: dto.email,
                        phone: dto.phone,
                        partnerCode: dto.partnerCode,
                        sapCode: dto.sapCode,
                        stateName: dto.address ? dto.address.stateName : '',
                        cityName: dto.address ? dto.address.cityName : '',
                        address: dto.address ? dto.address.address1 : '',
                        zip: dto.address ? dto.address.zip : '',
                        governanceAreaType: dto.governanceAreaType ? this.classifierLabelPipe.transform(dto.governanceAreaType, 'GOVERNANCE_AREA_TYPE') : '',
                        readingForwardEmail: dto.readingForwardEmail ? dto.readingForwardEmail : '',
                        deleted: dto.deleted != null
                    };
                });

                this.bsTableControl.load(rows);
                params.success({
                    total: queryOutput.count,
                    rows: rows
                });
            },
            error => {
                console.log('error', <any>error);
                params.success({
                    total: 0,
                    rows: []
                });
            }
        );
    }
}
