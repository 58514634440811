import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import { UserNotFoundComponent } from './core/components/user-not-found.component';

import { NgxPermissionsGuard } from "ngx-permissions";
import {AdminModule} from "./admin/admin.module";
import {GDPRGuard} from "./user-registry/gdpr/gdpr.guard";

const routes: Routes = [
    { path: 'user-not-found', component: UserNotFoundComponent },
    { path: '', redirectTo: '/list', pathMatch: 'full' },
    {
        path: 'admin',
        loadChildren: () => AdminModule,
        canActivate: [ NgxPermissionsGuard, GDPRGuard ],
        data: {
            title: 'Administreerimine',
            permissions: {
                only: [
                    'sync.start'
                ]
            }
        }
    }
];

@NgModule({
    imports:    [ RouterModule.forRoot(routes) ],
    exports:    [ RouterModule ]
})
export class AppRoutingModule { }
