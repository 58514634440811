import {AbstractControl, ValidationErrors, Validator, Validators} from "@angular/forms";

export class OptionalValidator implements Validator {

  static email(control: AbstractControl): ValidationErrors | null {
      if (!control.value) {
        return null;
      }

    return Validators.email(control);
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return undefined;
  }
}
