import {Component, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserDto} from "@app/core/resource-dto/user";
import {ManageService} from "../../manage/manage.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserAdapter} from "../../form/user-adapter";
import {DatePipe} from "@angular/common";
import {combineLatest, Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import * as moment from 'moment';

@Component({
    templateUrl: './substitution-manage.component.html',
    providers: [UserAdapter]
})
export class SubstitutionManageComponent {

    private ngDestroy: Subject<void> = new Subject<void>();
    public country: number = null;
    public user: UserDto.User = null;
    public user$: Observable<UserDto.User>;

    @ViewChild('periodDatetimepicker', { static: true })
    periodDatetimepickerRef: ElementRef;

    formGroup: FormGroup;
    public formFieldErrors = {
        actingAs: <any>null,
        substitute: <any>null,
        validFrom: <any>null,
        validTo: <any>null
    };

    public showFormErrors: boolean = false;
    protected isSaveInProgress: boolean = false;
    public formErrors: any = {};
    protected validationMessages = {
        'substitute': {
            'required': 'Palun määra kasutaja.',
        },
        'actingAs': {
            'required': 'Palun määra kasutaja.',
        },
        'validFrom': {
            'required': 'Palun määra ajavahemik.',
        },
        'validTo': {
            'required': 'Palun määra ajavahemik.',
        }
    };

    public substitution: UserDto.Substitution;
    public _validFrom = <any>null;
    public _validTo = <any>null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _datePipe: DatePipe,
        private manageService: ManageService,
        public userAdapter: UserAdapter
    ) {

        this.formGroup = new FormGroup({
            actingAs: new FormControl(null, [Validators.required]),
            substitute: new FormControl(null, [Validators.required]),
            validFrom: new FormControl('', [Validators.required]),
            validTo: new FormControl('', [Validators.required])
        });
        this.formGroup.valueChanges.subscribe((formModel: any) => this.onFormValueChanged(formModel));
        this.userAdapter.loadSubstitutionOptions();
        this.user$ = manageService.user$;


        this.user$.pipe(takeUntil(this.ngDestroy)).subscribe((user: UserDto.User) => {
            this.user = user;
        });

        combineLatest([manageService.user$, this.route.params])
          .pipe(takeUntil(this.ngDestroy))
          .subscribe(([user, params]) => {
            if (!user) return;

            if (!params.substitutionId) {
                this.resetFormValue();
                return;
            }

            this.manageService.querySubstitutions(user.id).then(
                (qa: UserDto.SubstitutionQueryOutput) => {
                    for (let i in qa.substitutions) {
                        if (qa.substitutions[i].id == params.substitutionId) {
                            this.substitution = qa.substitutions[i];
                        }
                    }
                    if (!this.substitution) {
                        this.router.navigate(['/user'], {relativeTo: route});
                    }
                    this.resetFormValue();
                },
                (error: any) => {
                    this.router.navigate(['/user'], {relativeTo: route});
                }
            );
        });
    }

    dateValueChange(e: any) {
        this.formGroup.get('validFrom').reset(e.startDate);
        this.formGroup.get('validTo').reset(e.endDate);
    }

    resetFormValue() {
        let formValue: any = this.prepareFormModel();
        this.formGroup.setValue(formValue);
        this._validFrom = formValue.validFrom;
        this._validTo = formValue.validTo;
    }

    prepareFormModel() {
        let substitution: UserDto.Substitution = this.substitution;
        if (!substitution) return {
            actingAs: {id: this.user.id, text: this.user.forename + " " + this.user.surname},
            substitute: <any>null,
            validFrom: moment(new Date()),
            validTo: moment(new Date())
        };
        return {
            actingAs: substitution.actingAs ? {
                id: substitution.actingAs.userId,
                text: substitution.actingAs.name
            } : null,
            substitute: substitution.substitute ? {
                id: substitution.substitute.userId,
                text: substitution.substitute.name
            } : null,
            validFrom: this._datePipe.transform(substitution.validFrom, 'dd.MM.yyyy'),
            validTo: this._datePipe.transform(substitution.validTo, 'dd.MM.yyyy')
        };
    }

    ngOnDestroy() {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    onFormValueChanged(formModel?: any) {
        this.processValidation();
    }

    processValidation() {
        if (!this.formGroup) return;

        const form = this.formGroup;
        for (const field in this.formFieldErrors) {
            this.formFieldErrors[field] = null;
            const control = form.get(field);
            if (control && (control.dirty || this.showFormErrors) && !control.valid) {
                const messages = this.validationMessages[field];
                this.formFieldErrors[field] = control.errors;
                for (const key in control.errors) {
                    this.formFieldErrors[field][key] = messages[key];
                }
            }
        }

        this.formErrors = {};

        let invalid = !this.formGroup.valid;
        if (!invalid) {
            if (this.formGroup.get('actingAs').value.id != this.user.id && this.formGroup.get('substitute').value.id != this.user.id) {
                this.formErrors['not_user'] = true;
                invalid = true;
            }
            const formModel = this.formGroup.getRawValue();
            let vFrom = moment(formModel.validFrom, 'DD.MM.YYYY');
            let vTo = moment(formModel.validTo, 'DD.MM.YYYY');
            if (vTo.isBefore(vFrom)) {
                this.formErrors['invalid_interval'] = true;
                invalid = true;
            }
        }

        if (!invalid) {
            this.showFormErrors = false;
        } else {
            this.formErrors['invalid'] = true;
        }
    }

    btnDismissErrorsClicked() {
        this.showFormErrors = false;
    }

    btnSaveClicked() {

        if (this.isSaveInProgress) {
            return;
        }

        this.formGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
        if (this.formGroup.valid && !this.formErrors['invalid']) {
            this.isSaveInProgress = true;
            let substitutionInput: UserDto.SubstitutionInput = this.prepareInputDtoForSubmit();
            if (this.substitution && this.substitution.id) {
                this.manageService.updateSubstitution(substitutionInput, this.substitution.id, this.substitution.substitute.userId).then(
                    (substitution: UserDto.Substitution) => {
                        this.isSaveInProgress = false;
                        this.router.navigate(['../..'], {relativeTo: this.route});
                    },
                    (error: any) => {
                        this.isSaveInProgress = false;
                    }
                );
            } else {
                this.manageService.saveSubstitution(substitutionInput).then(
                    (substitution: UserDto.Substitution) => {
                        this.isSaveInProgress = false;
                        this.router.navigate(['..'], {relativeTo: this.route});
                    },
                    (error: any) => {
                        const code = error?.body?.errorCode;
                        const description = error?.body?.errorDescription;
                        if (!!code && !!description) {
                            this.showFormErrors = true;
                            this.formErrors[code] = description;
                        }
                        this.isSaveInProgress = false;
                    }
                );
            }
        } else {
            this.showFormErrors = true;
        }
    }

    prepareInputDtoForSubmit() {
        const formModel = this.formGroup.getRawValue();
        let input = new UserDto.SubstitutionInput();
        input.actingAs = new UserDto.SubstitutionUserRef(formModel.actingAs.id);
        input.substitute = new UserDto.SubstitutionUserRef(formModel.substitute.id);
        input.validFrom = this._datePipe.transform(moment(formModel.validFrom, 'DD.MM.YYYY').toDate(), 'yyyy-MM-dd\'T\'00:00:00.000+0300');
        input.validTo = this._datePipe.transform(moment(formModel.validTo, 'DD.MM.YYYY').toDate(), 'yyyy-MM-dd\'T\'23:59:59.999+0300');
        return input;
    }
}
