export namespace UserDto {

    export class QueryInput {
        largeClientManagerUserIds?: number[];
        companyId: number;
        company: string;
        representationRight: string;
        personalCode?: string;
        country?: string;
        name?: string;
        sortFields?: string;
        roleCodes?: string[];
        isExternal?: boolean;
        isDeleted?: boolean;
        dateFrom?: string;
        dateTo?: string;
        search?: string;
        offset?: number;
        limit?: number;
        filterByCompanyId?: number;
        isAllUsers?: boolean;
        ministryRegNo: string;
    }

    export class QueryOutput {
        users: User[];
        offset?: number;
        limit?: number;
        size?: number;
    }

    export class User {
        id: number;
        country: Country;
        personalCode: string;
        forename?: string;
        surname?: string;
        email?: string;
        phone?: string;
        gender?: string;
        birthday?: string;
        info?: string;
        sapCostCenter?: string;
        isExternal: boolean;
        gdprAccepted: boolean;
        registrationTimestamp: string;
        verifiedTimestamp: string;
        companyCount?: number;
        countryCode: string;
        countryName: string;
        userCompanies: Company[];
        deleted: Date;
        adDptGroup: string;
        largeClientManagerUser: LargeClientManagerUser;
        phoneNumbers: string[]
    }

    export class Country {
        id: number;
        code: string;
        name: string;
    }

    export class CompanyQueryInput {
        companyId: number;
    }

    export class CompanyQueryOutput {
        companies: Company[];
    }

    export class Company {
        id: number = null;
        companyId: number = null;
        name: string = '';
        email: string = '';
        phone: string = '';
        jobTitles: JobTitle[] = [];
        roles: RoleData[] = [];
    }

    export class LargeClientManagerUser {
        id: number;
        name: string;
    }

    export class UserInput {
        id: number;
        countryId: number;
        personalCode: string;
        forename: string;
        surname: string;
        email: string;
        info: string;
        sapCostCenter: string;
        undelete: boolean;
        gender: string;
        birthday: string;
        roles: CompanyInput[];
        largeClientManagerUserId: number;
        phoneNumbers: string[];
        isExternal: boolean;
    }

    export class CompanyInput {
        companyId: number;
        roles: RoleInput[];
        email: string;
        phone: string;
        jobTitles: JobTitle[];
    }

    export class CompanyData {
      companyId: number;
      companyName: string;
      roles: RoleData[];
      email: string;
      phone: string;
      jobTitles: JobTitle[];
    }

    export class JobTitle {
      id: number;
      name: string;
    }

    export class RoleData {
        id: number;
        name: string;
        roleId: number;
        basisOfAssign: string;
        basisOfAssignRequired: boolean;
        error?: string;
    }

    export class RoleInput {
        id: number;
        basisOfAssign: string;
    }

    export class SubstitutionQueryInput {
        companyId: number;
        userId:number;
    }

    export class SubstitutionQueryOutput {
        substitutions: Substitution[];
    }

    export class Substitution {
        id: number;
        type: string;
        actingAs: SubstitutionUser;
        substitute: SubstitutionUser;
        validFrom: string;
        validTo: string;
    }

    export class SubstitutionUser {
        userId: number;
        name: string;
        email: string;
        personalCode: string;
        phone: string;
    }

    export class SubstitutionUserRef {
        userId: number;
        constructor(userId: number) {
            this.userId = userId;
        }
    }

    export class SubstitutionInput {
        actingAs: SubstitutionUserRef;
        substitute: SubstitutionUserRef;
        validFrom: string;
        validTo: string;
    }

    export class BasisOfAssign {
        id: number;
        userCompanyId: number;
        companyName: string;
        roleId: number;
        roleName: string;
        validFrom: string;
        validTo: string;
        basisOfAssign: string;
    }

    export class BasisOfAssignInput {
        userId: number;
        companyId: number;
        onlyActiveUserRoles: boolean;
    }

    export class UserPhoneNumberRequest {
        id: number;
        phoneNumber: string;
    }

    export class UserPhoneNumbers {
        id: number;
        phoneNumber: string;
    }
}
