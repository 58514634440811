import { PipeTransform, Pipe } from '@angular/core';
import { ClassifierService } from "@app/core/services/classifier.service";
import { ClassifierDto } from "@app/core/resource-dto/classifier";
import ClassifierValue = ClassifierDto.ClassifierValue;

@Pipe({
    name: 'classifier'
})
export class ClassifierLabelPipe implements PipeTransform {

    constructor(private _classifierService: ClassifierService) {}

    transform(value: any, classifier: any): any {
      const classifierValue: ClassifierValue = this._classifierService.getClassifierValueByCode(classifier, value);

      return !classifierValue ? '' : classifierValue.name;
    }
}
