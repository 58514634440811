import {AfterViewInit, Component, ElementRef, Input, ViewChild} from "@angular/core";
import {BsTableControl} from "@app/shared/directives/bs-table-control.directive";
import {CompanyDto} from "@app/core/resource-dto/company";
import GovernanceAreaCompany = CompanyDto.GovernanceAreaCompany;
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-governance-area-companies',
  templateUrl: './governance-area-companies.component.html',
})
export class GovernanceAreaCompaniesComponent implements AfterViewInit {

  @ViewChild(BsTableControl)
  bsTableControl:BsTableControl;

  @ViewChild('bsTableElement')
  bsTableElement:ElementRef;

  @Input()
  public governanceAreaCompanies: GovernanceAreaCompany[] = null;

  public bsTableOptions:any = {
    toolbar: undefined,
    pagination: true,
    columns: [
      {
        formatter: (value:any, row:any) => {
          return '<a href="#" class="company-link" data-id="' + row.id + '">'+value+'</a>';
        },
        field: 'name',
        title: 'Nimi',
        sortable: true
      },
      {
        field: 'usersCount',
        title: 'Kasutajate arv',
        sortable: true
      }
    ],
    data: []
  };

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngAfterViewInit() {
    this.bsTableControl.load(this.governanceAreaCompanies.map((row: CompanyDto.GovernanceAreaCompany) => {
      return {
        id: row.id,
        name: row.name,
        usersCount: row.usersCount
      };
    }));

    let $table = jQuery(this.bsTableElement.nativeElement);

    if($table.length) {

      $table.on('click', '.company-link', (e: any) => {
        e.preventDefault();

        let id: any = jQuery(e.target).data('id');
        if (id) {
          this.router.navigate(['/companies/view', id], {relativeTo: this.route});
        }

        return false;
      });
    }
  }
}
