import {Injectable} from "@angular/core";
import {QueryAdapter} from "@app/shared/QueryAdapter";
import {JobTitleRes} from "@app/core/resource/job-title.resource";
import {JobTitleDto} from "@app/core/resource-dto/job-title";
import * as lnr from 'elasticlunr';

@Injectable()
export class JobTitleAdapter extends QueryAdapter {

  constructor(private _jobTitleRes: JobTitleRes) { super() }

  loadJobTitles() {
    this._jobTitleRes.query().then((response: JobTitleDto.QueryOutput) => {
      this.updateMap(response.data);
      this.updateSearchIndex();
      this.updateSource();
    });
  }

  private updateSearchIndex() {
    this.index = new lnr.Index;

    this.index.pipeline.reset();
    this.index.pipeline.add(lnr.trimmer);

    this.index.addField('name');
    this.index.setRef('id');

    this.map.forEach((company: any) => {this.index.addDoc({id: company.id, name: company.name})});
  }

  public updateMap(jobTitles: JobTitleDto.JobTitle[]) {
    this.map = new Map<number, any>(jobTitles.map((title:any) => [title.id, title] as [number, any]));
  }

  public updateSource() {
    this.dataSource.next(this.map);

    let options:any[] = [];
    this.map.forEach((title: any) => {options.push({id: title.id, name: title.name})});
    this.optionsSource.next(options);
  }

  query(term:string): Array<any> {
    if(!term || !term.length) return this.clear();

    return this.search(term);
  }
}
